function loadJSON(filePath: string): any {
  return loadTextFileAjaxSync(filePath, 'application/json');
}

function loadTextFileAjaxSync(filePath: string, mimeType: string): any {
  const xmlhttp = new XMLHttpRequest();
  xmlhttp.open('GET', filePath, false);
  if (mimeType != null) {
    if (xmlhttp.overrideMimeType) {
      xmlhttp.overrideMimeType(mimeType);
    }
  }
  xmlhttp.send();
  if (xmlhttp.status === 200) {
    return xmlhttp.responseText;
  } else {
    return null;
  }
}

export const environment = {
  production: false,
  useHash: true,
  // host: '',
  // path: '',
  // wsHost: '',
  iconUrl: '//at.alicdn.com/t/font_2914808_rw9mglj9jzb.js',
  lockInterval: 300000,
  api: {
    baseUrl: './',
    refreshTokenEnabled: true,
    refreshTokenType: 'auth-refresh',
  },
  password: '123456',
  host: 'http://localhost:80',
  // ahost: 'http://192.168.1.39:80',
  ahost: 'https://admin.iregene.demo.showye.tech',
  path: '/api',
  wxMpId: 'wxade8f19de21656ba',
  wsHost: 'ws://admin.iregene.demo.showye.tech/api/ws/default',
  amapKey: '9b1a41bf510ed0db6f0726617ceadc87',
  ...JSON.parse(loadJSON('/assets/config.json')),
};
