import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NbApiService } from '@service/nb-api.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { API } from '@service/api';
import { ShowyePageAuthService } from '@showye/showye-page-base';

@Component({
  selector: 'app-refit-info',
  templateUrl: './refit-info.component.html',
  styleUrls: ['./info.scss'],
})
export class RefitInfoComponent implements OnInit {
  @Input() isVisible = true;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Input() detailInfo: any;
  info: any;

  get value(): any {
    return this.isVisible;
  }

  set value(v: any) {
    this.isVisible = v;
    this.isVisibleChange.emit(v);
  }
  token: any = this.auth.token;

  statusEnums: any = {
    remedy_apply: { label: '审核中', color: 'red' },
    remedy: { label: '已完成', color: 'green' },
    remedy_reject: { label: '已驳回', color: 'orange' },
    remedy_cancelled: { label: '已撤销', color: 'default' },
  };
  downloadUrl = this.api.getDownloadUrl();

  constructor(
    private api: NbApiService,
    modalSrv: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public msg: NzMessageService,
    public notify: NzNotificationService,
    private apiUrl: API,
    private modal: NzModalService,
    private auth: ShowyePageAuthService,
  ) {}

  ngOnInit(): void {
    this.getRemedyCheckDetail(this.detailInfo.id);
  }

  // 获取补卡详情
  getRemedyCheckDetail(id: any): void {
    if (id) {
      this.api.getRemedyCheckDetail(id).subscribe((e: any) => {
        this.info = e.data;
      });
    }
  }

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }

  onDownloadPrint(type?: any): void {
    if (type === 2) {
      window.open(this.apiUrl.CHECKIN_PRINT(this.detailInfo.id, type) + '&token=' + this.token, '_blank');
    } else {
      window.location.href = this.apiUrl.CHECKIN_PRINT(this.detailInfo.id, type) + '&token=' + this.token;
    }
  }
}
