import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@env/environment';
// layout
import { Exception404Component, LayoutBasicComponent } from '@showye/showye-page-admin/layout-admin';
import { SHOWYE_PAGE_ADMIN_ROOT_ROUTE, SHOWYE_PAGE_ADMIN_PASSPORT_ROUTE } from '@showye/showye-page-admin/routing';
import { SHOWYE_PAGE_FORM_ROOT_ROUTE } from '@showye/showye-page-form/routing';
import { SHOWYE_PAGE_FLOW_PROCESS_ROOT_ROUTE, SHOWYE_PAGE_FLOW_TASK_ROOT_ROUTE } from '@showye/showye-page-flow/routing';
import { ShowyePageAuthGuard } from '@showye/showye-page-base';
import { ReviewComponent } from '../components/review/review.component';

const routes: Routes = [
  // SHOWYE_PAGE_ADMIN_PASSPORT_ROUTE,
  { path: 'passport', loadChildren: () => import('./login/passport.module').then((m) => m.PassportModule) },
  { path: 'mp', loadChildren: () => import('./wxmp/mp.module').then((m) => m.MpModule) },
  {
    path: '',
    component: LayoutBasicComponent,
    canActivate: [ShowyePageAuthGuard],
    canActivateChild: [ShowyePageAuthGuard],
    children: [
      { path: 'exception', children: [{ path: '404', component: Exception404Component }] },
      {
        path: 'design',
        children: [
          { path: '', redirectTo: 'process', pathMatch: 'full' },
          SHOWYE_PAGE_FLOW_PROCESS_ROOT_ROUTE,
          SHOWYE_PAGE_FORM_ROOT_ROUTE,
        ],
      },
      SHOWYE_PAGE_FLOW_TASK_ROOT_ROUTE,
      SHOWYE_PAGE_ADMIN_ROOT_ROUTE,
      // TODO 在下面放置 业务模块 的内容
      {
        path: '',
        // canActivate: [NbHomeGuard],
        children: [{ path: '', redirectTo: '/my/workbench', pathMatch: 'full' }],
      },
      {
        path: 'office',
        children: [
          { path: '', redirectTo: 'checkin', pathMatch: 'full' },
          {
            path: 'config',
            children: [
              {
                path: 'checkin',
                loadChildren: () => import('./config/config-checkin/config-checkin.module').then((m) => m.ConfigCheckinModule),
              },
              {
                path: 'holiday',
                loadChildren: () => import('./config/config-holiday/config-holiday.module').then((m) => m.ConfigHolidayModule),
              },
            ],
          },
          {
            path: 'checkin',
            children: [
              { path: '', redirectTo: 'record', pathMatch: 'full' },
              // { path: 'record', loadChildren: () => import('./checkin/record/record.module').then((m) => m.RecordModule) },
              { path: 'record', loadChildren: () => import('./checkin/data/data.module').then((m) => m.DataModule) },
              { path: 'stat', loadChildren: () => import('./checkin/stat/stat.module').then((m) => m.StatModule) },
              // { path: 'review', loadChildren: () => import('./checkin/review/review.module').then((m) => m.ReviewModule) },
              { path: 'review', data: { groupId: 1 }, component: ReviewComponent },
              {
                path: 'overtime',
                loadChildren: () => import('./checkin/overtime/overtime.module').then((m) => m.OvertimeModule),
              },
              {
                path: 'holiday',
                loadChildren: () => import('./checkin/holiday/holiday.module').then((m) => m.HolidayModule),
              },
              { path: 'trip', loadChildren: () => import('./checkin/trip/trip.module').then((m) => m.TripModule) },
              { path: 'count', loadChildren: () => import('./checkin/count/count.module').then((m) => m.CountModule) },
              { path: 'task', loadChildren: () => import('./my/task/task.module').then((m) => m.TaskModule) },
            ],
          },
          {
            path: 'hr',
            children: [
              { path: '', redirectTo: 'domain', pathMatch: 'full' },
              { path: 'domain', loadChildren: () => import('./hr/domain/domain.module').then((m) => m.DomainModule) },
              { path: 'staff', loadChildren: () => import('./hr/staff/staff.module').then((m) => m.StaffModule) },
              { path: 'role', loadChildren: () => import('./hr/role/role.module').then((m) => m.RoleModule) },
              {
                path: 'position',
                loadChildren: () => import('./hr/position/position.module').then((m) => m.PositionModule),
              },
              {
                path: 'personnel',
                loadChildren: () => import('./hr/personnel/personnel.module').then((m) => m.PersonnelModule),
              },
              { path: 'review', data: { groupId: 2 }, component: ReviewComponent },
              { path: 'task', loadChildren: () => import('./my/task/task.module').then((m) => m.TaskModule) },
            ],
          },
        ],
      },
      {
        path: 'finance',
        children: [
          { path: '', redirectTo: 'finance', pathMatch: 'full' },
          {
            path: 'finance',
            children: [
              { path: '', redirectTo: 'approve', pathMatch: 'full' },
              {
                path: 'reimbursement',
                loadChildren: () => import('./finance/reimbursement/reimbursement.module').then((m) => m.ReimbursementModule),
              },
              {
                path: 'payment',
                loadChildren: () => import('./finance/payment/payment.module').then((m) => m.PaymentModule),
              },
              {
                path: 'approve',
                loadChildren: () => import('./finance/approve/approve.module').then((m) => m.ApproveModule),
              },
              { path: 'task', loadChildren: () => import('./my/task/task.module').then((m) => m.TaskModule) },
              {
                path: 'stats',
                loadChildren: () => import('./finance/stats/stats.module').then((m) => m.StatsModule),
              },
              {
                path: 'travel',
                loadChildren: () => import('./finance/travel/travel.module').then((m) => m.TravelModule),
              },
              {
                path: 'payee',
                loadChildren: () => import('./finance/payee/payee.module').then((m) => m.PayeeModule),
              },
            ],
          },
          {
            path: 'contract',
            children: [
              { path: '', redirectTo: 'buy', pathMatch: 'full' },
              { path: 'buy', loadChildren: () => import('./contract/buy/buy.module').then((m) => m.BuyModule) },
              { path: 'sale', loadChildren: () => import('./contract/sale/sale.module').then((m) => m.SaleModule) },
            ],
          },
          {
            path: 'purchase',
            children: [
              { path: '', redirectTo: 'buy', pathMatch: 'full' },
              { path: 'task', loadChildren: () => import('./purchase/task/task.module').then((m) => m.TaskModule) },
              { path: 'mine', loadChildren: () => import('./purchase/mine/mine.module').then((m) => m.MineModule) },
              { path: 'ticket', loadChildren: () => import('./my/task/task.module').then((m) => m.TaskModule) },
            ],
          },
        ],
      },
      {
        path: 'flow',
        children: [
          { path: '', redirectTo: 'group', pathMatch: 'full' },
          { path: 'design', loadChildren: () => import('./flow/design/design.module').then((m) => m.DesignModule) },
          { path: 'hr', loadChildren: () => import('./flow/hr/hr.module').then((m) => m.HrModule) },
          { path: 'finance', loadChildren: () => import('./flow/finance/finance.module').then((m) => m.FinanceModule) },
          { path: 'checkin', loadChildren: () => import('./flow/checkin/checkin.module').then((m) => m.CheckinModule) },
          { path: 'group', loadChildren: () => import('./group/group.module').then((m) => m.GroupModule) },
          { path: 'process', loadChildren: () => import('./process/process.module').then((m) => m.ProcessModule) },
          { path: 'birth', loadChildren: () => import('./birth/birth.module').then((m) => m.BirthModule) },
          { path: 'urge', loadChildren: () => import('./urge/urge.module').then((m) => m.UrgeModule) },
        ],
      },
      {
        path: 'wms',
        children: [
          { path: '', redirectTo: 'depot', pathMatch: 'full' },
          { path: 'depot', loadChildren: () => import('./wms/depot/depot.module').then((m) => m.DepotModule) },
          {
            path: 'inventory',
            loadChildren: () => import('./wms/inventory/inventory.module').then((m) => m.InventoryModule),
          },
          {
            path: 'material',
            loadChildren: () => import('./wms/material/material.module').then((m) => m.MaterialModule),
          },
          { path: 'brand', loadChildren: () => import('./wms/brand/brand.module').then((m) => m.BrandModule) },
          { path: 'receive', loadChildren: () => import('./wms/receive/receive.module').then((m) => m.ReceiveModule) },
          { path: 'inbound', loadChildren: () => import('./wms/inbound/inbound.module').then((m) => m.InboundModule) },
          { path: 'pickup', loadChildren: () => import('./wms/pickup/pickup.module').then((m) => m.PickupModule) },
          {
            path: 'condition',
            loadChildren: () => import('./wms/condition/condition.module').then((m) => m.ConditionModule),
          },
          {
            path: 'distributor',
            loadChildren: () => import('./wms/distributor/distributor.module').then((m) => m.DistributorModule),
          },
          { path: 'procure', loadChildren: () => import('./procurement/procure.module').then((m) => m.ProcureModule) },
          { path: 'task', loadChildren: () => import('./my/task/task.module').then((m) => m.TaskModule) },
        ],
      },
      {
        path: 'my',
        children: [
          { path: '', redirectTo: 'workbench', pathMatch: 'full' },
          {
            path: 'workbench',
            loadChildren: () => import('./workbench/workbench.module').then((m) => m.WorkbenchModule),
          },
          { path: 'checkin', loadChildren: () => import('./my/checkin/checkin.module').then((m) => m.CheckinModule) },
          { path: 'profile', loadChildren: () => import('./my/profile/profile.module').then((m) => m.ProfileModule) },
          { path: 'message', loadChildren: () => import('./my/message/message.module').then((m) => m.MessageModule) },
          { path: 'asset', loadChildren: () => import('./my/asset/asset.module').then((m) => m.AssetModule) },
          {
            path: 'approval',
            loadChildren: () => import('./my/approval/approval.module').then((m) => m.ApprovalModule),
          },
          { path: 'apply', loadChildren: () => import('./my/apply/apply.module').then((m) => m.ApplyModule) },
          { path: 'draft', loadChildren: () => import('./my/draft/draft.module').then((m) => m.DraftModule) },
          { path: 'revocal', loadChildren: () => import('./my/revocal/revocal.module').then((m) => m.RevocalModule) },
          // { path: 'work', loadChildren: () => import('./project/task/task.module').then((m) => m.TaskModule) },
          { path: 'work', loadChildren: () => import('./my/work/work.module').then((m) => m.WorkModule) },
          { path: 'task', loadChildren: () => import('./my/task/task.module').then((m) => m.TaskModule) },
          { path: 'receive', loadChildren: () => import('./my/receive/receive.module').then((m) => m.MyReceiveModule) },
          // 个人报销付款
          { path: 'reimburse', loadChildren: () => import('./my/reimburse/reimburse.module').then((m) => m.ReimburseModule) },
        ],
      },
      { path: 'test', loadChildren: () => import('./test/test.module').then((m) => m.TestModule) },
      {
        path: 'project',
        children: [
          { path: '', redirectTo: 'project/my', pathMatch: 'full' },
          {
            path: 'project',
            loadChildren: () => import('./project/project/project.module').then((m) => m.ProjectModule),
          },
          { path: 'task', loadChildren: () => import('./project/task/task.module').then((m) => m.TaskModule) },
          { path: 'pipeline', loadChildren: () => import('./pipeline/pipeline.module').then((m) => m.PipelineModule) },
          { path: 'ticket', loadChildren: () => import('./my/task/task.module').then((m) => m.TaskModule) },
        ],
      },
    ],
  },
  { path: '**', redirectTo: 'exception/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class RouteRoutingModule {}
