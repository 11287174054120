import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbFormService } from '@showye/nbweb-input/nb-form';
import { ShowyePageApiService, ShowyePageAuthService, ShowyePageUtilService } from '@showye/showye-page-base';
import { format } from 'date-fns';

@Component({
  selector: 'app-flow-view',
  templateUrl: './view.component.html',
  styleUrls: ['./task.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  @Input() id: any;
  @Input() isVisible = true;
  @Output() isVisibleChange = new EventEmitter<boolean>();

  constructor(
    private api: ShowyePageApiService,
    private coder: ShowyePageUtilService,
    private auth: ShowyePageAuthService,
    modalSrv: NzModalService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private formService: NbFormService,
    public msg: NzMessageService,
    public notify: NzNotificationService,
  ) {
    modalSrv.closeAll();
  }

  public route$: Subscription | null = null;

  downloadUrl = this.api.getDownloadUrl();
  uploadUrl = this.api.getUploadUrl();
  importUrl = this.api.getImportUrl();
  token: any = this.auth.token;
  pdfUrl = '../../../../assets/pdf/web/viewer.html?file=';

  task: any = {};
  ticket: any = {};
  forms: any;
  formGroups: FormGroup = new FormGroup({});
  operations: any = [];
  users: any = [];
  index = 0;
  data: any = {};
  userMap: any = {};
  roleMap: any = {};
  processId: any;
  showFlowModal = false;
  processName: any;

  ngOnDestroy(): void {
    if (this.route$) {
      this.route$.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.reloadUsers();
    this.reloadRoles();
    if (this.id) {
      this.reloadTicket();
      this.reloadTask();
      this.reloadForm();
      this.reloadOperations();
      this.reloadHistory();
    } else {
      this.msg.warning('请选择要处理的任务');
    }
  }

  reloadUsers(): void {
    this.api.listUsers().subscribe((e) => {
      this.users = e.data;
      this.userMap = e.data.reduce((a: any, c: any) => ({ ...a, [c.id]: c }), {});
    });
  }

  reloadRoles(): void {
    this.api.listPositions().subscribe((e) => {
      this.roleMap = e.data.reduce((a: any, c: any) => ({ ...a, [c.id]: c }), {});
    });
  }

  getTaskStatus(): string {
    switch (this.task.status) {
      default:
        return '待处理';
      case 2:
        return '已完成';
      case 3:
        return '已驳回';
    }
  }

  meta: any;

  reloadTicket(): void {
    this.api.getFlowTicket(this.id).subscribe((e) => {
      this.ticket = e.data;
      this.reloadMeta(e.data.processId);
      this.data = this.ticket.data;
      this.formService.reloadData(this.formGroups, this.forms, this.data);
      this.currentData = this.data && this.currentForm ? this.data[this.currentForm.code] : {};
    });
  }

  reloadMeta(pid: any): void {
    this.api.getProcessMeta(pid).subscribe((e) => {
      if (e.data?.options) {
        Object.keys(e.data.options).forEach((x: any) => {
          if (e.data.options[x]) {
            e.data.options[x] = e.data.options[x].reduce((a: any, c: any) => ({ ...a, [c.value]: c.label }), {});
          }
        });
      }
      if (e.data?.fields) {
        e.data.fields.forEach((x: any) => (x.code = x.path));
      }
      this.meta = e.data;
    });
  }

  explainMeta(field: any): any {
    return this.formService.explainData(this.ticket.data[this.meta.code], field, this.meta.options);
  }

  printTask: any;
  metaId: any;

  reloadTask(): void {
    this.api.getFlowTaskByTicketId(this.id).subscribe((e) => {
      this.task = e.data;
      this.processId = this.task.processId;
      this.processName = this.task?.processTitle;
      if (this.task.metaId) {
        this.metaId = this.task.metaId;
      }
      if (this.task.allowPrint) {
        this.printTask = this.api.getFlowTaskPrintUrl(this.task.id) + (this.token ? '?token=' + this.token : '');
      }
    });
  }

  assigner(): any {
    if (this.ticket.assignToId) {
      const st = this.users.filter((x: any) => x.id === this.ticket.assignToId);
      if (st && st.length > 0) {
        return st[0].name;
      }
    }
    return null;
  }

  reloadForm(): void {
    this.api.getFormByTicketId(this.id).subscribe((e) => {
      // const fm = [...e.data, { displayName: '流转记录', code: '$history', fields: [] }];
      const fm = e.data;
      this.formGroups = this.formService.reloadForm(fm, this.data);
      this.index = 0;
      this.currentForm = fm[0];
      this.code = this.currentForm.code;
      this.currentData = this.data ? this.data[this.currentForm.code] : {};
      this.forms = fm;
    });
  }

  reloadOperations(): void {
    this.api.getEdgesByTicketId(this.id).subscribe((e) => {
      this.operations = e.data;
    });
  }

  comment: any;
  sign: any;
  currentForm: any = {};
  code = '';
  currentData: any = {};

  get currentGroup(): FormGroup {
    return this.formGroups && this.formGroups.get(this.code) ? (this.formGroups.get(this.code) as FormGroup) : new FormGroup({});
  }

  isAuditInfo = 'none';
  isFormVisible = 'block';

  changeIndex(id: any): void {
    if (id <= this.forms.length - 1) {
      this.isAuditInfo = 'none';
      this.isFormVisible = 'block';
      this.data[this.currentForm.code] = this.currentGroup.value;
      this.currentForm = this.forms[id];
      this.code = this.currentForm.code;
      this.currentData = this.data[this.currentForm.code];
    } else {
      this.isAuditInfo = 'block';
      this.isFormVisible = 'none';
    }
  }

  statusTag: any = {
    2: { text: '已处理', color: 'green' },
    1: { text: '等待处理', color: 'blue' },
    3: { text: '正常完结', color: 'gray' },
    4: { text: '异常完结', color: 'red' },
  };
  history: any = [];

  reloadHistory(): void {
    this.api.getHistoryByTicketId(this.id).subscribe((e: any) => {
      this.history = e.data
        .sort((a: any, b: any) => a.id - b.id)
        .map((h: any) => ({
          date: !!h.committedAt ? format(new Date(h.committedAt), 'yyyy-MM-dd HH:mm') : '',
          operatorName: h.operatorName,
          routeName: h.routeName,
          // detail: (h.operatorName && h.routeName ? h.operatorName + ' ' + h.routeName + ' ' + '任务' : ''),
          nodeName: h.nodeName,
          taskStatus: h.taskStatus,
          comment: h.comment,
          isCurrent: h.isCurrent,
          singImg: h.sign && h.sign.path ? this.api.getDownloadUrl() + h.sign.path : '',
        }));
    });
  }

  downLoad = this.api.getDownloadUrl();

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }

  isViewVisibleChange(ev: any): void {
    this.showFlowModal = ev;
    this.close();
  }
}
