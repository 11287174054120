import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlainThemeModule } from '@delon/theme';
import { DelonACLModule } from '@delon/acl';
import { DelonFormModule } from '@delon/form';
import { SHARED_DELON_MODULES } from './shared-delon.module';
import { SHARED_ZORRO_MODULES } from './shared-zorro.module';
import { StColFilterPipe } from './utils/st-col-filter.pipe';
import { OptFilterPipe } from './utils/opt-filter.pipe';
import { OptinFilterPipe } from './utils/optin-filter.pipe';
import { NbSafePipe } from './utils/nb-safe.pipe';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { HolidayInfoComponent } from './component/holiday-info/holiday-info.component';
import { TripInfoComponent } from './component/trip-info/trip-info.component';
import { PositiveInfoComponent } from './component/positive-info/positive-info.component';
import { OvertimeInfoComponent } from './component/overtime-info/overtime-info.component';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { RefitInfoComponent } from './component/refit-info/refit-info.component';
import { ResignInfoComponent } from './component/resign-info/resign-info.component';
import { ShowImgModule } from '../components/show-img/show-img.module';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { DownFileComponent } from './component/download-file/down-file.component';
import { NbPeriodModule } from '@showye/nbweb-input/nb-period';
import { NbUploaderModule } from '@showye/nbweb-input/nb-uploader';

// #region third libs

const THIRDMODULES: Type<any>[] = [];

// #endregion

// #region your componets & directives

const COMPONENTS: Type<any>[] = [
  HolidayInfoComponent,
  TripInfoComponent,
  PositiveInfoComponent,
  OvertimeInfoComponent,
  RefitInfoComponent,
  ResignInfoComponent,
  DownFileComponent,
];
const DIRECTIVES: Type<any>[] = [];
const Pipes: Type<any>[] = [StColFilterPipe, OptFilterPipe, OptinFilterPipe, NbSafePipe];

// #endregion

@NgModule({
  imports: [
    NzTagModule,
    ShowImgModule,
    NzTimelineModule,
    NzDatePickerModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AlainThemeModule.forChild(),
    DelonACLModule,
    DelonFormModule,
    ...SHARED_DELON_MODULES,
    ...SHARED_ZORRO_MODULES,
    // third libs
    ...THIRDMODULES,
    NbPeriodModule,
    NbUploaderModule,
  ],
  declarations: [
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
    ...Pipes,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AlainThemeModule,
    DelonACLModule,
    DelonFormModule,
    ...SHARED_DELON_MODULES,
    ...SHARED_ZORRO_MODULES,
    // third libs
    ...THIRDMODULES,
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
    ...Pipes,
  ],
})
export class SharedModule {}
