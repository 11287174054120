import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NbXlsxService } from '@service/nb-xlsx.service';
import { NbApiService } from '@service/nb-api.service';
import { DatePipe } from '@angular/common';
import { API } from '@service/api';
import { ShowyePageAuthService } from '@showye/showye-page-base';
import Viewer from 'viewerjs';

@Component({
  selector: 'app-show-img',
  templateUrl: './show-img.component.html',
  styleUrls: ['./show-img.scss'],
})
export class ShowImgComponent {
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Input() showImgUrl = '';
  @Input() isVisible = false;

  get value(): any {
    return this.isVisible;
  }

  modalWidth = 600;

  set value(v: any) {
    this.isVisible = v;
    this.isVisibleChange.emit(v);
  }

  constructor(
    private xlsx: NbXlsxService,
    private api: NbApiService,
    private datePipe: DatePipe,
    private Api: API,
    private auth: ShowyePageAuthService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const domImg = document.getElementById('dowebok');
    if (domImg) {
      // tslint:disable-next-line:no-unused-expression
      new Viewer(domImg, {
        inline: false,
        button: false,
        navbar: false,
        title: false,
      });
    }
  }

  timer: any;

  ngOnChanges(changes: SimpleChanges): void {
    // if (this.showImgUrl) {
    //   this.timer = setTimeout(() => {
    //     this.getImgSize();
    //   }, 100);
    // }
  }

  ngOnDestroy(): void {
    // clearTimeout(this.timer);
  }

  // 获取图片的宽高
  getImgSize(): void {
    const box = document.getElementById('img-box');
    // @ts-ignore
    box.style.height = this.modalWidth + 'px';
    // @ts-ignore
    box.style.lineHeight = this.modalWidth - 100 + 'px';
    const domImg = document.getElementById('showImg');
    const imgNew = new Image();
    // @ts-ignore
    imgNew.src = domImg.src;
    // 图片比例
    const compareRateW = imgNew.height / imgNew.width;
    const compareRateH = imgNew.width / imgNew.height;
    if (compareRateW < 1) {
      // @ts-ignore
      domImg.style.width = this.modalWidth - 100 + 'px';
      // @ts-ignore
      domImg.style.height = (this.modalWidth - 100) * compareRateW + 'px';
    } else {
      // @ts-ignore
      domImg.style.height = this.modalWidth - 100 + 'px';
      // @ts-ignore
      domImg.style.width = (this.modalWidth - 100) * compareRateH + 'px';
    }
  }

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }

  rotate = 0;

  onRotation(ev: any): void {
    const domImg = document.getElementById('showImg');
    if (ev === 1) {
      this.rotate = this.rotate + 90;
      // @ts-ignore
      domImg.style.transform = `rotate(${this.rotate}deg)`;
    } else {
      this.rotate = this.rotate - 90;
      // @ts-ignore
      domImg.style.transform = `rotate(${this.rotate}deg)`;
    }
  }
}
