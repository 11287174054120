import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NbApiService } from '@service/nb-api.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { API } from '@service/api';
import { ShowyePageAuthService } from '@showye/showye-page-base';
import { format } from 'date-fns';
import { zhCN } from 'date-fns/locale';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-overtime-info',
  templateUrl: './overtime-info.component.html',
  styleUrls: ['./info.scss'],
})
export class OvertimeInfoComponent implements OnInit {
  @Input() detailInfo: any;
  @Input() isVisible = true;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;
  configs: any = [];
  checkins: any = [];
  timeItems: any = [];
  selected: any = {};
  // timeItems: any = [
  //   { color: 'red', label: '星期六', time: new Date(), type: 'day' },
  //   { color: 'blue', label: '上班打卡', time: new Date(), type: 'checkin' },
  //   { color: 'purple', label: '计划加班开始', time: new Date(), type: 'overtime' },
  //   { color: 'blue', label: '下班打卡', time: new Date(), type: 'checkin' },
  //   { color: 'purple', label: '计划加班结束', time: new Date(), type: 'overtime' },
  // ];
  isEditVisible = false;
  isRecordVisible = false;
  loading = false;

  get value(): any {
    return this.isVisible;
  }

  set value(v: any) {
    this.isVisible = v;
    this.isVisibleChange.emit(v);
  }

  info: any;
  id: any;
  token: any = this.auth.token;
  statusEnum: any = {
    accepted: '待核销',
    confirmed: '已核销',
    pending: '审核中',
    rejected: '已驳回',
    cancelled: '已撤销',
  };
  // 加班核销方式
  methodEnum: any = {
    1: '加班费',
    2: '调休',
  };
  // 加班類型
  workStatusEnum: any = {
    0: '工作日',
    1: '休息日',
  };

  dayEnum: any = {
    0: '星期天',
    1: '星期一',
    2: '星期二',
    3: '星期三',
    4: '星期四',
    5: '星期五',
    6: '星期六',
  };
  downloadUrl = this.api.getDownloadUrl();
  paid: any;
  recordList: any[] = [];

  constructor(
    private api: NbApiService,
    modalSrv: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public msg: NzMessageService,
    public notify: NzNotificationService,
    private apiUrl: API,
    private modal: NzModalService,
    private auth: ShowyePageAuthService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.id = this.detailInfo.id;
    this.getOvertimeInfo();
  }

  refreshTimelineData(e: any): void {
    const param = {
      userId: e.userId,
      beginAt: new Date(e.applyBeginAt).setHours(0, 0, 0, 0),
      endAt: new Date(e.applyEndAt).setHours(23, 59, 59, 999),
    };
    this.api.getCheckinRuleMapByDaterange(param).subscribe((x: any) => {
      this.configs = x.data;
      this.generateTimelineData();
    });
    this.api.listStaffCheckinsByDateRange(param).subscribe((x: any) => {
      this.checkins = x.data;
      this.generateTimelineData();
    });
  }

  generateTimelineData(): void {
    const ti: any = [];
    const dayMap: any = {};
    if (!!this.configs && this.configs.length > 0) {
      this.configs.forEach((x: any) => {
        if (!!x.beginAt && !!x.endAt) {
          const st = new Date(x.beginAt);
          st.setHours(0, 0, 0, 0);
          dayMap[format(st, 'yyyy-MM-dd')] = { label: '', time: st, color: 'red', type: 'day' };
          ti.push({
            time: new Date(x.beginAt),
            label: '上班',
            color: 'lightgray',
            type: 'config',
          });
          ti.push({
            label: '下班',
            time: new Date(x.endAt),
            color: 'lightgray',
            type: 'config',
          });
        }
      });
    }
    if (!!this.checkins && this.checkins.length > 0) {
      this.checkins.forEach((x: any) => {
        const st = new Date(x.signAt);
        st.setHours(0, 0, 0, 0);
        dayMap[format(st, 'yyyy-MM-dd')] = {
          label: format(st, 'EEEE', { locale: zhCN }),
          time: st,
          color: 'red',
          type: 'day',
        };
        ti.push({
          label: x.onOff ? '上班打卡' : '下班打卡',
          color: 'blue',
          time: new Date(x.signAt),
          type: 'checkin',
        });
      });
    }
    if (!!this.selected) {
      const st = new Date(this.selected.applyBeginAt);
      st.setHours(0, 0, 0, 0);
      dayMap[format(st, 'yyyy-MM-dd')] = {
        label: format(st, 'EEEE', { locale: zhCN }),
        time: st,
        color: 'red',
        type: 'day',
      };
      const et = new Date(this.selected.applyEndAt);
      et.setHours(0, 0, 0, 0);
      dayMap[format(et, 'yyyy-MM-dd')] = {
        label: format(et, 'EEEE', { locale: zhCN }),
        time: et,
        color: 'red',
        type: 'day',
      };
      ti.push({
        label: '计划加班开始',
        color: 'purple',
        time: new Date(this.selected.applyBeginAt),
        type: 'overtime',
      });
      ti.push({
        label: '计划加班结束',
        color: 'purple',
        time: new Date(this.selected.applyEndAt),
        type: 'overtime',
      });
    }
    Object.keys(dayMap).forEach((k: any) => ti.push(dayMap[k]));
    this.timeItems = ti.sort((a: any, b: any) => a.time - b.time);
  }

  // 获取加班详情信息
  getOvertimeInfo(): void {
    if (this.id) {
      this.api.overtimeInfo({ id: this.id }).subscribe((x: any) => {
        this.info = x.data;
        this.selected = this.info;
        this.paidType = this.info.method;
        this.selected.userName = this.info.userName;
        this.selected.domainName = this.info.domainName;
        this.selected.methodName = this.methodEnum[this.info.method];
        this.refreshTimelineData(this.info);
      });
    }
  }

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }

  onDownloadPrint(type: any): void {
    if (type === 2) {
      window.open(this.apiUrl.OVERTIME_DETAIL_PRINT(this.detailInfo.id, type) + '&token=' + this.token, '_blank');
    } else {
      window.location.href = this.apiUrl.OVERTIME_DETAIL_PRINT(this.detailInfo.id, type) + '&token=' + this.token;
    }
  }

  onEdit(): void {
    this.paid = '';
    this.paidType = this.info.method;
    this.isEditVisible = true;
  }

  // 查询核销记录
  onRecord(): void {
    if (this.id) {
      this.api.overtimeConfirmList(this.id).subscribe((e: any) => {
        if (e.code === 200) {
          if (!e.data || e.data?.length === 0) {
            this.notify.info('暂无核销记录!', '');
          } else {
            this.recordList = e.data.reverse();
            this.isRecordVisible = true;
          }
        }
      });
    }
  }

  paidType: any;

  onPaidTypeChange(ev: any): void {
    this.paidType = ev;
  }

  onChange(value: string): void {
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if (reg.test(value)) {
      this.paid = Number(value);
    } else {
      this.inputElement!.nativeElement.value = this.paid = '';
    }
  }

  onSubmit(): void {
    const par = { id: this.id, period: this.paid, method: this.paidType };
    // if (!this.paid) {
    //   this.msg.info('请填写核销时长！');
    //   return;
    // }
    if (!this.paidType) {
      this.msg.info('请选择核销方式！');
      return;
    }
    if (par.id) {
      this.loading = true;
      this.api.update(par).subscribe(
        (e: any) => {
          if (e.code === 200) {
            this.isEditVisible = this.loading = false;
            this.notify.success('核销成功', '');
            this.getOvertimeInfo();
          }
        },
        () => (this.loading = false),
      );
    }
  }
}
