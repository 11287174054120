<nz-modal [(nzVisible)]="isVisible" nzTitle="加班详情" (nzOnCancel)="close()" [nzWidth]="1150">
  <ng-container *nzModalContent>
    <div style="position: relative">
      <div class="modal-header-right-button">
        <button nz-button nzType="dashed" (click)="onDownloadPrint(1)">下载</button>
        <button nz-button nzType="dashed" (click)="onDownloadPrint(2)">打印</button>
      </div>
    </div>
    <div class="d-flex">
      <div style="width: 60%">
        <div style="margin-bottom: 10px">
          <div class="title">基本信息</div>
          <sv-container [col]="2">
            <sv label="申请流水号">{{ info?.applyId }}</sv>
            <sv label="申请人">{{ info?.userName }}</sv>
            <sv label="所属部门">{{ info?.domainName }}</sv>
            <sv label="申请时间">{{ info?.appliedAtStr }}</sv>
            <sv label="状态">{{ info?.status ? statusEnum[info.status] : '' }}</sv>
          </sv-container>
        </div>

        <div style="margin-bottom: 10px">
          <div class="title">加班信息</div>
          <sv-container [col]="2">
            <sv label="加班核销方式">{{ info?.method ? methodEnum[info.method] : '' }}</sv>
            <sv label="加班时长（小时）">{{ info?.plan }}</sv>
            <sv label="计划加班时间">{{ info?.applyBeginAt | date: 'yyyy-MM-dd HH:mm' }} ~ {{ info?.applyEndAt | date: 'HH:mm' }}</sv>
            <sv label="类型"> {{ workStatusEnum[info?.workStatus] || '' }}</sv>
            <sv label="加班原因" [col]="1">{{ info?.reason }}</sv>
            <sv label="图片" [col]="1">
              <div *ngIf="info?.pictureList?.length > 0" style="display: flex; flex-wrap: wrap">
                <img style="width: 350px; margin-bottom: 10px" *ngFor="let op of info?.pictureList" [src]="downloadUrl + op.path" />
              </div>
            </sv>
          </sv-container>
        </div>
      </div>

      <div style="width: 1px; height: auto; background: #eee; margin: 0 30px"></div>

      <div style="width: 40%">
        <div style="margin-bottom: 10px">
          <div class="title">核销信息</div>
          <sv-container [col]="1">
            <sv label="时间">{{ info?.applyBeginAt | date: 'MM-dd HH:mm' }} > {{ info?.applyEndAt | date: 'MM-dd HH:mm' }}</sv>
            <sv label="实际（小时）">{{ info?.actual }} </sv>
            <sv label="核销（小时）"
              >{{ info?.period }}
              <div *ngIf="info?.status === 'confirmed'" style="display: inline-block; margin-left: 30px">
                <img src="../../../../assets/img/edit.png" style="margin-right: 15px; cursor: pointer" (click)="onEdit()" />
                <img src="../../../../assets/img/record.png" style="cursor: pointer" (click)="onRecord()" />
              </div>
            </sv>
            <sv label="核销人">{{ info?.confirmedByName }}</sv>
            <sv label="核销时间">{{ info?.confirmedAt | date: 'yyyy-MM-dd HH:mm' }}</sv>
          </sv-container>
        </div>
        <div style="margin-top: 40px">
          <nz-timeline nzMode="right">
            <ng-container *ngFor="let op of timeItems">
              <nz-timeline-item
                *ngIf="op.type !== 'day'"
                [nzPosition]="op.type === 'overtime' ? 'right' : 'left'"
                [nzColor]="op.color"
                [nzLabel]="op.label"
              >
                {{ op.time | date: 'HH:mm:ss' }}
              </nz-timeline-item>
              <nz-timeline-item
                *ngIf="op.type === 'day'"
                nzPosition="right"
                [nzColor]="op.color"
                [nzDot]="dotTemplate"
                [nzLabel]="op.label"
              >
                {{ op.time | date: 'yyyy-MM-dd' }}
              </nz-timeline-item>
            </ng-container>
          </nz-timeline>
          <ng-template #dotTemplate>
            <i nz-icon nzType="clock-circle-o" style="font-size: 16px"></i>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>

<!--修改核销弹窗-->
<nz-modal [(nzVisible)]="isEditVisible" nzTitle="修改核销" nzCentered [nzWidth]="500" (nzOnCancel)="isEditVisible = false">
  <ng-container *nzModalContent>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>核销方式</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-select [(ngModel)]="paidType" (ngModelChange)="onPaidTypeChange($event)">
          <nz-option nzLabel="加班费" [nzValue]="1"></nz-option>
          <nz-option nzLabel="调休" [nzValue]="2"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>核销（小时）</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="核销时长不能为空">
        <input nz-input #inputElement [ngModel]="paid" (ngModelChange)="onChange($event)" placeholder="请输入数字" />
      </nz-form-control>
    </nz-form-item>
  </ng-container>
  <div *nzModalFooter>
    <div style="text-align: center">
      <button nz-button nzType="primary" style="margin-right: 40px" [nzLoading]="loading" (click)="onSubmit()">确定</button>
      <button nz-button nzType="default" (click)="isEditVisible = false">取消</button>
    </div>
  </div>
</nz-modal>

<!--核销记录弹窗-->
<nz-modal [(nzVisible)]="isRecordVisible" nzTitle="核销记录" nzCentered [nzWidth]="500" (nzOnCancel)="isRecordVisible = false">
  <ng-container *nzModalContent>
    <div class="record-list-box">
      <div class="each-record" *ngFor="let data of recordList">
        <sv-container [col]="1">
          <sv label="核销方式">{{ data?.method ? methodEnum[data?.method] : '' }}</sv>
          <sv label="核销（小时）">{{ data?.period }}</sv>
          <sv label="核销人">{{ data?.confirmedName }}</sv>
          <sv label="核销时间">{{ data?.confirmedAt | date: 'yyyy-MM-dd HH:mm:ss' }}</sv>
        </sv-container>
        <div class="nb-divider" style="margin-bottom: 15px"></div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>
