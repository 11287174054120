<router-outlet></router-outlet>

<nz-modal [(nzVisible)]="locked" [nzClosable]="false" [nzMaskClosable]="false">
  <ng-container *nzModalContent>
    <div class="locking-box">
      <div class="head"></div>
      <div class="content">
        <div class="up-part">
          <div class="img-bg">
            <img style="border-radius: 50%" *ngIf="userInfo?.avatar" [src]="downloadUrl + userInfo?.avatar" alt="." />
          </div>
          <div style="font-weight: bold">账号：{{ userInfo?.username }}</div>
        </div>
        <div class="tip-gray">您由于长时间未操作系统，已自动锁定；请输入密码解锁！</div>
        <!--        <div *ngIf="count < 3 && count !== 0" class="tip-gray">-->
        <!--          密码输入错误，请重新输入。<span class="tip-red">剩余{{ count }}次机会</span>-->
        <!--        </div>-->
        <!--        <div *ngIf="count == 0" class="tip-red">密码输入错误，请重新输入。即将退出{{ countdown }}s</div>-->
        <div>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>密码</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <nz-input-group nzSize="large" nzPrefixIcon="lock">
                <input
                  nz-input
                  [type]="passwordType"
                  placeholder="请输入密码"
                  autocomplete="new-password"
                  [(ngModel)]="password"
                  (ngModelChange)="onPasswordChange($event)"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <div style="text-align: center">
      <button style="width: 100px; margin-right: 30px" nz-button nzType="primary" [nzLoading]="loading" (click)="unlockScreen()">
        登录
      </button>
      <button
        style="width: 100px"
        nz-button
        nzType="default"
        nz-popconfirm
        nzPopconfirmTitle="是否确认切换账号？切换账号将丢失未保存的操作。"
        (nzOnConfirm)="logout()"
      >
        切换账号
      </button>
    </div>
  </div>
</nz-modal>

<!--<nz-modal [(nzVisible)]="showRichTip">-->
<!--  <ng-container *nzModalContent>-->
<!--    <div>{{ richTip }}</div>-->
<!--  </ng-container>-->
<!--  <div *nzModalFooter>-->
<!--    <div style="text-align: center">-->
<!--      <button style="width: 100px; margin-right: 30px" nz-button nzType="primary" [nzLoading]="showRichLoading" (click)="submitRicheContent()">保存</button>-->
<!--      <button style="width: 100px" nz-button nzType="default" (click)="showRichTip = false">舍弃</button>-->
<!--    </div>-->
<!--  </div>-->
<!--</nz-modal>-->
