<page-header title=""></page-header>
<nz-card style="width: 100%">
  <nz-card-tab>
    <nz-tabset nzSize="large" [(nzSelectedIndex)]="index" (nzSelectedIndexChange)="to($event)">
      <nz-tab nzTitle="进行中"></nz-tab>
      <nz-tab nzTitle="已完结"></nz-tab>
      <nz-tab nzTitle="已驳回"></nz-tab>
      <nz-tab nzTitle="已撤销"></nz-tab>
    </nz-tabset>
  </nz-card-tab>
  <!--  <div>-->
  <!--    <st [columns]="columns" [data]="tickets"></st>-->
  <!--  </div>-->

  <div *ngIf="index === 0 || index === 1 || index === 2">
    <nz-table
      *ngIf="isGoRefresh"
      #logonTable
      [nzData]="tickets"
      [nzFrontPagination]="false"
      [nzLoading]="loading"
      (nzQueryParams)="onQueryParamsChange($event)"
    >
      <thead>
        <tr>
          <th nzColumnKey="taskId" nzCustomFilter>流水号</th>
          <th nzColumnKey="processId" [nzFilterMultiple]="false" [nzFilters]="processFilters" [nzFilterFn]="true">申请类型</th>
          <th nzColumnKey="taskApplicantName" nzCustomFilter>申请人</th>
          <th nzColumnKey="domainId" [nzFilterMultiple]="false" [nzFilters]="domainFilters" [nzFilterFn]="true">流程所属部门</th>
          <th nzColumnKey="nodeName" nzCustomFilter>当前环节</th>
          <th nzColumnKey="taskCreatedAt" nzCustomFilter>申请时间</th>
          <th nzColumnKey="previousOperatorName" nzCustomFilter>最后审批人</th>
          <th nzColumnKey="previousCommittedAt" nzCustomFilter>最后审批时间</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of logonTable.data">
          <td>{{ data?.taskId }}</td>
          <td>{{ data?.processTitle }}</td>
          <td>{{ data?.taskApplicantName }}</td>
          <td>{{ data?.taskApplicantDomainName }}</td>
          <td>{{ data?.nodeName }}</td>
          <td>{{ data?.taskCreatedAt | date: 'yyyy-MM-dd' }}</td>
          <td>{{ data?.previousOperatorName }}</td>
          <td>{{ data?.previousCommittedAt | date: 'yyyy-MM-dd' }}</td>
          <td style="width: 200px">
            <a *ngIf="index === 1 || index === 2" nz-button nzType="link" nzSize="small" (click)="view(data)">查看</a>
            <a *ngIf="index === 0" nz-button nzType="link" nzSize="small" (click)="dealWith(data)">处理 </a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>

  <div *ngIf="index === 3">
    <nz-table
      #logonTable
      [nzData]="tickets"
      [nzFrontPagination]="false"
      [nzLoading]="loading"
      (nzQueryParams)="onQueryParamsChange($event)"
    >
      <thead>
        <tr>
          <th nzColumnKey="taskId" nzCustomFilter>流水号</th>
          <th nzColumnKey="processId" [nzFilterMultiple]="false" [nzFilters]="processFilters" [nzFilterFn]="true">申请类型</th>
          <th nzColumnKey="taskApplicantName" nzCustomFilter>申请人</th>
          <th nzColumnKey="domainId" [nzFilterMultiple]="false" [nzFilters]="domainFilters" [nzFilterFn]="true">流程所属部门</th>
          <th nzColumnKey="updatedAt" nzCustomFilter>撤销时间</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of logonTable.data">
          <td>{{ data?.taskId }}</td>
          <td>{{ data?.processTitle }}</td>
          <td>{{ data?.taskApplicantName }}</td>
          <td>{{ data?.taskApplicantDomainName }}</td>
          <td>{{ data?.updatedAt | date: 'yyyy-MM-dd' }}</td>
          <td style="width: 200px">
            <a nz-button nzType="link" nzSize="small" (click)="view(data)">查看</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>

  <div style="text-align: right; line-height: 50px; margin: 10px 0">
    <nz-pagination
      [nzPageIndex]="pageIndex"
      [nzTotal]="total"
      nzShowSizeChanger
      [nzPageSize]="pageSize"
      (nzPageIndexChange)="pageChange($event)"
      (nzPageSizeChange)="pageSizeChange($event)"
    ></nz-pagination>
  </div>
</nz-card>

<!--审核处理弹窗-->
<app-flow-edit
  *ngIf="showModal"
  [id]="ticketId"
  [isVisible]="showModal"
  (isVisibleChange)="isVisibleChange($event)"
  (isRefresh)="isRefresh($event)"
>
</app-flow-edit>

<!--审核查看弹窗-->
<app-flow-view *ngIf="showViewModal" [id]="ticketId" [isVisible]="showViewModal" (isVisibleChange)="isViewVisibleChange($event)">
</app-flow-view>
