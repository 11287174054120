import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NbApiService } from '@service/nb-api.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { API } from '@service/api';
import { ShowyePageAuthService } from '@showye/showye-page-base';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-holiday-info',
  templateUrl: './holiday-info.component.html',
  styleUrls: ['./info.scss'],
})
export class HolidayInfoComponent implements OnInit {
  @Input() isVisible = true;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Input() detailInfo: any;
  @Input() isSelf = false;
  info: any;
  typeEnums: any = {};
  realTimespan = 0;

  get value(): any {
    return this.isVisible;
  }

  set value(v: any) {
    this.isVisible = v;
    this.isVisibleChange.emit(v);
  }

  statusEnums: any = {
    pending: { label: '审核中', color: 'red' },
    accepted: { label: '已完成', color: 'green' },
    rejected: { label: '已驳回', color: 'orange' },
    cancelled: { label: '已撤销', color: 'default' },
  };
  token = this.auth.token;
  downloadUrl = this.api.getDownloadUrl();
  uploadUrl = this.api.getUploadUrl();
  pdfUrl = '../../../../assets/pdf/web/viewer.html?file=';
  form: FormGroup = this.fb.group({
    id: [null],
    beginAt: [null, Validators.required],
    endAt: [null, Validators.required],
    realTimespan: [null],
  });
  loading = false;
  isEditVisible = false;
  beginAtValue: any;
  endAtValue: any;
  invalid = true;
  proofForm: FormGroup;
  isProofVisible = false;

  constructor(
    private api: NbApiService,
    modalSrv: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public msg: NzMessageService,
    public notify: NzNotificationService,
    private apiUrl: API,
    private auth: ShowyePageAuthService,
    private modal: NzModalService,
    private fb: FormBuilder,
    private date: DatePipe,
  ) {
    this.proofForm = this.fb.group({
      id: [null],
      certificateFileList: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.reloadHolidayType();
    this.getHolidayDetail(this.detailInfo.id);
  }

  reloadHolidayType(): void {
    this.api.listHolidayTypes().subscribe((e: any) => {
      this.typeEnums = e.data.reduce((a: any, c: any) => ({ ...a, [c.id]: c.name }), {});
    });
  }

  // 获取请假详情
  getHolidayDetail(id: any): void {
    if (id) {
      this.api.getHolidayDetail(id).subscribe((e: any) => {
        this.info = e.data;
        this.realTimespan = this.info.timespan;
        this.info.beginAtAfterHour = this.date.transform(this.info.beginAt, 'HH:mm');
        this.info.endAtAfterHour = this.date.transform(this.info.endAt, 'HH:mm');
        this.info.realBeginAt ? (this.info.realBeginAtAfterHour = this.date.transform(this.info.realBeginAt, 'HH:mm')) : '';
        this.info.realEndAt ? (this.info.realEndAtAfterHour = this.date.transform(this.info.realEndAt, 'HH:mm')) : '';
        if (this.info?.sickCertificateFileList?.length > 0) {
          this.proofForm.reset({ id: this.info?.id, certificateFileList: this.info?.sickCertificateFileList });
        }
      });
    }
  }

  jumpTask = (e: any) => {
    this.router.navigate(['/my/task/view', { id: e.ticketId }]);
  };

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }

  // 补充凭证
  onAddProof(): void {
    this.isProofVisible = true;
  }

  onDownloadPrint(type?: any): void {
    if (type === 2) {
      window.open(this.apiUrl.HOLIDAY_PRINT(this.detailInfo.id, type) + '&token=' + this.token, '_blank');
    } else {
      window.location.href = this.apiUrl.HOLIDAY_PRINT(this.detailInfo.id, type) + '&token=' + this.token;
    }
  }

  /**
   * 撤销假期
   */
  cancelHoliday(): void {
    if (this.info?.id) {
      this.api.cancelHoliday(this.info.id).subscribe((e: any) => {
        if (e.code === 200) {
          this.notify.success('', '撤销成功！');
          this.getHolidayDetail(this.detailInfo.id);
        }
      });
    }
  }

  editHoliday(): void {
    this.isEditVisible = true;
    this.beginAtValue = this.endAtValue = null;
    this.form.reset({});
  }

  changeBeginAt(ev: any): void {
    // console.log('开始时间', ev, this.info);
    if (ev) {
      if (
        new Date(ev.from).getTime() < new Date(this.info.beginAt).getTime() ||
        new Date(ev.from).getTime() > new Date(this.info.endAt).getTime()
      ) {
        this.notify.info('', '请在请假范围内销假！');
        this.invalid = true;
        return;
      }
      if (this.endAtValue && new Date(ev.from).getTime() > new Date(this.endAtValue.from).getTime()) {
        this.notify.info('', '开始时间不能大于结束时间');
        this.invalid = true;
        return;
      }
      if (this.endAtValue && ev.from) {
        this.invalid = false;
        this.calculateTimespan(ev.from, this.endAtValue.to);
      }
    }
  }

  changeEndAt(ev: any): void {
    // console.log('结束时间', ev, this.info);
    if (ev) {
      if (
        new Date(ev.to).getTime() < new Date(this.info.beginAt).getTime() ||
        new Date(ev.to).getTime() > new Date(this.info.endAt).getTime() + 1000
      ) {
        this.invalid = true;
        this.notify.info('', '请在请假范围内销假！');
        return;
      }
      if (this.beginAtValue && new Date(this.beginAtValue.from).getTime() > new Date(ev.from).getTime()) {
        this.notify.info('', '开始时间不能大于结束时间');
        this.invalid = true;
        return;
      }
      if (this.beginAtValue && ev.from) {
        this.invalid = false;
        this.calculateTimespan(this.beginAtValue.from, ev.to);
      }
    }
  }

  calculateTimespan(beginAt: any, endAt: any): void {
    if (beginAt && endAt) {
      this.api.calculateHolidayTimespan({ id: this.info.id, beginAt, endAt }).subscribe((e: any) => {
        if (e.code === 200) {
          this.realTimespan = e.data;
        }
      });
    }
  }

  onProofConfirm(): void {
    if (this.info.id) {
      const par = { ...this.proofForm.value, id: this.info.id };
      this.loading = true;
      this.api.addSickHolidayCertificate(par).subscribe(
        (e: any) => {
          if (e.code === 200) {
            this.loading = false;
            this.notify.success('', '上传成功！');
            this.isProofVisible = false;
            this.getHolidayDetail(this.detailInfo.id);
          }
        },
        () => (this.loading = false),
      );
    }
  }

  onConfirm(): void {
    if (this.info.id) {
      const par = {
        id: this.info.id,
        beginAt: this.beginAtValue.from,
        endAt: this.endAtValue.to,
        realTimespan: this.realTimespan,
      };
      this.loading = true;
      this.api.updateRealHoliday(par).subscribe(
        (e: any) => {
          if (e.code === 200) {
            this.loading = false;
            this.notify.success('', '修改成功！');
            this.isEditVisible = false;
            this.getHolidayDetail(this.detailInfo.id);
          }
        },
        () => (this.loading = false),
      );
    }
  }
}
