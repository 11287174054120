<nz-modal *ngIf="isVisible" [(nzVisible)]="isVisible" [nzTitle]="title" [nzStyle]="{ top: '50px' }" (nzOnCancel)="close()" [nzWidth]="1000">
  <ng-container *nzModalContent>
    <div
      style="height: 78vh"
      class="visnet"
      [visNetwork]="visNetwork"
      [visNetworkData]="visNetworkData"
      [visNetworkOptions]="visNetworkOptions"
      (initialized)="networkInitialized()"
    ></div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>
