<nz-modal [(nzVisible)]="isVisible" nzTitle="请假详情" (nzOnCancel)="close()" [nzWidth]="1350">
  <ng-container *nzModalContent>
    <div style="position: relative">
      <div class="modal-header-right-button">
        <button *ngIf="info?.typeId == 3" nz-button nzType="dashed" (click)="onAddProof()">补充凭证</button>
        <button nz-button nzType="dashed" (click)="onDownloadPrint(1)">下载</button>
        <button nz-button nzType="dashed" (click)="onDownloadPrint(2)">打印</button>
      </div>

      <div class="info">
        <div>
          <div class="part-content">
            <div class="title">基本信息</div>
            <div class="content">
              <sv-container [col]="3">
                <sv label="申请流水号">{{ info?.applyId }}</sv>
                <sv label="申请人">{{ info?.userName }}</sv>
                <sv label="所属部门">{{ info?.domainName }}</sv>
                <sv label="申请时间">{{ info?.createdAt | date: 'yyyy-MM-dd HH:mm:ss' }}</sv>
                <sv label="状态">
                  <nz-tag *ngIf="info?.status" [nzColor]="statusEnums[info?.status]?.color">{{ statusEnums[info?.status]?.label }}</nz-tag>
                </sv>
              </sv-container>
            </div>
          </div>
          <div class="part-content">
            <div class="title">请假信息</div>
            <div class="content">
              <sv-container [col]="3">
                <sv label="请假类型">{{ info?.typeId ? typeEnums[info?.typeId] : '' }}</sv>
                <sv label="请假单位">{{ info?.unit === 1 ? '小时' : '天' }}</sv>
                <sv label="请假时长">{{ info?.timespan }}{{ info?.unit === 1 ? '小时' : '天' }}</sv>
                <sv *ngIf="info?.unit === 1" label="开始时间">{{ info?.beginAt | date: 'yyyy-MM-dd HH:mm' }}</sv>
                <sv *ngIf="info?.unit === 1" label="结束时间">{{ info?.endAt | date: 'yyyy-MM-dd HH:mm' }}</sv>
                <sv *ngIf="info?.unit !== 1" label="开始时间"
                  >{{ info?.beginAt | date: 'yyyy-MM-dd' }} {{ info?.beginAtAfterHour < '12:00' ? '上午' : '下午' }}</sv
                >
                <sv *ngIf="info?.unit !== 1" label="结束时间"
                  >{{ info?.endAt | date: 'yyyy-MM-dd' }} {{ info?.endAtAfterHour < '12:00' ? '上午' : '下午' }}</sv
                >
                <sv [col]="1" label="请假事由">{{ info?.reason }}</sv>
                <sv [col]="1" label="图片">
                  <div *ngIf="info?.pictureList?.length > 0" style="display: flex; flex-wrap: wrap">
                    <img style="width: 350px; margin-bottom: 10px" *ngFor="let op of info?.pictureList" [src]="downloadUrl + op.path" />
                  </div>
                </sv>
                <sv *ngIf="info?.typeId == 3" [col]="1" label="凭证">
                  <div *ngIf="info?.sickCertificateFileList?.length > 0" style="display: flex; flex-wrap: wrap">
                    <img
                      style="width: 350px; margin-bottom: 10px"
                      *ngFor="let op of info?.sickCertificateFileList"
                      [src]="downloadUrl + op.path"
                    />
                  </div>
                </sv>
              </sv-container>
            </div>
          </div>
        </div>

        <div *ngIf="info?.status === 'accepted' && !isSelf" style="border-left: 1px solid #ccc; padding-left: 20px">
          <div class="part-content">
            <div class="title">实际请假信息</div>
            <div class="content">
              <sv-container [col]="1">
                <sv *ngIf="info?.unit === 1" label="开始时间">
                  {{ info?.realBeginAt | date: 'yyyy-MM-dd HH:mm' }}
                </sv>
                <sv *ngIf="info?.unit === 1" label="结束时间">
                  {{ info?.realEndAt | date: 'yyyy-MM-dd HH:mm' }}
                </sv>
                <sv *ngIf="info?.unit !== 1" label="开始时间">
                  {{ info?.realBeginAt | date: 'yyyy-MM-dd' }}{{ info?.realBeginAtAfterHour < '12:00' ? '上午' : '下午' }}
                </sv>
                <sv *ngIf="info?.unit !== 1" label="结束时间">
                  {{ info?.realEndAt | date: 'yyyy-MM-dd' }}
                  {{ info?.realEndAtAfterHour < '12:00' ? '上午' : '下午' }}
                </sv>
                <sv label="请假时长"> {{ info?.realTimespan }}{{ info?.unit === 1 ? '小时' : '天' }} </sv>
                <sv label="操作人">{{ info?.realUpdateByName }}</sv>
              </sv-container>
              <div *ngIf="!info?.realUpdateBy" style="width: 100%; text-align: center; margin-top: 20px">
                <button nz-button nzType="primary" (click)="cancelHoliday()">撤销</button>
                <button nz-button nzType="primary" (click)="editHoliday()">销假</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>

<!--补充凭证-->
<nz-modal [(nzVisible)]="isProofVisible" nzTitle="补充凭证" (nzOnCancel)="isProofVisible = false" [nzWidth]="700">
  <ng-container *nzModalContent>
    <form [formGroup]="proofForm">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24">补充凭证</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <nb-uploader-file
            formControlName="certificateFileList"
            [readonly]="false"
            [uploadUrl]="uploadUrl"
            [downloadUrl]="downloadUrl"
            [pdfUrl]="pdfUrl"
            [token]="token"
            [max]="10"
          >
          </nb-uploader-file>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
  <div *nzModalFooter>
    <div style="text-align: center">
      <button
        style="width: 100px"
        nz-button
        nzType="primary"
        [nzLoading]="loading"
        (click)="onProofConfirm()"
        [disabled]="proofForm.invalid"
      >
        确定
      </button>
      <button style="width: 100px; margin-left: 30px" nz-button nzType="default" (click)="isProofVisible = false">取消</button>
    </div>
  </div>
</nz-modal>
<!--销假弹窗-->
<nz-modal [(nzVisible)]="isEditVisible" nzTitle="修改实际请假时间" (nzOnCancel)="isEditVisible = false" [nzWidth]="500">
  <ng-container *nzModalContent>
    <div class="edit-holiday-modal">
      <div class="edit-holiday-tip">销假修改请假时间，只能在申请范围内修改</div>
      <form [formGroup]="form">
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24">实际开始时间</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <nb-period
              [type]="info?.unit == 1 ? 'minutely' : 'halfday'"
              formControlName="beginAt"
              [(ngModel)]="beginAtValue"
              (ngModelChange)="changeBeginAt($event)"
            ></nb-period>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24">实际结束时间</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <nb-period
              [type]="info?.unit == 1 ? 'minutely' : 'halfday'"
              formControlName="endAt"
              [(ngModel)]="endAtValue"
              (ngModelChange)="changeEndAt($event)"
            ></nb-period>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24">请假时长</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <div>{{ realTimespan }}{{ info?.unit === 1 ? '小时' : '天' }}</div>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <div style="text-align: center">
      <button style="width: 100px" nz-button nzType="primary" [nzLoading]="loading" (click)="onConfirm()" [disabled]="invalid">确定</button>
      <button style="width: 100px; margin-left: 30px" nz-button nzType="default" (click)="isEditVisible = false">取消</button>
    </div>
  </div>
</nz-modal>
