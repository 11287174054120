<nz-modal [(nzVisible)]="isVisible" nzTitle="转正详情" (nzOnCancel)="close()" [nzWidth]="1350">
  <ng-container *nzModalContent>
    <div style="position: relative">
      <div class="modal-header-right-button">
        <button nz-button nzType="dashed" (click)="onDownloadPrint(1)">下载</button>
        <button nz-button nzType="dashed" (click)="onDownloadPrint(2)">打印</button>
      </div>
      <div class="info">
        <div class="part-content">
          <div class="title">基本信息</div>
          <div class="content">
            <sv-container [col]="3">
              <sv label="转正编号">{{ info?.serial }}</sv>
              <sv label="申请流水号">{{ info?.applyId }}</sv>
              <sv label="申请时间">{{ info?.applyAt | date: 'yyyy-MM-dd HH:mm:ss' }}</sv>
              <sv label="申请人">{{ info?.userName }}</sv>
              <sv label="工号">{{ info?.userSerial }}</sv>
              <sv label="所属岗位">{{ info?.positionNames }}</sv>
              <sv label="所属部门">{{ info?.domainName }}</sv>
              <sv label="状态">
                <nz-tag *ngIf="info?.status" [nzColor]="statusEnums[info?.status]?.color">{{ statusEnums[info?.status]?.label }}</nz-tag>
              </sv>
            </sv-container>
          </div>
        </div>

        <div class="part-content">
          <div class="title">转正信息</div>
          <div class="content">
            <sv-container [col]="3">
              <sv label="入职时间">{{ info?.enrolledAt | date: 'yyyy-MM-dd' }}</sv>
              <sv label="转正时间">{{ info?.positiveAt | date: 'yyyy-MM-dd' }}</sv>
              <sv label="试用期时长（月）">{{ info?.probation }}</sv>
              <sv [col]="1" label="试用总结">{{ info?.summary }}</sv>
              <sv [col]="1" label="试用期对公司的意见">{{ info?.opinion }}</sv>
              <sv [col]="1" label="考核附件">
                <div *ngFor="let data of info?.checkAttachment; let i = index">
                  <div class="link-operate" (click)="onDownloadFile(data)">{{ i + 1 }}、{{ data.name }}</div>
                </div>
              </sv>
              <sv [col]="1" label="附件">
                <div *ngFor="let data of info?.attachment; let i = index">
                  <div class="link-operate" (click)="onDownloadFile(data)">{{ i + 1 }}、{{ data.name }}</div>
                </div>
              </sv>
            </sv-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>

<app-down-file [filePath]="filePath" (fileVisibleChange)="fileVisibleChange($event)"></app-down-file>
