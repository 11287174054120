import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbFormService } from '@showye/nbweb-input/nb-form';
import { ShowyePageApiService, ShowyePageAuthService, ShowyePageUtilService } from '@showye/showye-page-base';

@Component({
  selector: 'app-view-flow',
  templateUrl: './view-flow.component.html',
  styleUrls: ['./task.scss'],
})
export class ViewFlowComponent implements OnInit, OnDestroy {
  @Input() ticketId: any;
  @Input() processName: any;
  @Input() processId: any;
  @Input() isVisible = true;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  Q: any = window.Q;
  graph: any;
  createdNodeList: any[] = [];
  title = '流程图';

  constructor(
    private api: ShowyePageApiService,
    private coder: ShowyePageUtilService,
    private auth: ShowyePageAuthService,
    modalSrv: NzModalService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private formService: NbFormService,
    public msg: NzMessageService,
    public notify: NzNotificationService,
  ) {
    modalSrv.closeAll();
  }

  ngOnInit(): void {
    if (this.processName) {
      this.title = this.processName + '流程图';
    }
    this.listEdgesByProcess(this.processId);
  }

  nodesInfo: any[] = [];

  listNodesByProcess(id: any): void {
    if (id) {
      this.api.listNodesByProcess(id).subscribe((e) => {
        this.nodesInfo = e.data;
        // let nodesInfo: any[] = [];
        // this.nodeIdsByEdges.forEach((x: any) => {
        //   e.data.forEach((y: any) => {
        //     if (x.id === y.id) {
        //       nodesInfo.push(y);
        //     }
        //   });
        // });
        setTimeout(() => {
          this.graph = new this.Q.Graph('canavs');
          this.graph.zoomToOverview();
          const nodesInfo = this.nodesInfo.filter((x: any) => x.code !== '$cancel');
          nodesInfo.forEach((c: any, i) => {
            if (c.code !== '$cancel') {
              let bgColor = '#29b9e2';
              let textColor = '#555';
              let xPosition = 200;
              let yPosition = 100 * (i + 1);
              if (c.code === 'launch') {
                bgColor = 'rgba(109,103,103,0.62)';
                textColor = '#f6eded';
              }
              if (c.name === '结束') {
                bgColor = 'rgba(47,219,83,0.6)';
                textColor = '#f5efef';
                xPosition = 350;
                yPosition = 100 * nodesInfo.length + 100;
              }
              if (c.name === '关闭') {
                bgColor = '#e8210d';
                textColor = '#f3ecec';
                xPosition = 50;
                yPosition = 100 * nodesInfo.length + 100;
              }
              this.createNode(c.name, xPosition, yPosition, bgColor, textColor, c);
            }
          });

          if (this.createdNodeList.length > 0 && this.graph) {
            this.drawLine(this.edgesInfo);
          }
        }, 200);
      });
    }
  }

  drawLine(data: any): void {
    data.forEach((c: any) => {
      if (c.fromId && c.toId) {
        this.createdNodeList.forEach((a: any) => {
          if (c.fromId === a.nodeData.id) {
            c.fromNode = a;
          }
          if (c.toId === a.nodeData.id) {
            if (c.special === 'condition' && a.nodeData.$name !== '结束') {
              a.x = a.x + 200;
              a.y = a.y + 100;
            }
            c.toNode = a;
          }
        });
        let bgColor = '#29b9e2';
        let textColor = '#29b9e2';
        if (c.name === '驳回') {
          bgColor = '#e8210d';
          textColor = '#e8210d';
        }
        this.createEdge(c.name, c.fromNode, c.toNode, bgColor, textColor);
      }
    });
  }

  edgesInfo: any[] = [];
  nodeIdsByEdges: any[] = [];

  listEdgesByProcess(id: any): void {
    const conditions: any = [];
    if (id) {
      this.api.listEdgesByProcess(id).subscribe((e) => {
        this.nodeIdsByEdges = [];
        e.data.forEach((x: any) => {
          this.nodeIdsByEdges.push({ id: x.fromId });
          this.nodeIdsByEdges.push({ id: x.toId });
          if (x.conditions) {
            x.conditions.forEach((y: any) => {
              y.fromId = x.fromId;
              y.toId = y.nodeId;
              y.name = x.name + ',' + y.name;
              y.special = 'condition';
              conditions.push(y);
            });
          }
        });
        this.edgesInfo = e.data.concat(conditions);
        this.nodeIdsByEdges = this.goRepeatData(this.nodeIdsByEdges);
        this.listNodesByProcess(this.processId);
      });
    }
  }

  // 数组去从
  goRepeatData(data: any): any[] {
    const newArr: any = [];
    const obj: any = {};
    for (let i = 0; i < data.length; i++) {
      if (!obj[data[i].id]) {
        newArr.push(data[i]);
        obj[data[i].id] = true;
      }
    }
    return newArr;
  }

  createNode(name: string, x: number, y: number, bgColor: any, textColor: any, nodeData: any): any {
    const node = this.graph.createText(name, x, y);
    node.setStyle(this.Q.Styles.LABEL_POSITION, this.Q.Position.CENTER_MIDDLE);
    node.setStyle(this.Q.Styles.LABEL_ANCHOR_POSITION, this.Q.Position.CENTER_MIDDLE);
    node.setStyle(this.Q.Styles.LABEL_BACKGROUND_COLOR, bgColor);
    node.setStyle(this.Q.Styles.LABEL_SIZE, new this.Q.Size(150, 60));
    node.setStyle(this.Q.Styles.LABEL_RADIUS, 10);
    node.setStyle(this.Q.Styles.LABEL_FONT_SIZE, 18);
    node.setStyle(this.Q.Styles.LABEL_COLOR, textColor);
    node.setStyle(this.Q.Styles.BORDER, 1);
    node.setStyle(this.Q.Styles.BORDER_COLOR, 'rgba(85,85,85,0)');
    node.setStyle(this.Q.Styles.BORDER_RADIUS, 50);
    node.nodeData = nodeData;
    this.createdNodeList.push(node);
    return node;
  }

  createEdge(name: string, from: any, to: any, labelColor: any, edgeColor: any): any {
    const edge = this.graph.createEdge(name, from, to);
    edge.setStyle(this.Q.Styles.EDGE_COLOR, edgeColor);
    edge.setStyle(this.Q.Styles.LABEL_COLOR, labelColor);
    edge.setStyle(this.Q.Styles.LABEL_FONT_SIZE, 18);
    edge.setStyle(this.Q.Styles.LABEL_POSITION, this.Q.Position.CENTER_TOP);
    edge.setStyle(this.Q.Styles.LABEL_ANCHOR_POSITION, this.Q.Position.CENTER_BOTTOM);
    edge.setStyle(this.Q.Styles.EDGE_WIDTH, 1);
    // edge.edgeType = this.Q.Consts.EDGE_TYPE_VERTICAL_HORIZONTAL;
    edge.edgeType = this.Q.Consts.EDGE_TYPE_HORIZONTAL_VERTICAL;
    return edge;
  }

  ngOnDestroy(): void {}

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }
}
