import { NgModule } from '@angular/core';
import { NbFormModule } from '@showye/nbweb-input/nb-form';
import { NbSignerModule } from '@showye/nbweb-input/nb-signer';
import { ShowyePageBaseModule } from '@showye/showye-page-base';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzCardModule } from 'ng-zorro-antd/card';
import { PageHeaderModule } from '@delon/abc/page-header';
import { SVModule } from '@delon/abc/sv';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { EditComponent } from './edit.component';
import { ViewComponent } from './view.component';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { SharedModule } from '@shared';
import { ViewFlowComponent } from './view-flow.component';
import { ViewFlowCopyComponent } from './view-flow-copy.component';
import { VisModule } from '@showye/nbweb-vis';
import { VisNetworkService } from '@showye/nbweb-vis';

const COMPONENTS = [EditComponent, ViewComponent, ViewFlowComponent, ViewFlowCopyComponent];

@NgModule({
  imports: [
    SharedModule,
    ShowyePageBaseModule,
    NzCardModule,
    NzButtonModule,
    NzGridModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzModalModule,
    NzMessageModule,
    NzNotificationModule,
    NzTabsModule,
    NzPageHeaderModule,
    PageHeaderModule,
    NbFormModule,
    NbSignerModule,
    SVModule,
    NzDividerModule,
    NzDescriptionsModule,
    NzTagModule,
    NzSelectModule,
    NzTimelineModule,
    VisModule,
    NbFormModule,
  ],
  declarations: [...COMPONENTS],
  exports: [EditComponent, ViewComponent],
  providers: [{ provide: VisNetworkService }],
})
export class TaskModalModule {}
