import { Component, OnInit, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NbXlsxService } from '@service/nb-xlsx.service';
import { NbApiService } from '@service/nb-api.service';
import { DatePipe } from '@angular/common';
import { API } from '@service/api';
import { ShowyePageAuthService } from '@showye/showye-page-base';

@Component({
  selector: 'app-down-file',
  templateUrl: './down-file.component.html',
})
export class DownFileComponent implements OnInit {
  @Input() filePath: any;
  @Output() fileVisibleChange = new EventEmitter<boolean>();
  downloadUrl = this.api.getDownloadUrl();
  showImgUrl: any;
  showImg = false;

  constructor(
    private xlsx: NbXlsxService,
    private api: NbApiService,
    private datePipe: DatePipe,
    private Api: API,
    private auth: ShowyePageAuthService,
  ) {}

  ngOnInit(): void {
    if (this.filePath) {
      this.onDownloadFile(this.filePath);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.filePath) {
      this.onDownloadFile(this.filePath);
    }
  }

  onDownloadFile(path: any): void {
    if (path.indexOf('.jpeg') !== -1 || path.indexOf('.jpg') !== -1 || path.indexOf('.png') !== -1 || path.indexOf('.svg') !== -1) {
      this.showImgUrl = this.downloadUrl + path;
      this.showImg = true;
    } else if (path.indexOf('.pdf') !== -1) {
      const fileUrl = encodeURIComponent(this.downloadUrl + path);
      const pdfUrl = `../../../../assets/pdf/web/viewer.html?file=${fileUrl}`;
      const windowObjectReference = window.open();
      // @ts-ignore
      windowObjectReference.location.href = pdfUrl;
    } else {
      window.open(this.downloadUrl + path);
    }
  }

  isVisibleImgChange(ev: boolean): void {
    this.showImg = ev;
    this.fileVisibleChange.emit(ev);
  }
}
