<nz-modal
  *ngIf="isVisible"
  [(nzVisible)]="isVisible"
  [nzStyle]="{ top: '50px' }"
  [nzTitle]="task?.processTitle"
  (nzOnCancel)="close()"
  [nzMaskClosable]="false"
  [nzWidth]="1500"
>
  <ng-container *nzModalContent>
    <div class="full-panel">
      <nz-card class="container" style="width: 76%; margin-right: 10px">
        <nz-card-tab>
          <nz-tabset *ngIf="forms" [(nzSelectedIndex)]="index" (nzSelectedIndexChange)="changeIndex($event)">
            <nz-tab *ngFor="let t of forms" [nzTitle]="t.displayName"></nz-tab>
          </nz-tabset>
        </nz-card-tab>
        <div [style.display]="isFormVisible" class="apply-info-content">
          <nb-form-viewer
            [formGroup]="currentGroup"
            [form]="currentForm"
            [data]="currentData"
            [readonly]="true"
            [downloadUrl]="downloadUrl"
            [importUrl]="importUrl"
            [uploadUrl]="uploadUrl"
            [token]="token"
            [pdfUrl]="pdfUrl"
          ></nb-form-viewer>
        </div>
      </nz-card>

      <div class="left-panel left-panel-view">
        <h1 class="title">{{ ticket.nodeName }}</h1>
        <nz-card *ngIf="meta" nzBorderless nzSize="small" class="indicator-card" [nzTitle]="meta.displayName">
          <div class="applicant">
            <sv-container [col]="1">
              <ng-container *ngIf="meta && meta.fields">
                <sv *ngFor="let mt of meta.fields" [label]="mt.title">{{ explainMeta(mt) }}</sv>
              </ng-container>
            </sv-container>
          </div>
          <div class="nb-divider"></div>
        </nz-card>

        <nz-card nzBorderless nzSize="small" class="indicator-card" nzTitle="抄送信息">
          <div class="make-copy-frame">
            <div class="copy-each" *ngFor="let op of task?.ccUsers">
              <div class="copy-img-box">
                <div class="bg-img">
                  <img
                    class="copy-img"
                    [src]="userMap[op] && userMap[op].avatar ? downLoad + userMap[op].avatar : '../../../../assets/picture/init-img.png'"
                    alt=" "
                  />
                </div>
              </div>
              <div>{{ userMap[op] ? userMap[op].name : '-' }}</div>
            </div>
          </div>
          <div class="nb-divider"></div>
        </nz-card>

        <nz-card nzBorderless nzSize="small" class="indicator-card" nzTitle="审核信息" [nzExtra]="buttonTemplate">
          <ng-template #buttonTemplate>
            <div class="link-operate" (click)="showFlowModal = true">查看完整流程</div>
          </ng-template>
          <div id="aaa">
            <div *ngFor="let op of history; let i = index" class="audit-each">
              <div class="left-time">{{ op.date }}</div>
              <div class="right-info">
                <div class="title">
                  <img
                    *ngIf="!op.isCurrent && (i == 0 || i == history.length - 1)"
                    class="title-left-img"
                    src="../../../../assets/picture/audit-start.png"
                    alt="icon"
                  />
                  <img
                    *ngIf="op.routeName == '驳回' || op.routeName == '关闭'"
                    class="title-left-img"
                    src="../../../../assets/picture/audit-refuse.png"
                    alt="icon"
                  />
                  <img
                    *ngIf="!op.isCurrent && i != 0 && i != history.length - 1 && op.routeName != '驳回' && op.routeName != '关闭'"
                    class="title-left-img"
                    src="../../../../assets/picture/audit-agree.png"
                    alt="icon"
                  />
                  <img
                    *ngIf="op.isCurrent && op.taskStatus != 2 && op.taskStatus != 3"
                    class="title-left-img"
                    src="../../../../assets/picture/await.png"
                    alt="icon"
                  />
                  <img
                    *ngIf="op.isCurrent && (op.taskStatus == 2 || op.taskStatus == 3) && i == history.length - 1"
                    class="title-left-img"
                    src="../../../../assets/picture/audit-start.png"
                    alt="icon"
                  />
                  {{ op.nodeName }}
                </div>
                <div *ngIf="!op.isCurrent" class="detail-info" [style]="i == history.length - 1 ? { 'border-left': 'none' } : ''">
                  <div *ngIf="op.operatorName && op.routeName" class="des">
                    {{ op.operatorName
                    }}<span [ngClass]="op.routeName == '驳回' || op.routeName == '关闭' ? 'audit-detail disagree' : 'audit-detail agree'">{{
                      op.routeName
                    }}</span>
                    任务
                  </div>
                  <div *ngIf="op.comment" class="comment">{{ op.comment }}</div>
                  <div *ngIf="op.singImg" class="sign">查看签字 <img class="sign-img" [src]="op.singImg" alt="icon" /></div>
                </div>
                <div
                  *ngIf="op.isCurrent && op.taskStatus != 2 && op.taskStatus != 3"
                  class="detail-info"
                  [style]="i == history.length - 1 ? { 'border-left': 'none' } : ''"
                >
                  <div class="deal">等待处理</div>
                </div>
              </div>
            </div>
          </div>
          <!--  <div class="nb-divider"></div>-->
        </nz-card>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>

<!--查看完整流程图-->
<!--<app-view-flow-->
<!--  *ngIf="showFlowModal"-->
<!--  [processId]="processId"-->
<!--  [isVisible]="showFlowModal"-->
<!--  [processName]='processName'-->
<!--  (isVisibleChange)="isViewVisibleChange($event)">-->
<!--</app-view-flow>-->

<app-view-flow-copy
  *ngIf="showFlowModal"
  [processId]="processId"
  [isVisible]="showFlowModal"
  [processName]="processName"
  (isVisibleChange)="isViewVisibleChange($event)"
>
</app-view-flow-copy>
