<nz-modal [(nzVisible)]="isVisible" nzTitle="补卡详情" (nzOnCancel)="close()" [nzWidth]="1350">
  <ng-container *nzModalContent>
    <div style="position: relative">
      <div class="modal-header-right-button">
        <button nz-button nzType="dashed" (click)="onDownloadPrint(1)">下载</button>
        <button nz-button nzType="dashed" (click)="onDownloadPrint(2)">打印</button>
      </div>
      <div class="info">
        <div class="part-content">
          <div class="title">基本信息</div>
          <div class="content">
            <sv-container [col]="3">
              <sv label="流水号">{{ info?.applyId }}</sv>
              <sv label="申请人">{{ info?.userName }}</sv>
              <sv label="所属部门">{{ info?.domainName }}</sv>
              <sv label="申请时间">{{ info?.createdAt | date: 'yyyy-MM-dd HH:mm:ss' }}</sv>
              <sv label="状态">
                <nz-tag *ngIf="info?.signType" [nzColor]="statusEnums[info?.signType]?.color">{{
                  statusEnums[info?.signType]?.label
                }}</nz-tag>
              </sv>
            </sv-container>
          </div>
        </div>

        <div class="part-content">
          <div class="title">补卡信息</div>
          <div class="content">
            <sv-container [col]="3">
              <sv label="补卡时间">{{ info?.signAt | date: 'yyyy-MM-dd HH:mm:ss' }}</sv>
              <!--              <sv label="是否上班卡">{{ info?.onOff ? '否' : '是' }}</sv>-->
              <sv [col]="1" label="补卡原因">{{ info?.reason }}</sv>
              <sv [col]="1" label="图片">
                <div *ngIf="info?.fileList?.length > 0" style="display: flex; flex-wrap: wrap">
                  <img style="width: 350px; margin-bottom: 10px" *ngFor="let op of info?.fileList" [src]="downloadUrl + op.path" />
                </div>
              </sv>
            </sv-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>
