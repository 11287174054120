import { Injectable } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { Observable } from 'rxjs';
import { API } from './api';
import { ShowyePageApiPath, ShowyePageApiService, ShowyePageUtilService } from '@showye/showye-page-base';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/**
 * 接口服务，专用从服务器请求数据。缓存管理也在这里进行。
 */
export class NbApiService extends ShowyePageApiService {
  /**
   * 公司机构类
   */
  // 获取机构列表
  public viewCompany(): Observable<any> {
    return this.http.get(this.api.COMPANY_VIEW);
  }

  // 获取机构树
  public viewCompanyTree(): Observable<any> {
    return this.listDomains().pipe(
      map((e: any) => {
        const smap: any = {};
        e.data
          .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
          .forEach((x: any) => {
            smap[x.id] = x;
            x.key = x.id;
            x.title = x.name + (x.code && x.code.length > 0 ? '[' + x.code + ']' : '');
            x.children = [];
            x.expanded = true;
          });
        e.data.forEach((x: any) => {
          if (!!smap[x.parentId]) {
            smap[x.parentId].children.push(x);
          }
        });
        e.data.forEach((x: any) => {
          if (!x.children || x.children.length <= 0) {
            x.isLeaf = true;
          }
        });
        e.data = {
          domains: Object.keys(smap)
            .filter((k) => !smap[k].parentId)
            .map((k) => smap[k]),
          map: smap,
        };
        return e;
      }),
    );
  }

  public listChildrenByDomain(id: any): Observable<any> {
    return this.http.get(this.api.LIST_CHILDREN_BY_DOMAIN(id));
  }

  public listPositionsByDomain(id: any): Observable<any> {
    return this.http.get(this.api.LIST_POSITION_BY_DOMAIN(id));
  }

  public listStaffsByDomain(id: any): Observable<any> {
    return this.http.get(this.api.LIST_STAFF_BY_DOMAIN(id));
  }

  /**
   * 公司员工类
   */
  public listStaffByResignStatus(resigned: boolean): Observable<any> {
    return this.http.post(this.api.LIST_STAFF_BY_RESIGN_STATUS, { resigned });
  }

  public getListStaffByResignStatus(params: any): Observable<any> {
    return this.http.get(this.api.LIST_STAFF, params);
  }

  // 设置打卡人员
  public setCheckFlag(ids: any): Observable<any> {
    return this.http.post(this.api.SET_CHECK_FLAG, { staffIds: ids });
  }

  public listStaff(): Observable<any> {
    return this.http.post(this.api.LIST_STAFF_BY_RESIGN_STATUS, {});
  }

  public getStaffInfo(id: any): Observable<any> {
    return this.http.get(this.api.STAFF_INFO(id));
  }

  public staffResign(param: any): Observable<any> {
    return this.http.post(this.api.STAFF_RESIGN, param);
  }

  public staffEnroll(param: any): Observable<any> {
    return this.http.post(this.api.STAFF_ENROLL, param);
  }

  public batchStaffEnroll(param: any): Observable<any> {
    return this.http.post(this.api.STAFF_BATCH_ENROLL, param);
  }

  public resetStaffPassword(id: any): Observable<any> {
    return this.http.post(this.api.STAFF_RESET_PASSWORD, { id });
  }

  public listStaffEnrollHistory(id: any): Observable<any> {
    return this.http.post(this.api.LIST_STAFF_ENROLL_HISTORY, { id });
  }

  public listStaffLoginHistory(id: any): Observable<any> {
    return this.http.post(this.api.LIST_STAFF_LOGIN_HISTORY, { id });
  }

  public editStaffInfo(param: any): Observable<any> {
    return this.http.post(this.api.STAFF_INFO_EDIT, param);
  }

  public unbindWechat(id: any): Observable<any> {
    return this.http.post(this.api.UNBIND_PLATFORM_ACCOUNT_WECHAT, { id });
  }

  public resignedHistoryList(par: any): Observable<any> {
    return this.http.post(this.api.STAFF_RESIGN_HISTORY_LIST, par);
  }

  public positiveHistoryList(par: any): Observable<any> {
    return this.http.post(this.api.STAFF_POSITIVE_HISTORY_LIST, par);
  }

  /**
   * 考勤打卡类
   */
  public getCheckinLocationConfig(): Observable<any> {
    return this.http.get(this.api.CHECKIN_RULE_LOCATION);
  }

  public updateCheckinLocationConfig(param: any): Observable<any> {
    return this.http.post(this.api.CHECKIN_RULE_LOCATION_UPDATE, param);
  }

  public getLatestRawConfigCheckin(): Observable<any> {
    return this.http.get(this.api.CHECKIN_CONFIG_RAW_LATEST);
  }

  public getCheckinRuleByDate(date: any): Observable<any> {
    return this.http.post(this.api.CHECKIN_RULE_BY_DATE, { targetDate: date });
  }

  public getCheckinRuleMapByDaterange(param: any): Observable<any> {
    return this.http.post(this.api.CHECKIN_RULE_BY_DATERANGE, param);
  }

  public updateCheckinRule(param: any): Observable<any> {
    return this.http.post(this.api.CHECKIN_RULE_UPDATE, param);
  }

  public statDepartmentLate(param: any): Observable<any> {
    return this.http.post(this.api.CHECKIN_STAT_DEPARTMENT_LATE, param);
  }

  public statStaffLate(param: any): Observable<any> {
    return this.http.post(this.api.CHECKIN_STAT_STAFF_LATE, param);
  }

  public listStaffCheckinDailySummaryByDate(param: any): Observable<any> {
    return this.http.post(this.api.CHECKIN_DAILY_SUMMARY_BY_STAFF, param);
  }

  public listStaffCheckinsByDateRange(param: any): Observable<any> {
    return this.http.post(this.api.CHECKIN_LIST_BY_STAFF_DATERANGE, param);
  }

  public listWorkingDays(param: any): Observable<any> {
    return this.http.post(this.api.CHECKIN_LIST_WORKING_DAYS, param);
  }

  public updateWorkdayConfig(param: any): Observable<any> {
    return this.http.post(this.api.CHECKIN_WORKING_DAYS_UPDATE, param);
  }

  public deleteWorkdayConfig(targetDate: any): Observable<any> {
    return this.http.post(this.api.CHECKIN_WORKING_DAYS_DELETE, { targetDate });
  }

  public listTopCheckin(param: any): Observable<any> {
    return this.http.post(this.api.TOP_CHECKIN_LIST, param);
  }

  /**
   * 考勤分析统计类
   */
  public listStatHrByStaffDay(param: any): Observable<any> {
    return this.http.post(this.api.HR_STAT_BY_STAFF_DAY, param);
  }

  /**
   * 个人中心
   */
  public getMyCheckinStatByDate(param: any): Observable<any> {
    return this.http.post(this.api.MY_CHECKIN_STAT, param);
  }

  public listMyCheckinRulesByDate(param: any): Observable<any> {
    return this.http.post(this.api.MY_CHECKIN_RULES_SUMMARY, param);
  }

  // public listMyCheckinSummariesByDateRange(param: any): Observable<any> {
  //   return this.http.post(this.api.MY_CHECKIN_SUMMARIES, param);
  // }

  public getMyOvertimeSummaryByDate(param: any): Observable<any> {
    return this.http.post(this.api.MY_OVERTIME_SUMMARY, param);
  }

  public getMyCheckinSummaryToday(): Observable<any> {
    return this.http.get(this.api.MY_CHECKIN_SUMMARY_TODAY);
  }

  public getMyCheckinStatusByDate(param: any): Observable<any> {
    return this.http.post(this.api.MY_CHECKIN_DATE, param);
  }

  public getMyInfo(): Observable<any> {
    return this.http.get(this.api.MY_INFO);
  }

  public updateMyInfo(param: any): Observable<any> {
    return this.http.post(this.api.MY_UPDATE_INFO, param);
  }

  public listMyEnrollHistory(): Observable<any> {
    return this.http.get(this.api.MY_ENROLL_HISTORY);
  }

  public listMyLoginHistory(): Observable<any> {
    return this.http.get(this.api.MY_LOGIN_HISTORY);
  }

  public changeMyPassword(param: any): Observable<any> {
    return this.http.post(this.api.MY_CHANGE_PASSWORD, param);
  }

  public getBindingUrl(): string {
    return this.api.BINDING_URL;
  }

  /**
   * 假期类
   */
  public listHolidayTypes(): Observable<any> {
    return this.http.get(this.api.HOLIDAY_TYPES_LIST);
  }

  public addHolidayType(param: any): Observable<any> {
    return this.http.post(this.api.HOLIDAY_TYPE_ADD, param);
  }

  public editHolidayType(param: any): Observable<any> {
    return this.http.post(this.api.HOLIDAY_TYPE_EDIT, param);
  }

  public deleteHolidayType(id: any): Observable<any> {
    return this.http.post(this.api.HOLIDAY_TYPE_DELETE, { id });
  }

  public listConfirmedHolidayByDate(param: any): Observable<any> {
    return this.http.post(this.api.HOLIDAY_CONFIRMED_BY_DATE, param);
  }

  public filterHolidayList(param: any): Observable<any> {
    return this.http.post(this.api.HOLIDAY_FILTER_LIST, param);
  }

  public statHolidayQuotaByStaff(par: any): Observable<any> {
    return this.http.get(this.api.HOLIDAY_QUOTA_STAFF_STAT, par);
  }

  public listMyHolidaiesByDate(param: any): Observable<any> {
    return this.http.post(this.api.MY_HOLIDAY_LIST, param);
  }

  public getMyHolidaySummaryByDate(param: any): Observable<any> {
    return this.http.post(this.api.MY_HOLIDAY_SUMMARY, param);
  }

  public filterTripList(param: any): Observable<any> {
    return this.http.post(this.api.TRIP_FILTER_LIST, param);
  }

  /**
   * 加班调休
   */
  public listHrConfirmingOvertimes(param: any): Observable<any> {
    return this.http.post(this.api.OVERTIME_LIST_CONFIRMINGS, param);
  }

  public listHrConfirmedOvertimes(param: any): Observable<any> {
    return this.http.post(this.api.OVERTIME_CONFIRMED, param);
  }

  public confirmOvertime(param: any): Observable<any> {
    return this.http.post(this.api.OVERTIME_CONFIRM, param);
  }

  public listHrTotalOvertimes(param: any): Observable<any> {
    return this.http.post(this.api.GET_OVER_TIME_TOTAL, param);
  }

  /**
   * 职级管理
   */
  public listLevelOfAll(): Observable<any> {
    return this.http.get(this.api.ALL_LEVEL_INFO);
  }

  public listUserOfLevel(id: any): Observable<any> {
    return this.http.get(this.api.LEVEL_USER_LIST(id));
  }

  public listNextLevelByLevel(id: any): Observable<any> {
    return this.http.get(this.api.NEXT_LEVEL_LIST(id));
  }

  public updateLevelInfo(isAdd: boolean, param: any): Observable<any> {
    if (isAdd) {
      return this.http.post(this.api.ADD_LEVEL_INFO, param);
    } else {
      return this.http.post(this.api.UPDATE_LEVEL_INFO, param);
    }
  }

  /**
   * 财务管理
   */
  public listReimburseComplete(params: any): Observable<any> {
    return this.http.get(this.api.REIMBURSE_COMPLETE_LIST, params);
  }

  public listReimbursePending(params: any): Observable<any> {
    return this.http.get(this.api.REIMBURSE_PENDING_LIST, params);
  }

  public detailInfoReimburse(id: any): Observable<any> {
    return this.http.get(this.api.REIMBURSE_DETAIL_INFO(id));
  }

  public listPaymentComplete(params: any): Observable<any> {
    return this.http.get(this.api.PAYMENT_COMPLETE_LIST, params);
  }

  public listPaymentPending(params: any): Observable<any> {
    return this.http.get(this.api.PAYMENT_PENDING_LIST, params);
  }

  public detailInfoPayment(id: any): Observable<any> {
    return this.http.get(this.api.PAYMENT_DETAIL_INFO(id));
  }

  public listBuyContract(): Observable<any> {
    return this.http.get(this.api.BUY_CONTRACT_LIST);
  }

  public detailInfoBuyContract(id: any): Observable<any> {
    return this.http.get(this.api.BUY_CONTRACT_DETAIL_INFO(id));
  }

  public listSaleContract(): Observable<any> {
    return this.http.get(this.api.SALE_CONTRACT_LIST);
  }

  public detailInfoSaleContract(id: any): Observable<any> {
    return this.http.get(this.api.SALE_CONTRACT_DETAIL_INFO(id));
  }

  public listPurchaseComplete(): Observable<any> {
    return this.http.get(this.api.PURCHASE_COMPLETE_LIST);
  }

  public listPurchaseMine(): Observable<any> {
    return this.http.get(this.api.PURCHASE_MINE_LIST);
  }

  public listPurchasePending(): Observable<any> {
    return this.http.get(this.api.PURCHASE_PENDING_LIST);
  }

  public detailInfoPurchase(id: any): Observable<any> {
    return this.http.get(this.api.PURCHASE_DETAIL_INFO(id));
  }

  public receivePurchase(id: any): Observable<any> {
    return this.http.get(this.api.PURCHASE_RECEIVE(id));
  }

  public getNowCheck(): Observable<any> {
    return this.http.get(this.api.GET_NOW_CHECK);
  }

  public getHrCheckByDate(param: any): Observable<any> {
    return this.http.post(this.api.GET_HR_CHECK_BY_DATE, param);
  }

  public getCheckConfigHistory(): Observable<any> {
    return this.http.get(this.api.GET_CHECK_CONFIG_HISTORY);
  }

  public getMissAndAbnormalCheck(param: any): Observable<any> {
    return this.http.post(this.api.GET_MISS_AND_ABNORMAL_CHECK, param);
  }

  // public getMyMessages(): Observable<any> {
  //   return this.http.get(this.api.GET_MY_MESSAGES);
  // }

  public readMessages(messageType: any): Observable<any> {
    return this.http.get(this.api.READ_MESSAGES(messageType));
  }

  public setReaded(id: any): Observable<any> {
    return this.http.get(this.api.SET_READED(id));
  }

  // 获取code类型枚举值
  public getCodeType(type: any): Observable<any> {
    return this.http.get(this.api.CODE_TYPE(type));
  }

  // 新增项目
  public addProject(param: any): Observable<any> {
    return this.http.put(this.api.ADD_PROJECT, param);
  }

  // 完结项目
  public finishProject(param: any): Observable<any> {
    return this.http.post(this.api.FINISH_PROJECT, param);
  }

  // 添加成本
  public addCost(param: any): Observable<any> {
    return this.http.post(this.api.ADD_COST, param);
  }

  // 修改成本
  public editCost(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_COST, param);
  }

  // 删除成本
  public delCost(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_COST(id));
  }

  // 完结任务
  public finishTask(id: any): Observable<any> {
    return this.http.get(this.api.FINISH_TASK(id));
  }

  // 删除任务
  public delTask(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_TASK(id));
  }

  //  获取项目下关闭任务列表
  public getCloseTaskList(param: any): Observable<any> {
    return this.http.get(this.api.GET_CLOSE_TASK_LIST, param);
  }

  //  添加任务成果说明
  public addGainContent(param: any): Observable<any> {
    return this.http.put(this.api.ADD_GAIN_CONTENT, param);
  }

  //  添加任务成果附件
  public addGainFile(param: any): Observable<any> {
    return this.http.post(this.api.ADD_GAIN_FILE, param);
  }

  //  删除任务成果附件 删除任务描述附件
  public delGainFile(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_GAIN_FILE(id));
  }

  //  添加任务描述说明
  public addDemandContent(param: any): Observable<any> {
    return this.http.put(this.api.ADD_DEMAND_CONTENT, param);
  }

  //  添加任务描述附件
  public addDemandFile(param: any): Observable<any> {
    return this.http.post(this.api.ADD_DEMAND_FILE, param);
  }

  // 添加子任务
  public addChildTask(param: any): Observable<any> {
    return this.http.post(this.api.ADD_CHILD_TASK, param);
  }

  // 解除子任务
  public delChildTask(id: any): Observable<any> {
    return this.http.delete(this.api.LIFT_CHILD_TASK(id));
  }

  // 获取项目访问记录
  public getProjectRecord(): Observable<any> {
    return this.http.get(this.api.GET_PROJECT_RECORD);
  }

  // 获取任务访问记录
  public getTaskRecord(): Observable<any> {
    return this.http.get(this.api.GET_TASK_RECORD);
  }

  // 立项申请
  public launchProject(param: any): Observable<any> {
    return this.http.post(this.api.LAUNCH_PROJECT, param);
  }

  // 获取项目动态
  public getProjectLog(param: any): Observable<any> {
    return this.http.get(this.api.GET_PROJECT_LOG, param);
  }

  // 获取任务动态
  public getTaskLog(param: any): Observable<any> {
    return this.http.get(this.api.GET_TASK_LOG, param);
  }

  // 删除项目
  public delProject(id: any): Observable<any> {
    return this.http.put(this.api.DEL_PROJECT(id));
  }

  // 获取所有项目
  public getAllProject(): Observable<any> {
    return this.http.get(this.api.GET_ALL_PROJECT);
  }

  // 获取我负责的项目
  public getMYProject(): Observable<any> {
    return this.http.get(this.api.GET_MY_PROJECT_LIST);
  }

  // 获取我的草稿项目
  public getMYDraftProject(): Observable<any> {
    return this.http.get(this.api.GET_MY_DRAFT_PROJECT_LIST);
  }

  // 获取我参与的项目
  public getParticipatedProject(): Observable<any> {
    return this.http.get(this.api.GET_PARTICIPATED_PROJECT_LIST);
  }

  // 获取项目详情
  public getProjectInfo(id: any): Observable<any> {
    return this.http.get(this.api.GET_PROJECT_INFO(id));
  }

  // 关闭项目
  public closeProject(id: any): Observable<any> {
    return this.http.put(this.api.CLOSE_PROJECT(id));
  }

  // 编辑项目
  public editProject(param: any): Observable<any> {
    return this.http.put(this.api.EDIT_PROJECT, param);
  }

  // 添加任务
  public addTask(param: any): Observable<any> {
    return this.http.post(this.api.ADD_TASK, param);
  }

  // 编辑任务基本信息
  public editTask(param: any): Observable<any> {
    return this.http.put(this.api.EDIT_TASK, param);
  }

  // 更新任务状态
  public updateTaskStatus(param: any): Observable<any> {
    return this.http.post(this.api.UPDATE_TASK_STATUS, param);
  }

  // 新增计划
  public addPlan(param: any): Observable<any> {
    return this.http.post(this.api.ADD_PLAN, param);
  }

  // 添加成员权限
  public addMemberRole(param: any): Observable<any> {
    return this.http.post(this.api.ADD_MEMBER_ROLE, param);
  }

  // 编辑成员权限
  public editMemberRole(param: any): Observable<any> {
    return this.http.put(this.api.EDIT_MEMBER_ROLE, param);
  }

  // 删除成员权限
  public deleteMemberRole(param: any): Observable<any> {
    return this.http.post(this.api.DELETE_MEMBER_ROLE, param);
  }

  // 我的任务列表
  public myTaskList(): Observable<any> {
    return this.http.get(this.api.MY_TASK_LIST);
  }

  // 所有任务列表
  public allTaskList(): Observable<any> {
    return this.http.get(this.api.ALL_TASK_LIST);
  }

  // 获取任务详情
  public getTaskInfo(id: any): Observable<any> {
    return this.http.get(this.api.GET_TASK_INFO(id));
  }

  // 获取计划详情
  public getPlanInfo(id: any): Observable<any> {
    return this.http.get(this.api.GET_PLAN_INFO(id));
  }

  // 获取项目下的一级任务列表
  public getTaskListByProjectId(id: any): Observable<any> {
    return this.http.get(this.api.GET_TASK_LIST_BY_PROJECT(id));
  }

  // 获取项目下的一级计划列表
  public getPlanListByProjectId(): Observable<any> {
    return this.http.get(this.api.GET_PLAN_LIST_BY_PROJECT);
  }

  // 是否有权限下载文件
  public downloadFile(param: any): Observable<any> {
    return this.http.get(this.api.DOWNLOAD_FILE, param);
  }

  public downloadFileRecord(param: any): Observable<any> {
    return this.http.get(this.api.DOWNLOAD_FILE_RECORD, param);
  }

  // 是否有权限修改项目
  public checkProjectUpdateRole(id: any): Observable<any> {
    return this.http.get(this.api.CHECK_PROJECT_UPDATE_ROLE(id));
  }

  // 是否有权限修改任务
  public checkTaskUpdateRole(id: any): Observable<any> {
    return this.http.get(this.api.CHECK_TASK_UPDATE_ROLE(id));
  }

  /**
   * 仓库管理
   */
  public listWarehouseDepots(): Observable<any> {
    return this.http.get(this.api.WAREHOUSE_DEPOT_LIST);
  }

  public listWarehouseDepotsGrids(): Observable<any> {
    return this.http.get(this.api.WAREHOUSE_DEPOT_GRID);
  }

  public getWarehouseDepotInfo(id: any): Observable<any> {
    return this.http.get(this.api.WAREHOUSE_DEPOT_INFO(id));
  }

  public getWarehouseDepotDetails(id: any): Observable<any> {
    return this.http.get(this.api.WAREHOUSE_DEPOT_DETAIL(id));
  }

  public addWarehouseDepot(param: any): Observable<any> {
    return this.http.post(this.api.WAREHOUSE_DEPOT_ADD, param);
  }

  public editWarehouseDepot(param: any): Observable<any> {
    return this.http.post(this.api.WAREHOUSE_DEPOT_EDIT, param);
  }

  public deleteWarehouseDepot(id: any): Observable<any> {
    return this.http.post(this.api.WAREHOUSE_DEPOT_DELETE, { id });
  }

  public addWarehouseDepotZone(param: any): Observable<any> {
    return this.http.post(this.api.WAREHOUSE_DEPOT_ZONE_ADD, param);
  }

  public deleteWarehouseDepotZone(id: any): Observable<any> {
    return this.http.post(this.api.WAREHOUSE_DEPOT_ZONE_DELETE, { id });
  }

  public listWarehouseDepotZonesByDepot(id: any): Observable<any> {
    return this.http.get(this.api.WAREHOUSE_DEPOT_ZONES_BY_DEPOT(id));
  }

  public listWarehouseDepotShelvesByDepot(id: any): Observable<any> {
    return this.http.get(this.api.WAREHOUSE_DEPOT_SHELVES_BY_DEPOT(id));
  }

  public listWarehouseDepotShelves(): Observable<any> {
    return this.http.get(this.api.WAREHOUSE_DEPOT_SHELF_LIST);
  }

  public deleteWarehouseDepotShelf(id: any): Observable<any> {
    return this.http.post(this.api.WAREHOUSE_DEPOT_SHELF_DELETE, { id });
  }

  public addWarehouseDepotShelf(zoneId: any): Observable<any> {
    return this.http.post(this.api.WAREHOUSE_DEPOT_SHELF_ADD, { zoneId });
  }

  public listWarehouseDepotStorageShelves(): Observable<any> {
    return this.http.get(this.api.WAREHOUSE_DEPOT_SHELF_STORAGE_LIST);
  }

  public listWarehouseDepotInboundShelves(): Observable<any> {
    return this.http.get(this.api.WAREHOUSE_DEPOT_SHELF_INBOUND_LIST);
  }

  public listWarehouseDepotOutboundShelves(): Observable<any> {
    return this.http.get(this.api.WAREHOUSE_DEPOT_SHELF_OUTBOUND_LIST);
  }

  /**
   * 物料管理
   */
  public listWmsItemCategoryAsTree(): Observable<any> {
    return this.http.get(this.api.WMS_ITEM_CATEGORY_TREE);
  }

  public listWmsItemCategory(): Observable<any> {
    return this.http.get(this.api.WMS_ITEM_CATEGORY_LIST);
  }

  public listWmsItemsByCategory(id: any): Observable<any> {
    return this.http.get(this.api.WMS_ITEM_BY_CATEGORY(id));
  }

  public getWmsItemCategory(id: any): Observable<any> {
    return this.http.get(this.api.WMS_ITEM_CATEGORY_INFO(id));
  }

  public getWmsItemSpu(id: any): Observable<any> {
    return this.http.get(this.api.WMS_ITEM_SPU_INFO(id));
  }

  public listWmsItemSpus(param?: any): Observable<any> {
    return this.http.get(this.api.WMS_ITEM_SPU_LIST, param);
  }

  public listWmsItemSpuByFilter(param?: any): Observable<any> {
    return this.http.get(this.api.WMS_ITEM_SPU_FILTER, param);
  }

  public addWmsItemCategory(param: any): Observable<any> {
    return this.http.post(this.api.WMS_ITEM_CATEGORY_ADD, param);
  }

  public editWmsItemCategory(param: any): Observable<any> {
    return this.http.post(this.api.WMS_ITEM_CATEGORY_EDIT, param);
  }

  public deleteWmsItemCategory(id: any): Observable<any> {
    return this.http.post(this.api.WMS_ITEM_CATEGORY_DELETE, { id });
  }

  public addWmsItemSpu(param: any): Observable<any> {
    return this.http.post(this.api.WMS_ITEM_SPU_ADD, param);
  }

  public addWmsItemSpuNew(param: any): Observable<any> {
    return this.http.post(this.api.WMS_ITEM_SPU_ADD_NEW, param);
  }

  public editWmsItemSpu(param: any): Observable<any> {
    return this.http.post(this.api.WMS_ITEM_SPU_EDIT, param);
  }

  public editWmsItemSpuAttributes(param: any): Observable<any> {
    return this.http.post(this.api.WMS_ITEM_SPU_ATTRIBUTES_EDIT, param);
  }

  public deleteWmsItemSpu(id: any): Observable<any> {
    return this.http.post(this.api.WMS_ITEM_SPU_DELETE, { id });
  }

  public listWmsItemSkuBySpu(id: any): Observable<any> {
    return this.http.get(this.api.WMS_ITEM_SKU_BY_SPU(id));
  }

  public listWmsItemSkus(): Observable<any> {
    return this.http.get(this.api.WMS_ITEM_SKUS);
  }

  public addWmsItemSku(param: any): Observable<any> {
    return this.http.post(this.api.WMS_ITEM_SKU_ADD, param);
  }

  public editWmsItemSku(param: any): Observable<any> {
    return this.http.post(this.api.WMS_ITEM_SKU_EDIT, param);
  }

  public deleteWmsItemSku(id: any): Observable<any> {
    return this.http.post(this.api.WMS_ITEM_SKU_DELETE, { id });
  }

  public listWmsItemBatchesBySku(id: any): Observable<any> {
    return this.http.get(this.api.WMS_ITEM_BATCH_BY_SKU(id));
  }

  public listWmsItemBatchesByShelf(id: any): Observable<any> {
    return this.http.get(this.api.WMS_ITEM_BATCH_BY_SHELF(id));
  }

  /**
   * 库存管理
   */
  public getWmsInventoryInfo(id: any): Observable<any> {
    return this.http.get(this.api.WMS_INVENTORY_INFO(id));
  }

  public listWmsInventoryBySpu(id: any): Observable<any> {
    return this.http.get(this.api.WMS_INVENTORY_BY_SPU(id));
  }

  public listWmsInventoryByDepot(id: any): Observable<any> {
    return this.http.get(this.api.WMS_INVENTORY_BY_DEPOT(id));
  }

  public listWmsInventory(): Observable<any> {
    return this.http.get(this.api.WMS_INVENTORY_LIST);
  }

  public listWmsInventoryInStock(): Observable<any> {
    return this.http.get(this.api.WMS_INVENTORY_INSTOCK);
  }

  public listWmsInventoryOldStock(): Observable<any> {
    return this.http.get(this.api.WMS_INVENTORY_OLD_STOCK);
  }

  public listWmsInventoryInbounds(): Observable<any> {
    return this.http.get(this.api.WMS_INVENTORY_INBOUNDS);
  }

  public listWmsInventoryOutbounds(): Observable<any> {
    return this.http.get(this.api.WMS_INVENTORY_OUTBOUNDS);
  }

  public listWmsInventoryTemporary(): Observable<any> {
    return this.http.get(this.api.WMS_INVENTORY_TEMPORARY);
  }

  public wmsSwapBatchBetweenShelf(param: any): Observable<any> {
    return this.http.post(this.api.WMS_INVENTORY_SWAP, param);
  }

  public wmsReportInventoryLoss(param: any): Observable<any> {
    return this.http.post(this.api.WMS_INVENTORY_LOSS, param);
  }

  public wmsCheckinBatchIntoShelf(param: any): Observable<any> {
    return this.http.post(this.api.WMS_INVENTORY_CHECKIN, param);
  }

  public wmsCheckoutBatchFromShelf(param: any): Observable<any> {
    return this.http.post(this.api.WMS_INVENTORY_CHECKOUT, param);
  }

  public listWmsInventoryHistoryByReceivedItem(id: any): Observable<any> {
    return this.http.get(this.api.WMS_INVENTORY_LOGS_BY_ITEM(id));
  }

  /**
   * 签收管理
   */
  public listWmsReceiveConfigSigners(): Observable<any> {
    return this.http.get(this.api.WMS_RECEIVE_CONFIG_SIGNERS);
  }

  public addWmsReceiveConfigSigner(param: any): Observable<any> {
    return this.http.post(this.api.WMS_RECEIVE_CONFIG_SIGNER_ADD, param);
  }

  public editWmsReceiveConfigSigner(param: any): Observable<any> {
    return this.http.post(this.api.WMS_RECEIVE_CONFIG_SIGNER_EDIT, param);
  }

  public deleteWmsReceiveConfigSigner(id: any): Observable<any> {
    return this.http.post(this.api.WMS_RECEIVE_CONFIG_SIGNER_DELETE, { id });
  }

  public listWmsReceiveOrders(): Observable<any> {
    return this.http.get(this.api.WMS_RECEIVE_ORDER_LIST);
  }

  public listWmsReceiveOrdersPage(param: any): Observable<any> {
    return this.http.get(this.api.WMS_RECEIVE_ORDER_LISTPAGE, param);
  }

  public listWmsReceiveOrdersOfMy(param: any): Observable<any> {
    return this.http.get(this.api.WMS_RECEIVE_ORDER_LIST_OFMY, param);
  }

  public getWmsReceiveOrder(id: any): Observable<any> {
    return this.http.get(this.api.WMS_RECEIVE_ORDER_INFO(id));
  }

  public getWmsReceiveOrderDetail(id: any): Observable<any> {
    return this.http.get(this.api.WMS_RECEIVE_ORDER_DETAIL(id));
  }

  public listWmsReceiveOrderItemsByOrder(id: any): Observable<any> {
    return this.http.get(this.api.WMS_RECEIVE_ORDER_ITEMS_LIST_BY_ORDER(id));
  }

  public receiveWmsReceiveOrderItems(param: any): Observable<any> {
    return this.http.post(this.api.WMS_RECEIVE_ORDER_ITEMS_RECEIVE, param);
  }

  public getWmsReceiveOrderItem(id: any): Observable<any> {
    return this.http.get(this.api.WMS_RECEIVE_ORDER_ITEM_INFO(id));
  }

  public addWmsReceiveOrder(param: any): Observable<any> {
    return this.http.post(this.api.WMS_RECEIVE_ORDER_ADD, param);
  }

  public rejectWmsReceiveOrder(param: any): Observable<any> {
    return this.http.post(this.api.WMS_RECEIVE_ORDER_REJECT, param);
  }

  public approveWmsReceiveOrder(param: any): Observable<any> {
    return this.http.post(this.api.WMS_RECEIVE_ORDER_APPROVE, param);
  }

  /**
   * 领料管理
   */
  public listWmsPickupOrders(): Observable<any> {
    return this.http.get(this.api.WMS_PICKUP_ORDER_LIST);
  }

  public listWmsPickupOrdersPage(param: any): Observable<any> {
    return this.http.get(this.api.WMS_PICKUP_ORDER_LISTPAGE, param);
  }

  public getWmsPickupOrder(id: any): Observable<any> {
    return this.http.get(this.api.WMS_PICKUP_ORDER_INFO(id));
  }

  public recheckinWmsPickupOrder(id: any): Observable<any> {
    return this.http.post(this.api.WMS_PICKUP_ORDER_RECHECKIN, { id });
  }

  public cancelWmsPickupOrder(param: any): Observable<any> {
    return this.http.post(this.api.WMS_PICKUP_ORDER_CANCEL, param);
  }

  public pickWmsPickupOrder(param: any): Observable<any> {
    return this.http.post(this.api.WMS_PICKUP_ORDER_PICKUP, param);
  }

  public getWmsPickupOrderDetail(id: any): Observable<any> {
    return this.http.get(this.api.WMS_PICKUP_ORDER_DETAIL(id));
  }

  public prepareWmsPickupOrderItem(param: any): Observable<any> {
    return this.http.post(this.api.WMS_PICKUP_ORDER_ITEM_PREPARE, param);
  }

  public listWmsPickupOrderItemsByOrder(id: any): Observable<any> {
    return this.http.get(this.api.WMS_PICKUP_ORDER_ITEMS_LIST_BY_ORDER(id));
  }

  public getWmsPickupOrderItem(id: any): Observable<any> {
    return this.http.get(this.api.WMS_PICKUP_ORDER_ITEM_INFO(id));
  }

  public addWmsPickupOrder(param: any): Observable<any> {
    return this.http.post(this.api.WMS_PICKUP_ORDER_ADD, param);
  }

  public rejectWmsPickupOrder(param: any): Observable<any> {
    return this.http.post(this.api.WMS_PICKUP_ORDER_REJECT, param);
  }

  public pickupWmsPickupOrder(param: any): Observable<any> {
    return this.http.post(this.api.WMS_PICKUP_ORDER_PICKUP, param);
  }

  public wmsPickupFromOutbounds(param: any): Observable<any> {
    return this.http.post(this.api.WMS_INVENTORY_PICKUP, param);
  }

  public listWmsPickupFulfillmentsByOrder(id: any): Observable<any> {
    return this.http.get(this.api.WMS_PICKUP_FULFILLMENTS_BY_ORDER(id));
  }

  public listWmsPickupFulfillmentsFilled(): Observable<any> {
    return this.http.get(this.api.WMS_PICKUP_FULFILLMENTS_FILLED);
  }

  public listInventoryByIds(ids: any): Observable<any> {
    return this.http.get(this.api.LIST_INVENTORY_BY_IDS(ids));
  }

  public stockAmountByIdsStr(ids: any): Observable<any> {
    return this.http.get(this.api.STOCK_AMOUNT_BY_IDS_STR(ids));
  }

  /**
   * 存储条件管理
   */
  public listWmsStorageConditions(): Observable<any> {
    return this.http.get(this.api.WMS_STORAGE_CONDITION_LIST);
  }

  public getWmsStorageConditionInfo(id: any): Observable<any> {
    return this.http.get(this.api.WMS_STORAGE_CONDITION_INFO(id));
  }

  public editWmsStorageCondition(param: any): Observable<any> {
    return this.http.post(this.api.WMS_STORAGE_CONDITION_EDIT, param);
  }

  public addWmsStorageCondition(param: any): Observable<any> {
    return this.http.post(this.api.WMS_STORAGE_CONDITION_ADD, param);
  }

  public deleteWmsStorageCondition(id: any): Observable<any> {
    return this.http.post(this.api.WMS_STORAGE_CONDITION_DELETE, { id });
  }

  public listWmsStorageLabels(): Observable<any> {
    return this.http.get(this.api.WMS_STORAGE_LABEL_LIST);
  }

  public getWmsStorageLabelInfo(id: any): Observable<any> {
    return this.http.get(this.api.WMS_STORAGE_LABEL_INFO(id));
  }

  public editWmsStorageLabel(param: any): Observable<any> {
    return this.http.post(this.api.WMS_STORAGE_LABEL_EDIT, param);
  }

  public addWmsStorageLabel(param: any): Observable<any> {
    return this.http.post(this.api.WMS_STORAGE_LABEL_ADD, param);
  }

  public deleteWmsStorageLabel(id: any): Observable<any> {
    return this.http.post(this.api.WMS_STORAGE_LABEL_DELETE, { id });
  }

  /**
   * 供应链管理
   */
  public listWmsDistributors(): Observable<any> {
    return this.http.get(this.api.WMS_DISTRIBUTOR_LIST);
  }

  public getWmsDistributorInfo(id: any): Observable<any> {
    return this.http.get(this.api.WMS_DISTRIBUTOR_INFO(id));
  }

  public editWmsDistributor(param: any): Observable<any> {
    return this.http.post(this.api.WMS_DISTRIBUTOR_EDIT, param);
  }

  public addWmsDistributor(param: any): Observable<any> {
    return this.http.post(this.api.WMS_DISTRIBUTOR_ADD, param);
  }

  public deleteWmsDistributor(id: any): Observable<any> {
    return this.http.post(this.api.WMS_DISTRIBUTOR_DELETE, { id });
  }

  /**
   * 品牌管理
   */
  public listWmsBrand(): Observable<any> {
    return this.http.get(this.api.WMS_BRAND_LIST);
  }

  public getWmsBrandInfo(id: any): Observable<any> {
    return this.http.get(this.api.WMS_BRAND_INFO(id));
  }

  public editWmsBrand(param: any): Observable<any> {
    return this.http.post(this.api.WMS_BRAND_EDIT, param);
  }

  public addWmsBrand(param: any): Observable<any> {
    return this.http.post(this.api.WMS_BRAND_ADD, param);
  }

  public deleteWmsBrand(id: any): Observable<any> {
    return this.http.post(this.api.WMS_BRAND_DELETE, { id });
  }

  /**
   * 权限管理
   */
  public getMyRoles(): Observable<any> {
    return this.http.get(this.api.ADMIN_USER_MY_ROLES);
  }

  /**
   * 采购管理
   */
  public getClosePurchaseContract(): Observable<any> {
    return this.http.get(this.api.GET_CLOSE_PURCHASE_CONTRACT);
  }

  public getClosePurchaseTask(): Observable<any> {
    return this.http.get(this.api.GET_CLOSE_PURCHASE_TASK);
  }

  public getPassedPurchaseContract(): Observable<any> {
    return this.http.get(this.api.GET_PASSED_PURCHASE_CONTRACT);
  }

  public getPassedPurchaseTask(): Observable<any> {
    return this.http.get(this.api.GET_PASSED_PURCHASE_TASK);
  }

  public getPendingPurchaseContract(): Observable<any> {
    return this.http.get(this.api.GET_PENDING_PURCHASE_CONTRACT);
  }

  public getPendingPurchaseTask(): Observable<any> {
    return this.http.get(this.api.GET_PENDING_PURCHASE_TASK);
  }

  public getClosePurchaseContractByParam(param: any): Observable<any> {
    return this.http.get(this.api.GET_CLOSE_PURCHASE_CONTRACT, param);
  }

  public getClosePurchaseTaskByParam(param: any): Observable<any> {
    return this.http.get(this.api.GET_CLOSE_PURCHASE_TASK, param);
  }

  public getPassedPurchaseContractByParam(param: any): Observable<any> {
    return this.http.get(this.api.GET_PASSED_PURCHASE_CONTRACT, param);
  }

  public getPassedPurchaseTaskByParam(param: any): Observable<any> {
    return this.http.get(this.api.GET_PASSED_PURCHASE_TASK, param);
  }

  public getPendingPurchaseContractByParam(param: any): Observable<any> {
    return this.http.get(this.api.GET_PENDING_PURCHASE_CONTRACT, param);
  }

  public getPendingPurchaseTaskByParam(param: any): Observable<any> {
    return this.http.get(this.api.GET_PENDING_PURCHASE_TASK, param);
  }

  public getPurchaseContractInfoById(id: any): Observable<any> {
    return this.http.get(this.api.GET_PURCHASE_CONTRACT_INFO_BY_ID(id));
  }

  // 采购详情
  public getPurchaseTaskInfoById(id: any): Observable<any> {
    return this.http.get(this.api.GET_PURCHASE_TASK_INFO_BY_ID(id));
  }

  // 采购详情领料信息
  public getPurchaseTaskInfoByIdNew(id: any): Observable<any> {
    return this.http.get(this.api.GET_PURCHASE_TASK_INFO_BY_ID_NEW(id));
  }

  // 采购详情领取信息
  public getPurchaseContractDetailsByTaskIdAndContractId(taskId: any, contractId: any): Observable<any> {
    return this.http.get(this.api.GET_CONTRACT_DETAILS_BY_TASK_ID_CONTRACT_ID(taskId, contractId));
  }

  public getPurchaseTaskList(applyUser: any, domainId: any, taskSerial: any): Observable<any> {
    let url = this.api.GET_PENDING_TASK_LIST;
    if (applyUser || domainId || taskSerial) {
      url = url + '?';
      if (applyUser) {
        url = url + '&applyUser=' + applyUser;
      }
      if (domainId) {
        url = url + '&domainId=' + domainId;
      }
      if (taskSerial) {
        url = url + '&taskSerial=' + taskSerial;
      }
    }
    return this.http.get(url);
  }

  public purchaseContractLaunch(param: any): Observable<any> {
    return this.http.post(this.api.PURCHASE_CONTRACT_LAUNCH, param);
  }

  public purchaseTaskLaunch(param: any): Observable<any> {
    return this.http.post(this.api.PURCHASE_TASK_LAUNCH, param);
  }

  public getPurchaseContractList(applyUser: any, domainId: any, taskSerial: any): Observable<any> {
    let url = this.api.GET_PURCHASE_CONTRACT_LIST;
    if (applyUser || domainId || taskSerial) {
      url = url + '?';
      if (applyUser) {
        url = url + '&applyUser=' + applyUser;
      }
      if (domainId) {
        url = url + '&domainId=' + domainId;
      }
      if (taskSerial) {
        url = url + '&taskSerial=' + taskSerial;
      }
    }
    return this.http.get(url);
  }

  public listOfMy(): Observable<any> {
    return this.http.get(this.api.LIST_OF_MY);
  }

  // 根据批号查询存放明细
  public stocksDetailsByBatchNo(batchNo: any): Observable<any> {
    return this.http.get(this.api.STOCKS_DETAILS_BY_BATCH_NO, batchNo);
  }

  // 根据库存导入次数唯一id查询存放明细
  public stocksDetailsByIds(ids: any): Observable<any> {
    return this.http.get(this.api.STOCKS_DETAILS_BY_IDS, ids);
  }

  public getProductNoListBySpuIdAndSkuId(param: any): Observable<any> {
    return this.http.get(this.api.GET_PRODUCT_BY_SKU, param);
  }

  public stockListSkus(): Observable<any> {
    return this.http.get(this.api.STOCK_LIST_SKUS);
  }

  public getStockAmountByProductNo(param: any): Observable<any> {
    return this.http.get(this.api.GET_STOCK_AMOUNT_BY_PRODUCT_NO, param);
  }

  public editDept(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_DEPOT, param);
  }

  public receiveListOfMy(): Observable<any> {
    return this.http.get(this.api.RECEIVE_LIST_OF_MY);
  }

  public receiveListOfMyByStatus(param: any): Observable<any> {
    return this.http.get(this.api.GET_RECEIVE_LIST_OF_MY, param);
  }

  public getSkuListBySpuId(spuId: any): Observable<any> {
    return this.http.get(this.api.GET_SKU_LIST_BY_SPUID(spuId));
  }

  public getConfigSign(): Observable<any> {
    return this.http.get(this.api.GET_CONFIG_SIGN);
  }

  public receivePrint(id: any, type?: any): Observable<any> {
    return this.http.get(this.api.RECEIVE_PRINT(id, type));
  }

  public pickUpPrint(id: any, type?: any): Observable<any> {
    return this.http.get(this.api.PICK_UP_PRINT(id, type));
  }

  public contractPrint(id: any, type?: any): Observable<any> {
    return this.http.get(this.api.CONTRACT_PRINT(id, type));
  }

  public taskPrint(id: any, type?: any): Observable<any> {
    return this.http.get(this.api.TASK_PRINT(id, type));
  }

  public importHistory(param: any): Observable<any> {
    return this.http.post(this.api.IMPORT_HISTORY, param);
  }

  public bySkuIdAndBatchNoAndProductNo(skuId: any, batchNo: any, productNo: any): Observable<any> {
    return this.http.get(this.api.BY_SKU_AND_BATCH_PRODUCT(skuId, batchNo, productNo));
  }

  public materialInfoTem(): Observable<any> {
    return this.http.get(this.api.MATERIAL_INFO_TEM);
  }

  // 项目管线
  public getChannelList(): Observable<any> {
    return this.http.get(this.api.GET_CHANNEL_LIST);
  }

  public addChannel(param: any): Observable<any> {
    return this.http.post(this.api.ADD_CHANNEL, param);
  }

  public editChannel(param: any): Observable<any> {
    return this.http.put(this.api.EDIT_CHANNEL, param);
  }

  public detailChannel(id: any): Observable<any> {
    return this.http.get(this.api.DETAIL_CHANNEL(id));
  }

  public deleteChannel(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_CHANNEL(id));
  }

  public getAllCancelled(): Observable<any> {
    return this.http.get(this.api.ALL_CANCELLED);
  }

  public getFinanceCancelled(id: any): Observable<any> {
    return this.http.get(this.api.FINANCE_CANCELLED(id));
  }

  public stocksByDepotId(id: any): Observable<any> {
    return this.http.get(this.api.STOCKS_BY_DEPOT(id));
  }

  public stocksByDepotZoneId(id: any): Observable<any> {
    return this.http.get(this.api.STOCKS_BY_DEPOT_BY_ZONE_ID(id));
  }

  public stocksByDepotShelfId(id: any): Observable<any> {
    return this.http.get(this.api.STOCKS_BY_DEPOT_BY_SHELF_ID(id));
  }

  public addFinishFile(par: any): Observable<any> {
    return this.http.post(this.api.ADD_FINISH_FILE, par);
  }

  public delFinishFile(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_FINISH_FILE(id));
  }

  public getProjectFinishRecordList(id: any): Observable<any> {
    return this.http.get(this.api.GET_PROJECT_FINISH_RECORD_LIST(id));
  }

  public getProjectFinishRecordInfoByRecordId(id: any): Observable<any> {
    return this.http.get(this.api.GET_PROJECT_FINISH_RECORD_INFO(id));
  }

  public editFinishedDirections(par: any): Observable<any> {
    return this.http.put(this.api.EDIT_FINISHED_DIRECTIONS, par);
  }

  public getCancelledPurchaseTask(): Observable<any> {
    return this.http.get(this.api.GET_CANCELLED_PURCHASE_TASK);
  }

  public getCancelledPurchaseContract(): Observable<any> {
    return this.http.get(this.api.GET_CANCELLED_PURCHASE_CONTRACT);
  }

  public getCancelledPurchaseTaskByParam(params: any): Observable<any> {
    return this.http.get(this.api.GET_CANCELLED_PURCHASE_TASK, params);
  }

  public getCancelledPurchaseContractByParam(params: any): Observable<any> {
    return this.http.get(this.api.GET_CANCELLED_PURCHASE_CONTRACT, params);
  }

  public cancelledPayList(params: any): Observable<any> {
    return this.http.get(this.api.CANCELLED_PAY_LIST, params);
  }

  public cancelledReimburseList(params: any): Observable<any> {
    return this.http.get(this.api.CANCELLED_REIMBURSE_LIST, params);
  }

  public launchTask(id: any): Observable<any> {
    return this.http.get(this.api.LAUNCH_TASK(id));
  }

  public updateTaskInfo(par: any): Observable<any> {
    return this.http.put(this.api.UPDATE_INFO_TASK, par);
  }

  public getMonthCheckByUserIdAndMonth(par: any): Observable<any> {
    return this.http.get(this.api.GET_MONTH_CHECK_BY_USER_ID_AND_MONTH, par);
  }

  public getCheckDetailByUserIdAndDay(par: any): Observable<any> {
    return this.http.get(this.api.GET_CHECK_DETAIL_BY_USER_ID_AND_DAY, par);
  }

  public getTripListByUserIdAndMonth(par: any): Observable<any> {
    return this.http.get(this.api.GET_TRIP_LIST_BY_USER_ID_AND_MONTH, par);
  }

  public getOvertimeListByUserIdAndMonth(par: any): Observable<any> {
    return this.http.get(this.api.GET_OVERTIME_LIST_BY_USER_ID_AND_MONTH, par);
  }

  public getExceptionCheckByUserIdAndMonth(par: any): Observable<any> {
    return this.http.get(this.api.GET_EXCEPTION_CHECK_BY_USER_ID_AND_MONTH, par);
  }

  public getHolidayListByUserIdAndMonth(par: any): Observable<any> {
    return this.http.get(this.api.GET_HOLIDAY_LIST_BY_USER_ID_AND_MONTH, par);
  }

  public payListOfMy(id: any, params: any): Observable<any> {
    return this.http.get(this.api.PAY_LIST_OF_MY(id), params);
  }

  public reimburseListOfMy(id: any, params: any): Observable<any> {
    return this.http.get(this.api.REIMBURSE_LIST_OF_MY(id), params);
  }

  public reimburseStatisticsByMonthAndDomain(par: any): Observable<any> {
    return this.http.get(this.api.REIMBURSE_STATISTICS_BY_MONTH_AND_DOMAIN, par);
  }

  public paymentStatisticsByMonthAndDomain(par: any): Observable<any> {
    return this.http.get(this.api.PAYMENT_STATISTICS_BY_MONTH_AND_DOMAIN, par);
  }

  public reimburseDetailByMonth(par: any): Observable<any> {
    return this.http.get(this.api.REIMBURSE_DETAIL_BY_MONTH, par);
  }

  public reimburseQueryWithEnclosure(par: any): Observable<any> {
    return this.http.post(this.api.REIMBURSE_QUERY_WITH_ENCLOSURE, par);
  }

  public payQueryWithEnclosure(par: any): Observable<any> {
    return this.http.post(this.api.PAY_QUERY_WITH_ENCLOSURE, par);
  }

  public overtimeInfo(par: any): Observable<any> {
    return this.http.get(this.api.OVERTIME_INFO, par);
  }

  public updateGain(par: any): Observable<any> {
    return this.http.post(this.api.UPDATE_GAIN, par);
  }

  public finishRecord(taskId: any): Observable<any> {
    return this.http.get(this.api.FINISH_RECORD(taskId));
  }

  public update(par: any): Observable<any> {
    return this.http.post(this.api.UPDATE, par);
  }

  public overtimeConfirmList(id: any): Observable<any> {
    return this.http.get(this.api.OVERTIME_CONFIRM_LIST(id));
  }

  public overtimeBatch(par: any): Observable<any> {
    return this.http.post(this.api.OVERTIME_BATCH, par);
  }

  public getMyTravelList(params: any): Observable<any> {
    return this.http.get(this.api.GET_MY_TRAVEL_LIST, params);
  }

  public getPendingTravelList(params: any): Observable<any> {
    return this.http.get(this.api.GET_PENDING_TRAVEL_LIST, params);
  }

  public getCompletedTravelList(params: any): Observable<any> {
    return this.http.get(this.api.GET_COMPLETED_TRAVEL_LIST, params);
  }

  public getCancelledTravelList(params: any): Observable<any> {
    return this.http.get(this.api.GET_CANCELLED_TRAVEL_LIST, params);
  }

  public getTravelInfo(id: any): Observable<any> {
    return this.http.get(this.api.GET_TRAVEL_INFO(id));
  }

  public repairOvertimeActual(): Observable<any> {
    return this.http.post(this.api.REPAIR_OVERTIME_ACTUAL);
  }

  public recordList(projectId: any): Observable<any> {
    return this.http.get(this.api.RECORD_LIST(projectId));
  }

  public changeInfo(id: any): Observable<any> {
    return this.http.get(this.api.CHANGE_INFO(id));
  }

  public recordTaskList(taskId: any): Observable<any> {
    return this.http.get(this.api.RECORD_TASK_LIST(taskId));
  }

  public changeTaskInfo(id: any): Observable<any> {
    return this.http.get(this.api.CHANGE_TASK_INFO(id));
  }

  public messageListByType(par: any): Observable<any> {
    return this.http.get(this.api.MESSAGE_LIST_BY_TYPE, par);
  }

  public reminderList(): Observable<any> {
    return this.http.get(this.api.REMINDER_LIST);
  }

  public reminderEdit(par: any): Observable<any> {
    return this.http.post(this.api.REMINDER_EDIT, par);
  }

  public setall(par: any): Observable<any> {
    return this.http.post(this.api.REMINDER_SETALL, par);
  }

  public getPositiveDetail(id: any): Observable<any> {
    return this.http.get(this.api.GET_POSITIVE_DETAIL(id));
  }

  public getResignedDetail(id: any): Observable<any> {
    return this.http.get(this.api.GET_RESIGNED_DETAIL(id));
  }

  public getHolidayDetail(id: any): Observable<any> {
    return this.http.get(this.api.GET_HOLIDAY_DETAIL(id));
  }

  public addSickHolidayCertificate(par: any): Observable<any> {
    return this.http.post(this.api.ADD_SICK_HOLIDAY_CERTIFICATE, par);
  }

  public getOvertimeList(par: any): Observable<any> {
    return this.http.post(this.api.GET_OVERTIME_LIST, par);
  }

  public getTripDetail(id: any): Observable<any> {
    return this.http.get(this.api.GET_TRIP_DETAIL(id));
  }

  public getRemedyCheck(par: any): Observable<any> {
    return this.http.post(this.api.GET_REMEDY_LIST, par);
  }

  public getRemedyCheckDetail(id: any): Observable<any> {
    return this.http.get(this.api.GET_REMEDY_CHECK_DETAIL(id));
  }

  public getLaborList(userId: any): Observable<any> {
    return this.http.get(this.api.GET_LABOR_LIST(userId));
  }

  public getPositionList(userId: any): Observable<any> {
    return this.http.get(this.api.GET_POSITION_LIST(userId));
  }

  public getSalaryList(userId: any): Observable<any> {
    return this.http.get(this.api.GET_SALARY_LIST(userId));
  }

  public addLabor(par: any): Observable<any> {
    return this.http.post(this.api.ADD_LABOR, par);
  }

  public editLabor(par: any): Observable<any> {
    return this.http.post(this.api.EDIT_LABOR, par);
  }

  public addPosition(par: any): Observable<any> {
    return this.http.post(this.api.ADD_POSITION, par);
  }

  public addSalary(par: any): Observable<any> {
    return this.http.post(this.api.ADD_SALARY, par);
  }

  public getAccomplishAndFinishTime(par: any): Observable<any> {
    return this.http.get(this.api.GET_ACCOMPLISH_AND_FINISH_TIME, par);
  }

  public getAccomplishAndFinishTimeByCurrentMonth(par: any): Observable<any> {
    return this.http.get(this.api.GET_ACCOMPLISH_AND_FINISH_TIME_BY_CURRENT_MONTH, par);
  }

  public getStaffByRoleId(roleId: any): Observable<any> {
    return this.http.get(this.api.BY_ROLE_ID(roleId));
  }

  public getStaffByPositionId(positionId: any): Observable<any> {
    return this.http.get(this.api.BY_POSITION_ID(positionId));
  }

  public dayTypeUpdate(par: any): Observable<any> {
    return this.http.post(this.api.DAY_TYPE_UPDATE, par);
  }

  public dayTypeInfo(date: any): Observable<any> {
    return this.http.get(this.api.DAY_TYPE_INFO(date));
  }

  /**
   * 测试用接口
   */
  public testListUserByDomain(params: any, did: any): Observable<any> {
    return this.http.get(this.api.TEST_LIST_USER_BY_DOMAIN(did), params);
  }

  public testCheckin(param: any): Observable<any> {
    return this.http.post(this.api.CHECKIN_TEST, param);
  }

  public getProjectNum(): Observable<any> {
    return this.http.get(this.api.GET_PROJECT_NUM);
  }

  public getParticipatedProjectNum(): Observable<any> {
    return this.http.get(this.api.GET_PARTICIPATED_PROJECT_NUM);
  }

  public getAllProjectPage(par: any): Observable<any> {
    return this.http.get(this.api.GET_ALL_PROJECT_PAGE, par);
  }

  public getParticipatedProjectListPage(par: any): Observable<any> {
    return this.http.get(this.api.GET_PARTICIPATED_PROJECT_LIST_PAGE, par);
  }

  public getMyProjectListPage(par: any): Observable<any> {
    return this.http.get(this.api.GET_MY_PROJECT_LIST_PAGE, par);
  }

  public yearHolidayDetail(id: any): Observable<any> {
    return this.http.get(this.api.YEAR_HOLIDAY_DETAIL(id));
  }

  public configFrequencies(): Observable<any> {
    return this.http.get(this.api.CONFIG_FREQUENCIES);
  }

  public configRoleTypes(): Observable<any> {
    return this.http.get(this.api.CONFIG_ROLE_TYPES);
  }

  public configAdd(par: any): Observable<any> {
    return this.http.post(this.api.CONFIG_ADD, par);
  }

  public configDelete(id: any): Observable<any> {
    return this.http.delete(this.api.CONFIG_DELETE(id));
  }

  public configEdit(par: any): Observable<any> {
    return this.http.put(this.api.CONFIG_EDIT, par);
  }

  public queryFinancialApprovalList(params: any): Observable<any> {
    return this.http.get(this.api.APPROVAL_FINANCIAL_LIST, params);
  }

  public getHrApprovalList(params: any): Observable<any> {
    return this.http.get(this.api.HR_APPROVE, params);
  }

  public locationList(): Observable<any> {
    return this.http.get(this.api.LOCATION_LIST);
  }

  public addLocation(params: any): Observable<any> {
    return this.http.post(this.api.ADD_LOCATION, params);
  }

  public updateLocation(params: any): Observable<any> {
    return this.http.post(this.api.UPDATE_LOCATION, params);
  }

  public viewLocation(id: any): Observable<any> {
    return this.http.get(this.api.VIEW_LOCATION(id));
  }

  public deleteLocation(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_LOCATION(id));
  }

  public getOutApplyList(par: any): Observable<any> {
    return this.http.post(this.api.GET_OUT_APPLY_LIST, par);
  }

  public getOutDetailById(id: any): Observable<any> {
    return this.http.get(this.api.GET_OUT_DETAIL(id));
  }

  public updateRealHoliday(par: any): Observable<any> {
    return this.http.post(this.api.UPDATE_REAL_HOLIDAY, par);
  }

  public cancelHoliday(id: any): Observable<any> {
    return this.http.post(this.api.CANCEL_HOLIDAY(id));
  }

  public calculateHolidayTimespan(par: any): Observable<any> {
    return this.http.post(this.api.CALCULATE_HOLIDAY_TIMESPAN, par);
  }

  public laborEdit(par: any): Observable<any> {
    return this.http.post(this.api.LABOR_EDIT, par);
  }

  public positionEdit(par: any): Observable<any> {
    return this.http.post(this.api.POSITION_EDIT, par);
  }

  public salaryEdit(par: any): Observable<any> {
    return this.http.post(this.api.SALARY_EDIT, par);
  }

  public deleteSalary(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_SALARY(id));
  }

  public deletePosition(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_POSITION(id));
  }

  public deleteLabor(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_LABOR(id));
  }

  public fsPayLaunch(par: any): Observable<any> {
    return this.http.post(this.api.FS_PAY_LAUNCH, par);
  }

  public getPayeeList(): Observable<any> {
    return this.http.get(this.api.GET_PAYEE_LIST);
  }

  /**
   * 收款方管理
   */
  public listPayee(): Observable<any> {
    return this.http.get(this.api.PAYEE_LIST);
  }

  public editPayee(param: any): Observable<any> {
    return this.http.post(this.api.PAYEE_EDIT, param);
  }

  public addPayee(param: any): Observable<any> {
    return this.http.post(this.api.PAYEE_ADD, param);
  }

  public deletePayee(param: any): Observable<any> {
    return this.http.post(this.api.PAYEE_DELETE, param);
  }

  public getPayeeInfo(id: any): Observable<any> {
    return this.http.get(this.api.PAYEE_INFO(id));
  }

  public salesContractLaunch(param: any): Observable<any> {
    return this.http.post(this.api.SALES_CONTRACT_LAUNCH, param);
  }

  constructor(protected baseApi: ShowyePageApiPath, private api: API, protected http: _HttpClient, protected coder: ShowyePageUtilService) {
    super(baseApi, http, coder);
  }
}
