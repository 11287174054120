import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { NbApiService } from '@service/nb-api.service';
import { Subscription } from 'rxjs';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  templateUrl: './review.component.html',
  styleUrls: ['./review.scss'],
})
export class ReviewComponent implements OnInit, OnDestroy {
  constructor(
    private api: NbApiService,
    modalSrv: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public msg: NzMessageService,
    public notify: NzNotificationService,
  ) {
    modalSrv.closeAll();
  }

  public route$: Subscription | null = null;

  ngOnDestroy(): void {
    if (this.route$) {
      this.route$.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.route$ = this.route.data.subscribe((p: any) => {
      this.groupId = p.groupId;
    });
    this.reloadDomains();
    this.reloadProcesses();
  }

  groupId: any = 0;
  index = 0;
  columns: any = [];
  isGoRefresh = true;

  // reloadColumn(): void {
  //   if (this.index !== 3) {
  //     this.columns = [
  //       { title: '流水号', index: 'taskId' },
  //       {
  //         title: '申请类型',
  //         index: 'processId',
  //         type: 'enum',
  //         enum: this.processEnums,
  //         filter: {
  //           menus: this.processFilters,
  //           fn: (f: any, r: any) => !f.value || r.processId === f.value,
  //           multiple: false,
  //         },
  //       },
  //       { title: '申请人', index: 'taskApplicantName' },
  //       {
  //         title: '流程所属部门',
  //         index: 'domainId',
  //         type: 'enum',
  //         enum: this.domainEnums,
  //         filter: {
  //           menus: this.domainFilters,
  //           fn: (f: any, r: any) => !f.value || r.domainId === f.value,
  //           multiple: false,
  //         },
  //       },
  //       { title: '当前环节', index: 'nodeName' },
  //       { title: '申请时间', index: 'taskCreatedAt', type: 'date' },
  //       { title: '最后审批人', index: 'previousOperatorName' },
  //       { title: '最后审批时间', index: 'previousCommittedAt', type: 'date' },
  //       {
  //         title: '操作',
  //         buttons: [
  //           {
  //             text: '处理',
  //             icon: 'edit',
  //             type: 'none',
  //             iif: () => this.index === 0,
  //             click: (e: any) => {
  //               // this.router.navigate(['/my/task/edit', { id: e.id }]),
  //               this.ticketId = e.id;
  //               this.showModal = true;
  //             },
  //           },
  //           {
  //             text: '查看',
  //             icon: 'eye',
  //             type: 'none',
  //             iif: () => this.index !== 0,
  //             click: (e: any) => {
  //               // this.router.navigate(['/my/task/view', { id: e.id }]),
  //               this.ticketId = e.id;
  //               this.showViewModal = true;
  //             },
  //           },
  //         ],
  //       },
  //     ];
  //   } else {
  //     this.columns = [
  //       { title: '流水号', index: 'taskId' },
  //       {
  //         title: '申请类型',
  //         index: 'processId',
  //         type: 'enum',
  //         enum: this.processEnums,
  //         filter: {
  //           menus: this.processFilters,
  //           fn: (f: any, r: any) => !f.value || r.processId === f.value,
  //           multiple: false,
  //         },
  //       },
  //       { title: '申请人', index: 'taskApplicantName' },
  //       {
  //         title: '流程所属部门',
  //         index: 'domainId',
  //         type: 'enum',
  //         enum: this.domainEnums,
  //         filter: {
  //           menus: this.domainFilters,
  //           fn: (f: any, r: any) => !f.value || r.domainId === f.value,
  //           multiple: false,
  //         },
  //       },
  //       { title: '撤销时间', index: 'updatedAt', type: 'date' },
  //       {
  //         title: '操作',
  //         buttons: [
  //           {
  //             text: '查看',
  //             icon: 'eye',
  //             type: 'none',
  //             click: (e: any) => {
  //               // this.router.navigate(['/office/hr/task/view', { id: e.id }], { relativeTo: this.route }),
  //               this.ticketId = e.id;
  //               this.showViewModal = true;
  //             },
  //           },
  //         ],
  //       },
  //     ];
  //   }
  // }

  to(idx: any): void {
    this.index = idx;
    this.pageSize = 10;
    this.pageIndex = 1;
    this.params = null;
    if (idx === 0 || idx === 1 || idx === 2) {
      this.isGoRefresh = false;
      setTimeout(() => {
        this.isGoRefresh = true;
      }, 100);
    }
  }

  tickets: any = [];
  processFilters: any[] = [];
  processEnums: any = {};
  domainFilters: any[] = [];
  domainEnums: any = {};
  userEnums: any = {};
  codeType: any = [];
  loading = true;
  pageSize = 10;
  pageIndex = 1;
  total = 0;
  data: any[] = [];
  params: any;

  reloadData(pi: any, ps: any, sf: any, so: any, f: any): any {
    const st = f.reduce((a: any, c: any) => ({ ...a, [c.key]: c.value }), {});
    // console.log('st: ', st);
    if (pi && ps) {
      st.pageNum = pi;
      st.pageSize = ps;
    }
    if (sf && so) {
      st.sortField = sf;
      st.sortOrder = so;
    }
    st.status = this.index + 1;
    st.groupId = this.groupId;
    this.api.getHrApprovalList(st).subscribe((e: any) => {
      this.tickets = e.data;
      this.total = e.total;
      this.pageSize = e.pageSize;
      this.pageIndex = e.pageNum;
      this.loading = false;
    });
  }

  onQueryParamsChange(params: NzTableQueryParams, init?: boolean): void {
    if (this.params?.filter?.length > 0) {
      params.filter = this.params?.filter.concat(params.filter);
      params.filter.forEach((x: any) => {
        this.params.filter.forEach((y: any) => {
          if (x.key === y.key) {
            y.value = x.value;
          }
        });
      });
    }
    this.params = params;
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const filters = filter.filter((x) => (Array.isArray(x.value) ? x.value.length > 0 : x.value != null));
    // console.log('filters: ', filters);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.reloadData(!init ? pageIndex : 1, this.pageSize, sortField, sortOrder, filters);
  }

  pageChange(ev: any): void {
    this.pageIndex = ev;
    const { pageSize, pageIndex, sort, filter } = this.params;
    const currentSort = sort.find((item: any) => item.value !== null);
    const filters = filter.filter((x: any) => (Array.isArray(x.value) ? x.value.length > 0 : x.value != null));
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.reloadData(ev, this.pageSize, sortField, sortOrder, filters);
  }

  pageSizeChange(ev: any): void {
    this.pageSize = ev;
    const { pageSize, pageIndex, sort, filter } = this.params;
    const currentSort = sort.find((item: any) => item.value !== null);
    const filters = filter.filter((x: any) => (Array.isArray(x.value) ? x.value.length > 0 : x.value != null));
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.reloadData(pageIndex, ev, sortField, sortOrder, filters);
  }

  reloadRevocalTickets(): void {
    this.tickets = [];
    this.api.getAllCancelled().subscribe((e) => {
      const data: any[] = [];
      e.data.forEach((x: any) => {
        this.codeType.forEach((y: any) => {
          if (x.processCode === y) {
            data.push(x);
          }
        });
      });
      this.tickets = data.map((x: any) => x).sort((a: any, b: any) => b.taskId - a.taskId);
    });
  }

  reloadPendingTickets(): void {
    this.api.listMyPendingTicketsByGroup(this.groupId).subscribe((e) => {
      this.tickets = e.data.map((x: any) => x).sort((a: any, b: any) => b.taskId - a.taskId);
    });
  }

  reloadSuccessTickets(): void {
    this.api.listMySuccessTicketsByGroup(this.groupId).subscribe((e) => {
      this.tickets = e.data.map((x: any) => x).sort((a: any, b: any) => b.taskId - a.taskId);
    });
  }

  reloadFailedTickets(): void {
    this.api.listMyFailedTicketsByGroup(this.groupId).subscribe((e) => {
      this.tickets = e.data.map((x: any) => x).sort((a: any, b: any) => b.taskId - a.taskId);
    });
  }

  // reloadUsers(): void {
  //   this.api.listUsers().subscribe((e) => {
  //     this.userEnums = e.data.reduce((obj: any, item: any) => ({ ...obj, [item.id]: item.name }), {});
  //     this.reloadColumn();
  //   });
  // }
  reloadDomains(): void {
    this.api.listDomains().subscribe((e) => {
      this.domainEnums = e.data.reduce((obj: any, item: any) => ({ ...obj, [item.id]: item.name }), {});
      this.domainFilters = e.data.map((x: any) => ({ text: x.name, value: x.id }));
      // this.reloadColumn();
    });
  }

  reloadProcesses(): void {
    this.codeType = [];
    this.api.listProcessesByGroup(this.groupId).subscribe((e) => {
      this.codeType = e.data.map((x: any) => x.code);
      this.processEnums = e.data.reduce((obj: any, item: any) => ({ ...obj, [item.id]: item.title }), {});
      this.processFilters = e.data.map((x: any) => ({ text: x.title, value: x.id }));
      // this.reloadColumn();
    });
  }

  showModal = false;

  isVisibleChange(ev: any): void {
    this.showModal = ev;
  }

  isRefresh(ev: any): void {
    if (ev) {
      this.to(this.index);
    }
  }

  showViewModal = false;
  ticketId: any;

  isViewVisibleChange(ev: any): void {
    this.showViewModal = ev;
  }

  view(e: any): void {
    this.ticketId = e.ticketId;
    this.showViewModal = true;
  }

  dealWith(e: any): void {
    this.ticketId = e.ticketId;
    this.showModal = true;
  }
}
