import { Component, HostListener, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SettingsService, TitleService } from '@delon/theme';
import { NzModalService } from 'ng-zorro-antd/modal';
import { filter } from 'rxjs/operators';
import {
  ShowyePageApiService,
  ShowyePageAuthService,
  ShowyePageIconService,
  ShowyePageMenuService,
  ShowyePageMessageService,
} from '@showye/showye-page-base';
import { EventManager } from '@angular/platform-browser';
import { FormBuilder } from '@angular/forms';
import { ReuseTabService } from '@delon/abc/reuse-tab';
import { environment } from '@env/environment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { timer } from 'rxjs';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  passwordType = 'text';
  wsSubscribe: any;

  constructor(
    private router: Router,
    private titleSrv: TitleService,
    private modalSrv: NzModalService,
    private messageService: ShowyePageMessageService,
    private menuService: ShowyePageMenuService,
    private eventManager: EventManager,
    private settings: SettingsService,
    private auth: ShowyePageAuthService,
    private api: ShowyePageApiService,
    private icon: ShowyePageIconService,
    fb: FormBuilder,
    private settingsService: SettingsService,
    @Optional()
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    public notify: NzNotificationService,
    private location: PlatformLocation,
    private route: ActivatedRoute,
  ) {
    this.location.onPopState((x: any) => {
      if (!x.state) {
        this.notify.error('不支持该操作！', '');
        this.router.navigate(['/exception/404'], { relativeTo: this.route });
      }
    });
  }

  /**
   * 考虑到兼容丹丹本身的设计理念，将定时锁屏的逻辑代码放到业务侧。
   */
  downloadUrl = this.api.getDownloadUrl();
  userInfo: any = {};

  /**
   * 销毁定时器
   */
  timer: any;
  loadUnreadMessageSubscription: any;

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.loadUnreadMessageSubscription) {
      this.loadUnreadMessageSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.timer = setInterval(() => {
      if (
        !this.locked &&
        this.auth.loginValid() &&
        this.auth.refreshed > 0 &&
        new Date().getTime() - this.auth.refreshed > environment.lockInterval
      ) {
        this.lockScreen();
      }
    }, 10000);
    this.router.events.pipe(filter((evt) => evt instanceof NavigationEnd)).subscribe((e: any) => {
      // 监听全局路由变更事件
      this.auth.refresh();
      this.userInfo = this.auth.user;
      this.menuService.resetMenuByPath(e.urlAfterRedirects ? e.urlAfterRedirects : e.url);
      this.titleSrv.setTitle();
      this.modalSrv.closeAll();
    });
    this.messageService.trigger().subscribe((type) => {
      this.router.navigate(['/my/message', !!type ? { type } : {}]);
    });
    this.loadUnreadMessageSubscription = timer(100, 15000).subscribe((t) => {
      if (this.auth.loginValid()) {
        this.api.allTypeAndUnread().subscribe((e: any) => {
          this.messageService.message = e.data;
        });
      }
    });
  }

  /**
   * 监听全局键盘事件
   */
  @HostListener('window:keydown', ['$event'])
  onKeyDown(e: any): void {
    if (!this.locked) {
      this.auth.refresh();
    }
  }

  /**
   * 监听全局鼠标按下事件
   */
  @HostListener('window:mousedown', ['$event'])
  onMouseDown(e: any): void {
    if (!this.locked) {
      this.auth.refresh();
    }
  }

  /**
   * 监听全局鼠标移动事件
   */
  @HostListener('window:mousemove', ['$event'])
  onMouseMove(e: any): void {
    if (!this.locked) {
      this.auth.refresh();
    }
  }

  /**
   * 锁屏
   */
  locked = false;

  lockScreen(): void {
    this.auth.setToken('');
    this.passwordType = 'text';
    this.locked = true;
  }

  onPasswordChange(ev: any): void {
    this.passwordType = 'password';
  }

  /**
   * 解锁
   */
  password = '';
  loading = false;

  unlockScreen(): void {
    this.loading = true;
    this.api
      .login({
        username: this.userInfo.username,
        password: this.password,
      })
      .subscribe(
        (res: any) => {
          // 清空路由复用信息
          this.password = '';
          this.reuseTabService.clear();
          this.auth.setUser(res.data);
          this.locked = this.loading = false;
        },
        () => (this.loading = false),
      );
  }

  /**
   * 切换账号
   */
  logout(): void {
    this.auth.logout();
  }
}
