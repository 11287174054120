<nz-modal [(nzVisible)]="isVisible" nzTitle="出差详情" (nzOnCancel)="close()" [nzWidth]="1350">
  <ng-container *nzModalContent>
    <div style="position: relative">
      <div class="modal-header-right-button">
        <button nz-button nzType="dashed" (click)="onDownloadPrint(1)">下载</button>
        <button nz-button nzType="dashed" (click)="onDownloadPrint(2)">打印</button>
      </div>
      <div class="info">
        <div class="part-content">
          <div class="title">基本信息</div>
          <div class="content">
            <sv-container [col]="3">
              <sv label="申请流水号">{{ info?.applyId }}</sv>
              <sv label="申请人">{{ info?.userName }}</sv>
              <sv label="所属部门">{{ info?.domainName }}</sv>
              <sv label="申请时间">{{ info?.appliedAt | date: 'yyyy-MM-dd' }}</sv>
              <sv label="状态">
                <nz-tag *ngIf="info?.status" [nzColor]="statusEnums[info?.status]?.color">{{ statusEnums[info?.status]?.label }}</nz-tag>
              </sv>
            </sv-container>
          </div>
        </div>

        <div class="part-content">
          <div class="title">出差信息</div>
          <div class="content">
            <sv-container [col]="2">
              <sv label="出差目的地">{{ info?.destination }}</sv>
              <sv label="出差天数">{{ info?.days }}</sv>
              <sv label="出差开始时间">{{ info?.beginAt | date: 'yyyy-MM-dd HH:mm' }}</sv>
              <sv label="出差结束时间">{{ info?.endAt | date: 'yyyy-MM-dd HH:mm' }}</sv>
              <sv [col]="1" label="出差事由">{{ info?.reason }}</sv>
              <sv [col]="1" label="图片">
                <div *ngIf="info?.pictureList?.length > 0" style="display: flex; flex-wrap: wrap">
                  <img style="width: 350px; margin-bottom: 10px" *ngFor="let op of info?.pictureList" [src]="downloadUrl + op.path" />
                </div>
              </sv>
            </sv-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>
