import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class API {
  public HOST = environment.host + environment.path;

  public WX_MP_APP_ID = environment.wxMpId;
  /**
   * 公司机构管理
   */
  // 公司组织机构列表
  public COMPANY_VIEW = `${this.HOST}/company/organise/view`;
  // 查看直属下级机构列表
  public LIST_CHILDREN_BY_DOMAIN = (id: any) => `${this.HOST}/company/organise/children/${id}`;
  // 查看部门内设岗位
  public LIST_POSITION_BY_DOMAIN = (id: any) => `${this.HOST}/company/organise/positions/${id}`;
  // 查看部门内部员工
  public LIST_STAFF_BY_DOMAIN = (id: any) => `${this.HOST}/company/organise/staffs/${id}`;

  /**
   * 公司员工管理
   */
  // 在职员工列表
  public STAFF_EMPLOYEES = `${this.HOST}/hr/staff/employee`;
  // 离职员工列表
  public STAFF_RESIGNED = `${this.HOST}/hr/staff/resigned`;
  // 筛选员工列表，用参数进行筛选
  public LIST_STAFF_BY_RESIGN_STATUS = `${this.HOST}/hr/staff/bystatus`;
  // 用Get请求筛选员工列表，用参数进行筛选
  public LIST_STAFF = `${this.HOST}/hr/staff/getStaffList`;
  // 用Get请求导出员工列表，用参数进行筛选
  public EXPORT_LIST_STAFF = `${this.HOST}/hr/staff/exportStaffList`;
  // 设置打卡人员
  public SET_CHECK_FLAG = `${this.HOST}/hr/checkin/setCheckFlag`;
  // 查看全部离职记录
  public STAFF_ALL_RESIGNATION_RECORD = `${this.HOST}/hr/staff/resignation`;
  // 按员工查看离职记录
  public STAFF_RESIGNATION_RECORD = (id: any) => `${this.HOST}/hr/staff/resignation/${id}`;
  // 员工入职
  public STAFF_ENROLL = `${this.HOST}/hr/staff/enroll`;
  // 员工批量入职
  public STAFF_BATCH_ENROLL = `${this.HOST}/hr/staff/batch_enroll`;
  // 员工离职
  public STAFF_RESIGN = `${this.HOST}/hr/staff/resign`;
  // 更新员工资料
  public STAFF_INFO_EDIT = `${this.HOST}/hr/staff/edit`;
  public UNBIND_PLATFORM_ACCOUNT_WECHAT = `${this.HOST}/hr/staff/unbind/wechat`;
  // 查看员工资料
  public STAFF_INFO = (id: any) => `${this.HOST}/hr/staff/info/${id}`;
  // 重置员工登录密码
  public STAFF_RESET_PASSWORD = `${this.HOST}/hr/staff/reset_password`;
  // 查看指定员工的历史入职离职记录
  public LIST_STAFF_ENROLL_HISTORY = `${this.HOST}/hr/staff/enroll_history`;
  // 查看指定员工的登录记录
  public LIST_STAFF_LOGIN_HISTORY = `${this.HOST}/admin/user/login_history`;
  // 员工离职
  public STAFF_RESIGN_HISTORY_LIST = `${this.HOST}/hr/staff/resigned_history`;
  // 员工转正
  public STAFF_POSITIVE_HISTORY_LIST = `${this.HOST}/hr/staff/positive_history`;
  /**
   * 考勤设置
   */
  // 获取指定机构的首条打卡规则
  public CHECKIN_RULE_LOCATION = `${this.HOST}/hr/checkin/config/location`;
  public CHECKIN_RULE_LOCATION_UPDATE = `${this.HOST}/hr/checkin/config/location/update`;
  public CHECKIN_CONFIG_RAW_LATEST = `${this.HOST}/hr/checkin/config/latest`;
  public CHECKIN_RULE_BY_DATE = `${this.HOST}/hr/checkin/config/date`;
  public CHECKIN_RULE_BY_DATERANGE = `${this.HOST}/hr/checkin/config/daterange`;
  public CHECKIN_RULE_UPDATE = `${this.HOST}/hr/checkin/config/update`;
  public CHECKIN_STAT_DEPARTMENT_LATE = `${this.HOST}/hr/checkin/stat/late/by_department`;
  public CHECKIN_STAT_STAFF_LATE = `${this.HOST}/hr/checkin/stat/late/by_staff`;
  public CHECKIN_DAILY_SUMMARY_BY_STAFF = `${this.HOST}/hr/checkin/summary/by_staff`;
  public CHECKIN_LIST_BY_STAFF_DATERANGE = `${this.HOST}/hr/checkin/list/by_staff`;
  public CHECKIN_LIST_WORKING_DAYS = `${this.HOST}/hr/checkin/workingdays`;
  public CHECKIN_WORKING_DAYS_UPDATE = `${this.HOST}/hr/checkin/workingday/update`;
  public CHECKIN_WORKING_DAYS_DELETE = `${this.HOST}/hr/checkin/workingday/delete`;
  public TOP_CHECKIN_LIST = `${this.HOST}/hr/checkin/workTop`;

  /**
   * 综合考勤分析统计
   */
  public HR_STAT_BY_STAFF_DAY = `${this.HOST}/hr/stat/staff/day/list`;

  /**
   * 假期
   */
  public HOLIDAY_TYPES_LIST = `${this.HOST}/hr/holiday/types`;
  public HOLIDAY_TYPE_DELETE = `${this.HOST}/hr/holiday/type/delete`;
  public HOLIDAY_TYPE_ADD = `${this.HOST}/hr/holiday/type/add`;
  public HOLIDAY_TYPE_EDIT = `${this.HOST}/hr/holiday/type/edit`;
  public HOLIDAY_CONFIRMED_BY_DATE = `${this.HOST}/hr/holiday/confirmed/by_date`;
  public HOLIDAY_FILTER_LIST = `${this.HOST}/hr/holiday/filter`;
  public HOLIDAY_QUOTA_STAFF_STAT = `${this.HOST}/hr/holiday/stat/quota/staff`;
  public EXPORT_HOLIDAY_RECORD = (month: any, status: any, userId: any, typeId: any) =>
    `${this.HOST}/hr/holiday/exportHolidayList?month=${month}&status=${status}&userId=${userId}&typeId=${typeId}`;
  public EXPORT_QUOTA_HOLIDAY_RECORD = (name: any, domainId: any) =>
    `${this.HOST}/hr/holiday/exportHolidayQuotaList?name=${name}&domainId=${domainId}`;
  /**
   * 出差
   */
  public TRIP_FILTER_LIST = `${this.HOST}/hr/trip/list`;

  /**
   * 加班调休
   */
  public OVERTIME_LIST_CONFIRMINGS = `${this.HOST}/hr/overtime/confirmings`;
  public OVERTIME_CONFIRMED = `${this.HOST}/hr/overtime/confirmed`;
  public OVERTIME_CONFIRM = `${this.HOST}/hr/overtime/confirm`;
  // 加班统计列表
  public GET_OVER_TIME_TOTAL = `${this.HOST}/hr/overtime/getOverTimeTotal`;

  /**
   * 个人中心
   */
  public MY_OVERTIME_SUMMARY = `${this.HOST}/my/overtime/summary`;
  public MY_HOLIDAY_LIST = `${this.HOST}/my/holiday/list`;
  public MY_HOLIDAY_SUMMARY = `${this.HOST}/my/holiday/summary`;
  public MY_CHECKIN_RULES_SUMMARY = `${this.HOST}/my/checkin/rules`;
  public MY_CHECKIN_SUMMARIES = `${this.HOST}/my/checkin/summary/list`;
  public MY_CHECKIN_SUMMARY_TODAY = `${this.HOST}/my/checkin/summary/today`;
  public MY_CHECKIN_STAT = `${this.HOST}/my/checkin/stat`;
  public MY_CHECKIN_DATE = `${this.HOST}/my/checkin/date`;
  public MY_INFO = `${this.HOST}/my/info`;
  public MY_UPDATE_INFO = `${this.HOST}/my/info/update`;
  public MY_ENROLL_HISTORY = `${this.HOST}/my/enroll_history`;
  public MY_LOGIN_HISTORY = `${this.HOST}/my/login_history`;
  public MY_CHANGE_PASSWORD = `${this.HOST}/admin/user/password/reset`;
  public BINDING_URL = `${this.HOST}/open/wechat/login`;
  public WX_MP_LOGIN = `${this.HOST}/open/wechat/loginByWeChatPublic`;
  /**
   * 职级管理
   */
  // public ALL_LEVEL_INFO = `${this.HOST}/admin/level/abstract`;
  public ALL_LEVEL_INFO = `${this.HOST}/hr/level/list`;
  public LEVEL_USER_LIST = (id: any) => `${this.HOST}/hr/level/list/${id}/staffs`;
  public NEXT_LEVEL_LIST = (id: any) => `${this.HOST}/admin/level/positions/${id}`;
  public ADD_LEVEL_INFO = `${this.HOST}/admin/level/add`;
  public UPDATE_LEVEL_INFO = `${this.HOST}/admin/level/edit`;

  /**
   * 财务相关
   */
  public REIMBURSE_COMPLETE_LIST = `${this.HOST}/fs/reimburse/payList`;
  public REIMBURSE_PENDING_LIST = `${this.HOST}/fs/reimburse/unPayList`;
  public REIMBURSE_DETAIL_INFO = (id: any) => `${this.HOST}/fs/reimburse/info/${id}`;

  public PAYMENT_COMPLETE_LIST = `${this.HOST}/pay/payedList`;
  public PAYMENT_PENDING_LIST = `${this.HOST}/pay/unPayList`;
  public PAYMENT_DETAIL_INFO = (id: any) => `${this.HOST}/pay/getPayInfoById/${id}`;

  public BUY_CONTRACT_LIST = `${this.HOST}/contract/purchaseList`;
  public BUY_CONTRACT_DETAIL_INFO = (id: any) => `${this.HOST}/contract/purchaseInfoById/${id}`;

  public SALE_CONTRACT_LIST = `${this.HOST}/contract/salesList`;
  public SALE_CONTRACT_DETAIL_INFO = (id: any) => `${this.HOST}/contract/salesInfoById/${id}`;

  public PURCHASE_MINE_LIST = `${this.HOST}/purchase_task/my_purchase`;
  public PURCHASE_COMPLETE_LIST = `${this.HOST}/purchase_task/received_purchase`;
  public PURCHASE_PENDING_LIST = `${this.HOST}/purchase_task/pending_purchase`;
  public PURCHASE_DETAIL_INFO = (id: any) => `${this.HOST}/purchase_task/purchase_task_info/${id}`;
  public PURCHASE_RECEIVE = (id: any) => `${this.HOST}/purchase_task/receive_task/${id}`;
  // 财务审批
  public APPROVAL_FINANCIAL_LIST = `${this.HOST}/fs/approval/queryFinancialApprovalList`;

  // 获取当日打卡情况
  public GET_NOW_CHECK = `${this.HOST}/hr/checkin/getNowCheck`;
  // 按时间筛选当前用户的打卡情况
  public GET_HR_CHECK_BY_DATE = `${this.HOST}/hr/checkin/getHrCheckByDate`;
  // 获取工作时段打卡规则
  public GET_CHECK_CONFIG_HISTORY = `${this.HOST}/hr/checkin/getCheckConfigHistory`;
  // 统计迟到 早退 旷工 缺卡统计列表
  public GET_MISS_AND_ABNORMAL_CHECK = `${this.HOST}/hr/stat/staff/day/getMissAndAbnormalCheck`;
  // 我的消息
  public GET_MY_MESSAGES = `${this.HOST}/message/getMyMessages`;
  // 一键读取消息 /api
  public READ_MESSAGES = (messageType: any) => `${this.HOST}/message/readMessages/${messageType}`;

  // 获取字典类型
  public CODE_TYPE = (type: any) => `${this.HOST}/form/dictionary/code/${type}`;
  // 新增项目草稿
  public ADD_PROJECT = `${this.HOST}/projectManager/addProject`;
  // 完结项目
  public FINISH_PROJECT = `${this.HOST}/projectManager/finishProject`;
  // 添加成本
  public ADD_COST = `${this.HOST}/projectManager/addCost`;
  // 编辑成本
  public EDIT_COST = `${this.HOST}/projectManager/editCost`;
  // 删除成本
  public DEL_COST = (id: any) => `${this.HOST}/projectManager/delCost/${id}`;
  // 获取项目下关闭任务列表
  public GET_CLOSE_TASK_LIST = `${this.HOST}/projectManager/getCloseTaskListByProjectId`;
  // 完结任务
  public FINISH_TASK = (id: any) => `${this.HOST}/projectManager/finishTask/${id}`;
  // 删除任务
  public DEL_TASK = (id: any) => `${this.HOST}/projectManager/delTask/${id}`;
  // 添加任务成果
  public ADD_GAIN_CONTENT = `${this.HOST}/projectManager/addGainContent`;
  // 添加任务成果附件
  public ADD_GAIN_FILE = `${this.HOST}/projectManager/addGainFile`;
  // 删除任务成果附件
  public DEL_GAIN_FILE = (id: any) => `${this.HOST}/projectManager/delGainFile/${id}`;
  // 添加任务描述
  public ADD_DEMAND_CONTENT = `${this.HOST}/projectManager/addDemandContent`;
  // 添加任务描述附件
  public ADD_DEMAND_FILE = `${this.HOST}/projectManager/addDemandFile`;
  // 删除任务描述附件
  public DEL_DEMAND_FILE = (id: any) => `${this.HOST}/projectManager/delDemandFile/${id}`;
  // 添加子任务
  public ADD_CHILD_TASK = `${this.HOST}/projectManager/addChildTask`;
  // 解除子任务
  public LIFT_CHILD_TASK = (id: any) => `${this.HOST}/projectManager/liftChildTask/${id}`;
  // 获取项目最近访问记录
  public GET_PROJECT_RECORD = `${this.HOST}/projectManager/getProjectRecord`;
  // 获取任务最近访问记录
  public GET_TASK_RECORD = `${this.HOST}/projectManager/getTaskRecord`;
  // 发起流程
  public LAUNCH_PROJECT = `${this.HOST}/projectManager/launch`;
  // 获取项目动态
  public GET_PROJECT_LOG = `${this.HOST}/projectManager/getProjectLog`;
  // 获取任务动态
  public GET_TASK_LOG = `${this.HOST}/projectManager/getTaskLog`;
  // 删除项目
  public DEL_PROJECT = (id: any) => `${this.HOST}/projectManager/delProject/${id}`;
  // 获取所有项目列表
  public GET_ALL_PROJECT = `${this.HOST}/projectManager/getAllProject`;
  // 获取我负责的项目
  public GET_MY_PROJECT_LIST = `${this.HOST}/projectManager/getMyProjectList`;
  // 获取我的草稿项目
  public GET_MY_DRAFT_PROJECT_LIST = `${this.HOST}/projectManager/getMyUnApplyProjectList`;
  // 获取我参与的项目
  public GET_PARTICIPATED_PROJECT_LIST = `${this.HOST}/projectManager/getParticipatedProjectList`;
  // 项目详情
  public GET_PROJECT_INFO = (id: any) => `${this.HOST}/projectManager/getProjectInfo/${id}`;
  // 关闭项目
  public CLOSE_PROJECT = (id: any) => `${this.HOST}/projectManager/closeProject/${id}`;
  // 编辑项目
  public EDIT_PROJECT = `${this.HOST}/projectManager/editProject`;
  // 添加任务
  public ADD_TASK = `${this.HOST}/projectManager/addTask`;
  // 编辑任务
  public EDIT_TASK = `${this.HOST}/projectManager/editTask`;
  // 修改任务状态
  public UPDATE_TASK_STATUS = `${this.HOST}/projectManager/updateTaskStatus`;
  // 新增计划
  public ADD_PLAN = `${this.HOST}/projectManager/addPlan`;
  // 添加成员权限
  public ADD_MEMBER_ROLE = `${this.HOST}/projectManager/addMemberRole`;
  // 修改成员权限
  public EDIT_MEMBER_ROLE = `${this.HOST}/projectManager/editMemberRole`;
  // 删除成员权限
  public DELETE_MEMBER_ROLE = `${this.HOST}/projectManager/deleteMemberRole`;
  // 我的任务列表接口
  public MY_TASK_LIST = `${this.HOST}/projectManager/myTaskList`;
  // 所有任务接口
  public ALL_TASK_LIST = `${this.HOST}/projectManager/allTaskList`;
  // 查看任务详情
  public GET_TASK_INFO = (id: any) => `${this.HOST}/projectManager/getTaskInfo/${id}`;
  // 获取计划详情
  public GET_PLAN_INFO = (id: any) => `${this.HOST}/projectManager/getPlanInfo/${id}`;
  // 获取项目下的一级任务列表
  public GET_TASK_LIST_BY_PROJECT = (id: any) => `${this.HOST}/projectManager/getTaskListByProjectId/${id}`;
  // 获取项目下的一级计划列表
  public GET_PLAN_LIST_BY_PROJECT = `${this.HOST}/projectManager/getPlanListByProjectId`;
  // 是否有权限下载文件
  public DOWNLOAD_FILE = `${this.HOST}/projectManager/downloadRoleJudge`;
  // 完结记录详情是否有权限下载文件
  public DOWNLOAD_FILE_RECORD = `${this.HOST}/projectManager/downloadRecordRoleJudge`;
  // 判断修改项目的code权限
  public CHECK_PROJECT_UPDATE_ROLE = (id: any) => `${this.HOST}/projectManager/getMyProjectRoleByProjectId/${id}`;
  // 判断修改任务的code权限
  public CHECK_TASK_UPDATE_ROLE = (id: any) => `${this.HOST}/projectManager/getMyProjectRoleByTaskId/${id}`;

  /**
   * 仓库管理
   */
  public WAREHOUSE_DEPOT_LIST = `${this.HOST}/wms/warehouse/depot/list`;
  public WAREHOUSE_DEPOT_GRID = `${this.HOST}/wms/warehouse/depot/grid`;
  public WAREHOUSE_DEPOT_INFO = (id: any) => `${this.HOST}/wms/warehouse/depot/info/${id}`;
  public WAREHOUSE_DEPOT_DETAIL = (id: any) => `${this.HOST}/wms/warehouse/depot/detail/${id}`;
  public WAREHOUSE_DEPOT_ADD = `${this.HOST}/wms/warehouse/depot/add`;
  public WAREHOUSE_DEPOT_EDIT = `${this.HOST}/wms/warehouse/depot/edit`;
  public WAREHOUSE_DEPOT_DELETE = `${this.HOST}/wms/warehouse/depot/delete`;
  public WAREHOUSE_DEPOT_ZONE_ADD = `${this.HOST}/wms/warehouse/depot/zone/add`;
  public WAREHOUSE_DEPOT_ZONE_DELETE = `${this.HOST}/wms/warehouse/depot/zone/delete`;
  public WAREHOUSE_DEPOT_ZONES_BY_DEPOT = (id: any) => `${this.HOST}/wms/warehouse/depot/zones/depot/${id}`;
  public WAREHOUSE_DEPOT_SHELVES_BY_DEPOT = (id: any) => `${this.HOST}/wms/warehouse/depot/shelves/depot/${id}`;
  public WAREHOUSE_DEPOT_SHELF_LIST = `${this.HOST}/wms/warehouse/depot/shelf/list`;
  public WAREHOUSE_DEPOT_SHELF_DELETE = `${this.HOST}/wms/warehouse/depot/shelf/delete`;
  public WAREHOUSE_DEPOT_SHELF_ADD = `${this.HOST}/wms/warehouse/depot/shelf/add`;
  public WAREHOUSE_DEPOT_SHELF_STORAGE_LIST = `${this.HOST}/wms/warehouse/depot/shelf/storage`;
  public WAREHOUSE_DEPOT_SHELF_INBOUND_LIST = `${this.HOST}/wms/warehouse/depot/shelf/inbounds`;
  public WAREHOUSE_DEPOT_SHELF_OUTBOUND_LIST = `${this.HOST}/wms/warehouse/depot/shelf/outbounds`;

  /**
   * 物料管理
   */
  public WMS_ITEM_CATEGORY_TREE = `${this.HOST}/wms/item/category/tree`;
  public WMS_ITEM_CATEGORY_LIST = `${this.HOST}/wms/item/category/list`;
  public WMS_ITEM_CATEGORY_INFO = (id: any) => `${this.HOST}/wms/item/category/info/${id}`;
  public WMS_ITEM_SPU_INFO = (id: any) => `${this.HOST}/wms/item/spu/info/${id}`;
  public WMS_ITEM_BY_CATEGORY = (id: any) => `${this.HOST}/wms/item/spu/list/category/${id}`;
  public WMS_ITEM_BATCH_BY_SKU = (id: any) => `${this.HOST}/wms/item/batch/list/sku/${id}`;
  public WMS_ITEM_BATCH_BY_SHELF = (id: any) => `${this.HOST}/wms/item/batch/list/shelf/${id}`;
  public WMS_ITEM_CATEGORY_DELETE = `${this.HOST}/wms/item/category/delete`;
  public WMS_ITEM_CATEGORY_ADD = `${this.HOST}/wms/item/category/add`;
  public WMS_ITEM_CATEGORY_EDIT = `${this.HOST}/wms/item/category/edit`;
  public WMS_ITEM_SPU_ADD = `${this.HOST}/wms/item/spu/add`;
  public WMS_ITEM_SPU_ADD_NEW = `${this.HOST}/wms/item/spu/addNew`;
  public WMS_ITEM_SPU_EDIT = `${this.HOST}/wms/item/spu/edit`;
  public WMS_ITEM_SPU_ATTRIBUTES_EDIT = `${this.HOST}/wms/item/spu/attribs/edit`;
  public WMS_ITEM_SPU_DELETE = `${this.HOST}/wms/item/spu/delete`;
  public WMS_ITEM_SPU_LIST = `${this.HOST}/wms/item/spu/list`;
  public WMS_ITEM_SPU_FILTER = `${this.HOST}/wms/item/spu/filter`;
  public WMS_ITEM_SKU_BY_SPU = (id: any) => `${this.HOST}/wms/item/sku/list/spu/${id}`;
  public WMS_ITEM_SKUS = `${this.HOST}/wms/item/sku/list`;
  public WMS_ITEM_SKU_ADD = `${this.HOST}/wms/item/sku/add`;
  public WMS_ITEM_SKU_EDIT = `${this.HOST}/wms/item/sku/edit`;
  public WMS_ITEM_SKU_DELETE = `${this.HOST}/wms/item/sku/delete`;

  /**
   * 库存管理
   */
  public WMS_INVENTORY_INFO = (id: any) => `${this.HOST}/wms/inventory/info/${id}`;
  public WMS_INVENTORY_BY_SPU = (id: any) => `${this.HOST}/wms/inventory/byspu/${id}`;
  public WMS_INVENTORY_BY_DEPOT = (id: any) => `${this.HOST}/wms/inventory/bydepot/${id}`;
  public WMS_INVENTORY_LOGS_BY_ITEM = (id: any) => `${this.HOST}/wms/inventory/logs/byitem/${id}`;
  public WMS_INVENTORY_LIST = `${this.HOST}/wms/inventory/list`;
  public WMS_INVENTORY_INSTOCK = `${this.HOST}/wms/inventory/stocks`;
  public WMS_INVENTORY_INBOUNDS = `${this.HOST}/wms/inventory/inbounds`;
  public WMS_INVENTORY_OUTBOUNDS = `${this.HOST}/wms/inventory/outbounds`;
  public WMS_INVENTORY_TEMPORARY = `${this.HOST}/wms/inventory/temporary`;
  public WMS_INVENTORY_SWAP = `${this.HOST}/wms/inventory/swap`;
  public WMS_INVENTORY_LOSS = `${this.HOST}/wms/inventory/loss`;
  public WMS_INVENTORY_CHECKIN = `${this.HOST}/wms/inventory/checkin`;
  public WMS_INVENTORY_CHECKOUT = `${this.HOST}/wms/inventory/checkout`;
  public WMS_INVENTORY_PICKUP = `${this.HOST}/wms/inventory/pickup`;
  public WMS_INVENTORY_OLD_STOCK = `${this.HOST}/wms/inventory/oldStocks`;

  /**
   * 签收管理
   */
  public WMS_RECEIVE_CONFIG_SIGNERS = `${this.HOST}/wms/receive/config/signers`;
  public WMS_RECEIVE_CONFIG_SIGNER_ADD = `${this.HOST}/wms/receive/config/signer/add`;
  public WMS_RECEIVE_CONFIG_SIGNER_EDIT = `${this.HOST}/wms/receive/config/signer/edit`;
  public WMS_RECEIVE_CONFIG_SIGNER_DELETE = `${this.HOST}/wms/receive/config/signer/delete`;
  public WMS_RECEIVE_ORDER_LIST = `${this.HOST}/wms/receive/order/list`;
  public WMS_RECEIVE_ORDER_LISTPAGE = `${this.HOST}/wms/receive/order/listPage`;
  public WMS_RECEIVE_ORDER_LIST_OFMY = `${this.HOST}/wms/receive/order/listOfMyPage`;
  public WMS_RECEIVE_ORDER_ADD = `${this.HOST}/wms/receive/order/add`;
  public WMS_RECEIVE_ORDER_REJECT = `${this.HOST}/wms/receive/order/reject`;
  public WMS_RECEIVE_ORDER_APPROVE = `${this.HOST}/wms/receive/order/approve`;
  public WMS_RECEIVE_ORDER_INFO = (id: any) => `${this.HOST}/wms/receive/order/info/${id}`;
  public WMS_RECEIVE_ORDER_DETAIL = (id: any) => `${this.HOST}/wms/receive/order/detail/${id}`;
  public WMS_RECEIVE_ORDER_ITEMS_LIST_BY_ORDER = (id: any) => `${this.HOST}/wms/receive/items/order/${id}`;
  public WMS_RECEIVE_ORDER_ITEM_INFO = (id: any) => `${this.HOST}/wms/receive/order/item/info/${id}`;
  public WMS_RECEIVE_ORDER_ITEMS_RECEIVE = `${this.HOST}/wms/receive/order/receive`;

  /**
   * 领料管理
   */
  public WMS_PICKUP_ORDER_LIST = `${this.HOST}/wms/pickup/order/list`;
  public WMS_PICKUP_ORDER_LISTPAGE = `${this.HOST}/wms/pickup/order/listPage`;
  public WMS_PICKUP_ORDER_ADD = `${this.HOST}/wms/pickup/order/add`;
  public WMS_PICKUP_ORDER_REJECT = `${this.HOST}/wms/pickup/order/reject`;
  public WMS_PICKUP_ORDER_PICKUP = `${this.HOST}/wms/pickup/order/pickup`;
  public WMS_PICKUP_ORDER_CANCEL = `${this.HOST}/wms/pickup/order/cancel`;
  public WMS_PICKUP_ORDER_RECHECKIN = `${this.HOST}/wms/pickup/order/recheckin`;
  public WMS_PICKUP_ORDER_ITEM_PREPARE = `${this.HOST}/wms/pickup/order/item/prepare`;
  public WMS_PICKUP_ORDER_INFO = (id: any) => `${this.HOST}/wms/pickup/order/info/${id}`;
  public WMS_PICKUP_ORDER_DETAIL = (id: any) => `${this.HOST}/wms/pickup/order/detail/${id}`;
  public WMS_PICKUP_ORDER_ITEM_INFO = (id: any) => `${this.HOST}/wms/pickup/order/item/info/${id}`;
  public WMS_PICKUP_ORDER_ITEMS_LIST_BY_ORDER = (id: any) => `${this.HOST}/wms/pickup/items/order/${id}`;
  public WMS_PICKUP_FULFILLMENTS_BY_ORDER = (id: any) => `${this.HOST}/wms/pickup/fulfillments/order/${id}`;
  public WMS_PICKUP_FULFILLMENTS_FILLED = `${this.HOST}/wms/pickup/fulfillments/filled`;
  // 领料详情明细获取备货列表
  public LIST_INVENTORY_BY_IDS = (ids: any) => `${this.HOST}/wms/inventory/listInventoryByIds?ids=${ids}`;
  // 获取领料详情可用数量
  public STOCK_AMOUNT_BY_IDS_STR = (ids: any) => `${this.HOST}/wms/inventory/stockAmountByIdsStr?ids=${ids}`;
  /**
   * 仓储条件管理
   */
  public WMS_STORAGE_CONDITION_LIST = `${this.HOST}/wms/storage/condition/list`;
  public WMS_STORAGE_CONDITION_INFO = (id: any) => `${this.HOST}/wms/storage/condition/info/${id}`;
  public WMS_STORAGE_CONDITION_EDIT = `${this.HOST}/wms/storage/condition/edit`;
  public WMS_STORAGE_CONDITION_ADD = `${this.HOST}/wms/storage/condition/add`;
  public WMS_STORAGE_CONDITION_DELETE = `${this.HOST}/wms/storage/condition/delete`;
  public WMS_STORAGE_LABEL_LIST = `${this.HOST}/wms/storage/label/list`;
  public WMS_STORAGE_LABEL_INFO = (id: any) => `${this.HOST}/wms/storage/label/info/${id}`;
  public WMS_STORAGE_LABEL_EDIT = `${this.HOST}/wms/storage/label/edit`;
  public WMS_STORAGE_LABEL_ADD = `${this.HOST}/wms/storage/label/add`;
  public WMS_STORAGE_LABEL_DELETE = `${this.HOST}/wms/storage/label/delete`;

  /**
   * 供应链管理
   */
  public WMS_DISTRIBUTOR_LIST = `${this.HOST}/wms/distributor/list`;
  public WMS_DISTRIBUTOR_INFO = (id: any) => `${this.HOST}/wms/distributor/info/${id}`;
  public WMS_DISTRIBUTOR_EDIT = `${this.HOST}/wms/distributor/edit`;
  public WMS_DISTRIBUTOR_ADD = `${this.HOST}/wms/distributor/add`;
  public WMS_DISTRIBUTOR_DELETE = `${this.HOST}/wms/distributor/delete`;

  /**
   * 品牌管理
   */
  public WMS_BRAND_LIST = `${this.HOST}/wms/brand/list`;
  public WMS_BRAND_INFO = (id: any) => `${this.HOST}/wms/brand/info/${id}`;
  public WMS_BRAND_EDIT = `${this.HOST}/wms/brand/edit`;
  public WMS_BRAND_ADD = `${this.HOST}/wms/brand/add`;
  public WMS_BRAND_DELETE = `${this.HOST}/wms/brand/delete`;

  /**
   * 权限管理
   */
  public ADMIN_USER_MY_ROLES = `${this.HOST}/admin/user/my/roles`;

  /**
   * 采购管理
   */
  public GET_CLOSE_PURCHASE_CONTRACT = `${this.HOST}/purchase/getClosePurchaseContract`;
  public GET_CLOSE_PURCHASE_TASK = `${this.HOST}/purchase/getClosePurchaseTask`;
  public GET_PASSED_PURCHASE_CONTRACT = `${this.HOST}/purchase/getPassedPurchaseContract`;
  public GET_PASSED_PURCHASE_TASK = `${this.HOST}/purchase/getPassedPurchaseTask`;
  public GET_PENDING_PURCHASE_CONTRACT = `${this.HOST}/purchase/getPendingPurchaseContract`;
  public GET_PENDING_PURCHASE_TASK = `${this.HOST}/purchase/getPendingPurchaseTask`;
  public GET_PURCHASE_CONTRACT_INFO_BY_ID = (id: any) => `${this.HOST}/purchase/getPurchaseContractInfoById/${id}`;
  public GET_PURCHASE_TASK_INFO_BY_ID = (id: any) => `${this.HOST}/purchase/getPurchaseTaskInfoById/${id}`;
  public GET_PURCHASE_TASK_INFO_BY_ID_NEW = (id: any) => `${this.HOST}/purchase/getPurchaseTaskInfoByIdNew/${id}`;
  public GET_PENDING_TASK_LIST = `${this.HOST}/purchase/getPurchaseTaskList`;
  public PURCHASE_CONTRACT_LAUNCH = `${this.HOST}/purchase/purchaseContractLaunch`;
  public PURCHASE_TASK_LAUNCH = `${this.HOST}/purchase/purchaseTaskLaunch`;
  public GET_PURCHASE_CONTRACT_LIST = `${this.HOST}/purchase/getPurchaseContractList`;
  public GET_CONTRACT_DETAILS_BY_TASK_ID_CONTRACT_ID = (taskId: any, contractId: any) =>
    `${this.HOST}/contract/getPurchaseContractDetailsByTaskIdAndContractId?taskId=${taskId}&contractId=${contractId}`;

  public EXPORT_PURCHASE_CONTRACT = `${this.HOST}/purchase/exportPurchaseContract`;
  public EXPORT_PURCHASE_TASK = `${this.HOST}/purchase/exportPurchaseTask`;
  // 我的签收列表
  public LIST_OF_MY = `${this.HOST}/wms/receive/order/listOfMy`;
  // 批号存放位置明细
  public STOCKS_DETAILS_BY_BATCH_NO = `${this.HOST}/wms/inventory/stocksDetailsByBatchNo`;
  // 导入次数唯一性id查询存放明细
  public STOCKS_DETAILS_BY_IDS = `${this.HOST}/wms/inventory/stocksDetailsByIds?`;
  // 根据物料id和物料规格查询货号列表
  public GET_PRODUCT_BY_SKU = `${this.HOST}/wms/receive/getProductNoListBySpuIdAndSkuId`;
  // 有库存的物料规格型号列表
  public STOCK_LIST_SKUS = `${this.HOST}/wms/item/sku/stockListSkus`;
  // 获取可用数量
  public GET_STOCK_AMOUNT_BY_PRODUCT_NO = `${this.HOST}/wms/receive/getStockAmountByProductNo`;
  // 编辑分区
  public EDIT_DEPOT = `${this.HOST}/wms/warehouse/depot/zone/edit`;
  // 我的领料列表
  public RECEIVE_LIST_OF_MY = `${this.HOST}/wms/pickup/order/listOfMy`;
  // 我的领料列表条件查询
  public GET_RECEIVE_LIST_OF_MY = `${this.HOST}/wms/pickup/order/getListOfMy`;
  // 获取物料下规格
  public GET_SKU_LIST_BY_SPUID = (spuId: any) => `${this.HOST}/wms/item/spu/getSkuListBySpuId/${spuId}`;
  // 获取采购库管信息
  public GET_CONFIG_SIGN = `${this.HOST}/wms/receive/getConfigSign`;
  public RECEIVE_PRINT = (id: any, type: any) => `${this.HOST}/wms/receive/order/print/${id}` + (type ? `?type=${type}` : '');
  public PICK_UP_PRINT = (id: any, type: any) => `${this.HOST}/wms/pickup/order/print/${id}` + (type ? `?type=${type}` : '');
  public CONTRACT_PRINT = (id: any, type: any) => `${this.HOST}/purchase/printContract/${id}` + (type ? `?type=${type}` : '');
  public SALE_CONTRACT_PRINT = (id: any, type: any) => `${this.HOST}/contract/printSaleContract/${id}` + (type ? `?type=${type}` : '');
  public TASK_PRINT = (id: any, type: any) => `${this.HOST}/purchase/printPurchaseTask/${id}` + (type ? `?type=${type}` : '');
  // 批量导入历史库存数据
  public IMPORT_HISTORY = `${this.HOST}/wms/inventory/importHistory`;
  // 获取导入物料的流转历史信息
  public BY_SKU_AND_BATCH_PRODUCT = (skuId: any, batchNo: any, productNo: any) =>
    `${this.HOST}/wms/inventory/logs/bySkuIdAndBatchNoAndProductNo?skuId=${skuId}&batchNo=${batchNo}&productNo=${productNo}`;
  // 批量导入历史库存模板
  public MATERIAL_INFO_TEM = `${this.HOST}/wms/inventory/downloadTemplateFile?templateFileName=MaterialsInfo`;
  // 项目管线
  public GET_CHANNEL_LIST = `${this.HOST}/project/channel/getChannelList`;
  public ADD_CHANNEL = `${this.HOST}/project/channel/add`;
  public EDIT_CHANNEL = `${this.HOST}/project/channel/edit`;
  public DETAIL_CHANNEL = (id: any) => `${this.HOST}/project/channel/${id}`;
  public DELETE_CHANNEL = (id: any) => `${this.HOST}/project/channel/${id}`;
  // 月记录考勤导出

  public EXPORT_CHECK_SUMMARY_EXCEL = (month: any, token: any) =>
    `${this.HOST}/hr/checkin/exportCheckSummaryExcel?month=${month}&token=${token}`;
  // 获取所有撤销列表数据
  public ALL_CANCELLED = `${this.HOST}/flow/ticket/cancelled`;
  // 根据仓库id查询库物料列表
  public STOCKS_BY_DEPOT = (id: any) => `${this.HOST}/wms/inventory/stocksByDepotId/${id}`;
  // 根据仓库分区id查询库物料列表
  public STOCKS_BY_DEPOT_BY_ZONE_ID = (id: any) => `${this.HOST}/wms/inventory/stocksByDepotZoneId/${id}`;
  // 根据仓库货架id查询库物料列表
  public STOCKS_BY_DEPOT_BY_SHELF_ID = (id: any) => `${this.HOST}/wms/inventory/stocksByDepotShelfId/${id}`;
  // 项目完结报告功能
  public ADD_FINISH_FILE = `${this.HOST}/projectManager/addFinishFile`;
  public DEL_FINISH_FILE = (id: any) => `${this.HOST}/projectManager/delFinishFile/${id}`;
  public GET_PROJECT_FINISH_RECORD_LIST = (id: any) => `${this.HOST}/projectManager/getProjectFinishRecordList/${id}`;
  public GET_PROJECT_FINISH_RECORD_INFO = (id: any) => `${this.HOST}/projectManager/getProjectFinishRecordInfoByRecordId/${id}`;
  public EDIT_FINISHED_DIRECTIONS = `${this.HOST}/projectManager/editFinishedDirections`;
  // 采购需求已撤回列表
  public GET_CANCELLED_PURCHASE_TASK = `${this.HOST}/purchase/getCancelledPurchaseTask`;
  // 采购合同已撤回列表
  public GET_CANCELLED_PURCHASE_CONTRACT = `${this.HOST}/purchase/getCancelledPurchaseContract`;
  // 付款申请已撤回列表
  public CANCELLED_PAY_LIST = `${this.HOST}/pay/cancelledList`;
  // 报销申请已撤回列表
  public CANCELLED_REIMBURSE_LIST = `${this.HOST}/fs/reimburse/cancelledList`;
  // 未审核任务提交
  public LAUNCH_TASK = (id: any) => `${this.HOST}/projectManager/launchTask/${id}`;
  // 编辑任务详情
  public UPDATE_INFO_TASK = `${this.HOST}/projectManager/updateTaskInfo`;
  // 报销下载打印
  public REIMBURSE_PRINT = (id: any, type: any) => `${this.HOST}/fs/reimburse/print/${id}` + (type ? `?type=${type}` : '');
  // 付款下载打印
  public PAY_PRINT = (id: any, type: any) => `${this.HOST}/pay/print/${id}` + (type ? `?type=${type}` : '');
  // 月度考勤汇总按员工查询
  public GET_MONTH_CHECK_BY_USER_ID_AND_MONTH = `${this.HOST}/hr/checkin/getMonthCheckByUserIdAndMonth`;
  // 考勤明细
  public GET_CHECK_DETAIL_BY_USER_ID_AND_DAY = `${this.HOST}/hr/checkin/getCheckDetailByUserIdAndDay`;
  // 出差相关:根据用户id和月份查询出差记录
  public GET_TRIP_LIST_BY_USER_ID_AND_MONTH = `${this.HOST}/hr/trip/getTripListByUserIdAndMonth`;
  // 加班相关: 根据员工id和月份查询加班记录
  public GET_OVERTIME_LIST_BY_USER_ID_AND_MONTH = `${this.HOST}/hr/overtime/getOvertimeListByUserIdAndMonth`;
  // 根据员工id和月份查询异常考勤记录
  public GET_EXCEPTION_CHECK_BY_USER_ID_AND_MONTH = `${this.HOST}/hr/checkin/getExceptionCheckByUserIdAndMonth`;
  // 请假相关:根据用户id和月份查询请假记录
  public GET_HOLIDAY_LIST_BY_USER_ID_AND_MONTH = `${this.HOST}/hr/holiday/getHolidayListByUserIdAndMonth`;
  // 个人付款信息
  public PAY_LIST_OF_MY = (id: any) => `${this.HOST}/pay/listOfMy?userId=${id}`;
  // 个人报销信息
  public REIMBURSE_LIST_OF_MY = (id: any) => `${this.HOST}/fs/reimburse/listOfMy?userId=${id}`;
  // 各部门月报销统计
  public REIMBURSE_STATISTICS_BY_MONTH_AND_DOMAIN = `${this.HOST}/fs/reimburse/reimburseStatisticsByMonthAndDomain`;
  // 各部门月付款统计
  public PAYMENT_STATISTICS_BY_MONTH_AND_DOMAIN = `${this.HOST}/fs/reimburse/paymentStatisticsByMonthAndDomain`;
  // 各部门财务报销统计明细
  public REIMBURSE_DETAIL_BY_MONTH = `${this.HOST}/fs/reimburse/reimburseDetailByMonth`;
  // 可下载附件报销记录列表
  public REIMBURSE_QUERY_WITH_ENCLOSURE = `${this.HOST}/fs/reimburse/queryWithEnclosure`;
  // 报销附件下载
  public REIMBURSE_ENCLOSURE = `${this.HOST}/fs/reimburse/download/enclosure`;
  // 可下载附件付款记录列表
  public PAY_QUERY_WITH_ENCLOSURE = `${this.HOST}/pay/queryWithEnclosure`;
  // 付款附件下载
  public PAY_ENCLOSURE = `${this.HOST}/pay/download/enclosure`;
  // 获取加班核销详情
  public OVERTIME_INFO = `${this.HOST}/hr/overtime/getOvertime`;
  // 下载打印加班核销单
  public OVERTIME_PRINT = (id: any, type: any) => `${this.HOST}/hr/overtime/print/${id}?type=${type}`;
  // 项目导出信息
  public PROJECT_DOWNLOAD = (id: any) => `${this.HOST}/projectManager/download/${id}`;
  // 设置消息已读
  public SET_READED = (id: any) => `${this.HOST}/message/setReaded/${id}`;
  // 项目完结前编辑任务成果
  public UPDATE_GAIN = `${this.HOST}/projectManager/task/updateGain`;
  // 查询任务完结记录
  public FINISH_RECORD = (taskId: any) => `${this.HOST}/projectManager/task/finishRecord/${taskId}`;
  // 修改核销时间
  public UPDATE = `${this.HOST}/hr/overtime/confirm/update`;
  // 查询加班单核销记录
  public OVERTIME_CONFIRM_LIST = (id: any) => `${this.HOST}/hr/overtime/confirm/list/${id}`;
  // 按时间段批量核销
  public OVERTIME_BATCH = `${this.HOST}/hr/overtime/confirm/batch`;
  // 我的差旅报销记录
  public GET_MY_TRAVEL_LIST = `${this.HOST}/travel/getMyTravelList`;
  // 待处理的差旅报销申请
  public GET_PENDING_TRAVEL_LIST = `${this.HOST}/travel/getPendingTravelList`;
  // 已完成的差旅报销申请
  public GET_COMPLETED_TRAVEL_LIST = `${this.HOST}/travel/getCompletedTravelList`;
  // 已撤销的的差旅报销申请
  public GET_CANCELLED_TRAVEL_LIST = `${this.HOST}/travel/getCancelledTravelList`;
  // 差旅报销详情
  public GET_TRAVEL_INFO = (id: any) => `${this.HOST}/travel/getTravelInfo/${id}`;
  // 刷新核销实际时长
  public REPAIR_OVERTIME_ACTUAL = `${this.HOST}/hr/overtime/repairOvertimeAutal`;
  // 差旅报销下载打印
  public TRAVEL_PRINT = (id: any, type: any) => `${this.HOST}/travel/print/${id}?type=${type}`;
  // 查询项目变更记录
  public RECORD_LIST = (id: any) => `${this.HOST}/projectManager/change/record/list?projectId=${id}`;
  // 查询项目变更详情信息
  public CHANGE_INFO = (id: any) => `${this.HOST}/projectManager/change/info?id=${id}`;
  // 查询任务变更记录
  public RECORD_TASK_LIST = (id: any) => `${this.HOST}/projectManager/task/change/record/list?taskId=${id}`;
  // 查询任务变更详情信息
  public CHANGE_TASK_INFO = (id: any) => `${this.HOST}/projectManager/task/change/info?id=${id}`;
  // 获取指定类型消息列表
  public MESSAGE_LIST_BY_TYPE = `${this.HOST}/message/messageListByType`;
  // 获取所有流程的催办规则列表
  public REMINDER_LIST = `${this.HOST}/flow/reminder/list`;
  // 更新指定流程的催办规则
  public REMINDER_EDIT = `${this.HOST}/flow/reminder/edit`;
  // 更新全部流程的催办规则
  public REMINDER_SETALL = `${this.HOST}/flow/reminder/setall`;
  // 获取财务管理已撤销列表数据
  public FINANCE_CANCELLED = (id: any) => `${this.HOST}/flow/group/ticket/cancelled/${id}`;
  // 查看转正详情
  public GET_POSITIVE_DETAIL = (id: any) => `${this.HOST}/hr/staff/getPositiveDetail?id=${id}`;
  // 查看离职详情
  public GET_RESIGNED_DETAIL = (id: any) => `${this.HOST}/hr/staff/getResignedDetail?id=${id}`;
  // 查看
  public GET_HOLIDAY_DETAIL = (id: any) => `${this.HOST}/hr/holiday/getHolidayDetail?id=${id}`;
  // 病假上传凭证
  public ADD_SICK_HOLIDAY_CERTIFICATE = `${this.HOST}/hr/holiday/addSickHolidayCertificate`;
  // 查看加班记录列表
  public GET_OVERTIME_LIST = `${this.HOST}/hr/overtime/getOvertimeList`;
  // 出差详情
  public GET_TRIP_DETAIL = (id: any) => `${this.HOST}/hr/trip/getTripDetail?id=${id}`;
  // 查看补卡记录列表
  public GET_REMEDY_LIST = `${this.HOST}/hr/checkin/getRemedyCheck`;
  // 导出补卡记录
  public EXPORT_REMEDY_RECORD = (month: any, signType: any, userId: any, domainId: any) =>
    `${this.HOST}/hr/checkin/exportRemedyCheckList?month=${month}&signType=${signType}&userId=${userId}&domainId=${domainId}`;
  // 查看补卡详情
  public GET_REMEDY_CHECK_DETAIL = (id: any) => `${this.HOST}/hr/checkin/getRemedyCheckDetail?id=${id}`;
  // 员工的劳动合同签订记录列表
  public GET_LABOR_LIST = (userId: any) => `${this.HOST}/labor/list/${userId}`;
  // 员工的转岗记录列表
  public GET_POSITION_LIST = (userId: any) => `${this.HOST}/position/list/${userId}`;
  // 员工的调薪记录列表
  public GET_SALARY_LIST = (userId: any) => `${this.HOST}/salary/list/${userId}`;
  // 编辑劳动合同提醒
  public ADD_LABOR = `${this.HOST}/labor/add`;
  // 添加劳动合同签订记录
  public EDIT_LABOR = `${this.HOST}/labor/edit`;
  // 添加转岗记录
  public ADD_POSITION = `${this.HOST}/position/add`;
  // 添加调薪记录
  public ADD_SALARY = `${this.HOST}/salary/add`;
  // 请假下载打印
  public HOLIDAY_PRINT = (id: any, type: any) => `${this.HOST}/hr/holiday/print/${id}?type=${type}`;
  // 出差下载打印
  public TRIP_PRINT = (id: any, type: any) => `${this.HOST}/hr/trip/print/${id}?type=${type}`;
  // 补卡下载打印
  public CHECKIN_PRINT = (id: any, type: any) => `${this.HOST}/hr/checkin/print/${id}?type=${type}`;
  // 加班下载打印
  public OVERTIME_DETAIL_PRINT = (id: any, type: any) => `${this.HOST}/hr/overtime/printOvertimeDetail/${id}?type=${type}`;
  // 离职下载打印
  public RESIGNED_DETAIL_PRINT = (id: any, type: any) => `${this.HOST}/hr/staff/printResignedDetail/${id}?type=${type}`;
  // 转正下载打印
  public POSITIVE_DETAIL_PRINT = (id: any, type: any) => `${this.HOST}/hr/staff/printPositiveDetail/${id}?type=${type}`;
  // 日历待办提醒-当天
  public GET_ACCOMPLISH_AND_FINISH_TIME = `${this.HOST}/projectManager/getAccomplishAndFinishTime`;
  // 日历待办提醒-月
  public GET_ACCOMPLISH_AND_FINISH_TIME_BY_CURRENT_MONTH = `${this.HOST}/projectManager/getAccomplishAndFinishTimeByCurrentMonth`;
  // 加班核销加班统计导出  date=''yyyy-mm
  public EXPORT_OVERTIME_TOTAL = (beginDate: any, endDate: any, domainIds: any, userIds: any) =>
    `${this.HOST}/hr/overtime/exportOverTimeTotal?beginDate=${beginDate}&endDate=${endDate}&domainIds=${domainIds}&userIds=${userIds}`;
  // 职能查询员工
  public BY_ROLE_ID = (roleId: any) => `${this.HOST}/hr/staff/byRoleId/${roleId}`;
  // 岗位查询员工
  public BY_POSITION_ID = (positionId: any) => `${this.HOST}/hr/staff/byPositionId/${positionId}`;
  // 假期设置
  public DAY_TYPE_UPDATE = `${this.HOST}/hr/checkin/dayType/update`;
  // 假期详情
  public DAY_TYPE_INFO = (date: any) => `${this.HOST}/hr/checkin/dayType/info?day=${date}`;
  // 获取全部项目数量
  public GET_PROJECT_NUM = `${this.HOST}/projectManager/getProjectNum`;
  // 获取我的项目数量
  public GET_PARTICIPATED_PROJECT_NUM = `${this.HOST}/projectManager/getParticipatedProjectNum`;
  // 获取所有的项目分页列表
  public GET_ALL_PROJECT_PAGE = `${this.HOST}/projectManager/getAllProjectPage`;
  // 获取我参与的项目分页列表
  public GET_PARTICIPATED_PROJECT_LIST_PAGE = `${this.HOST}/projectManager/getParticipatedProjectListPage`;
  // 获取我负责的项目分页列表
  public GET_MY_PROJECT_LIST_PAGE = `${this.HOST}/projectManager/getMyProjectListPage`;
  // 员工年假信息
  public YEAR_HOLIDAY_DETAIL = (id: any) => `${this.HOST}/hr/holiday/yearHolidayDetail/${id}`;
  // 费用报销_待处理导出
  public REIMBURSE_PENDING = `${this.HOST}/fs/reimburse/reimbursementPendingExcel`;
  // 费用报销_已完成导出
  public REIMBURSE_ACCOMPLISH = `${this.HOST}/fs/reimburse/reimbursementAccomplishExcel`;
  // 费用报销_已撤销导出
  public REIMBURSE_REVOCATION = `${this.HOST}/fs/reimburse/reimbursementRevocationExcel`;
  // 付款记录_待处理导出
  public PAY_PENDING = `${this.HOST}/pay/payPendingExcel`;
  // 付款记录_已完成导出
  public PAY_ACCOMPLISH = `${this.HOST}/pay/payAccomplishExcel`;
  // 付款记录_已撤销导出
  public PAY_REVOCATION = `${this.HOST}/pay/payRevocationExcel`;
  // 差旅报销_待处理导出
  public RRAVEL_PENDING = `${this.HOST}/travel/travelPendingExcel`;
  // 差旅报销_已完成导出
  public RRAVEL_ACCOMPLISH = `${this.HOST}/travel/travelAccomplishExcel`;
  // 差旅报销_已撤销导出
  public RRAVEL_REVOCATION = `${this.HOST}/travel/travelRevocationExcel`;

  /**
   * 测试用接口
   */
  public CHECKIN_TEST = `${this.HOST}/hr/checkin/test`;
  public TEST_LIST_USER_BY_DOMAIN = (did: any) => `${this.HOST}/test/user/by/domain/${did}`;
  // 生日提醒
  public CONFIG_FREQUENCIES = `${this.HOST}/hr/birthdayRemind/config/frequencies`; // 通知频率
  public CONFIG_ROLE_TYPES = `${this.HOST}/hr/birthdayRemind/config/roleTypes`; // 角色类型
  public CONFIG_ADD = `${this.HOST}/hr/birthdayRemind/config/add`;
  public CONFIG_DELETE = (id: any) => `${this.HOST}/hr/birthdayRemind/config/delete/${id}`;
  public CONFIG_EDIT = `${this.HOST}/hr/birthdayRemind/config/edit`;
  public CONFIG_LIST = `${this.HOST}/hr/birthdayRemind/config/list`;
  public BIRTHDAY_MESSAGES = `${this.HOST}/message/birthdayMessages`;
  // 人事审批
  public HR_APPROVE = `${this.HOST}/hr/approval/queryHrApprovalList`;
  // 获取打卡点列表接口
  public LOCATION_LIST = `${this.HOST}/hr/checkin/config/locationList`;
  // 新增打卡点
  public ADD_LOCATION = `${this.HOST}/hr/checkin/config/location/add`;
  // 编辑打卡点
  public UPDATE_LOCATION = `${this.HOST}/hr/checkin/config/location/update`;
  // 查看打卡点信息
  public VIEW_LOCATION = (id: any) => `${this.HOST}/hr/checkin/config/location?id=${id}`;
  // 查看打卡点信息
  public DELETE_LOCATION = (id: any) => `${this.HOST}/hr/checkin/config/location/delete?id=${id}`;
  // 查询行政外勤记录
  public GET_OUT_APPLY_LIST = `${this.HOST}/hr/checkin/getOutApplyList`;
  // 查看外勤记录详情
  public GET_OUT_DETAIL = (id: any) => `${this.HOST}/hr/checkin/getOutDetail/${id}`;
  // 外勤下载打印
  public OUT_CHECK_PRINT = (id: any, type: any) => `${this.HOST}/hr/checkin/printOutCheck/${id}?type=${type}`;
  // 修改请假申请
  public UPDATE_REAL_HOLIDAY = `${this.HOST}/hr/holiday/updateRealHoliday`;
  public CALCULATE_HOLIDAY_TIMESPAN = `${this.HOST}/hr/holiday/calculateHolidayTimespan`;
  // 撤销假期
  public CANCEL_HOLIDAY = (id: any) => `${this.HOST}/hr/holiday/cancelHoliday/${id}`;
  // 编辑劳动合同签订记录
  public LABOR_EDIT = `${this.HOST}/labor/edit`;
  // 修改转岗记录
  public POSITION_EDIT = `${this.HOST}/position/edit`;
  // 修改调薪记录
  public SALARY_EDIT = `${this.HOST}/salary/edit`;
  // 删除调薪记录接口
  public DELETE_SALARY = (id: any) => `${this.HOST}/salary/delete/${id}`;
  // 删除转岗记录接口
  public DELETE_POSITION = (id: any) => `${this.HOST}/position/delete/${id}`;
  // 删除劳动合同记录
  public DELETE_LABOR = (id: any) => `${this.HOST}/labor/delete/${id}`;
  // 新增付款申请
  public FS_PAY_LAUNCH = `${this.HOST}/pay/fsPayLaunch`;
  // 单位账号列表
  public GET_PAYEE_LIST = `${this.HOST}/payee/getPayeeList`;

  /**
   * 收款方管理
   */
  public PAYEE_LIST = `${this.HOST}/payee/list`;
  public PAYEE_EDIT = `${this.HOST}/payee/edit`;
  public PAYEE_ADD = `${this.HOST}/payee/add`;
  public PAYEE_DELETE = `${this.HOST}/payee/delete`;
  public PAYEE_INFO = (id: any) => `${this.HOST}/payee/info?id=${id}`;

  // 新增销售合同
  public SALES_CONTRACT_LAUNCH = `${this.HOST}/contract/salesContractLaunch`;
}
