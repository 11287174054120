import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { SVModule } from '@delon/abc/sv';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ShowImgComponent } from './show-img.component';

const COMPONENTS = [ShowImgComponent];

@NgModule({
  imports: [
    CommonModule,
    NzModalModule,
    FormsModule,
    ReactiveFormsModule,
    NzGridModule,
    NzButtonModule,
    NzCardModule,
    NzTableModule,
    NzTagModule,
    SVModule,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class ShowImgModule {}
