import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbFormService } from '@showye/nbweb-input/nb-form';
import { ShowyePageApiService, ShowyePageAuthService, ShowyePageUtilService } from '@showye/showye-page-base';
import { VisNetworkService } from '@showye/nbweb-vis';

@Component({
  selector: 'app-view-flow-copy',
  templateUrl: './view-flow-copy.component.html',
  styleUrls: ['./task.scss'],
})
export class ViewFlowCopyComponent implements OnInit, OnDestroy {
  @Input() ticketId: any;
  @Input() processName: any;
  @Input() processId: any;
  @Input() isVisible = true;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  title = '流程图';

  constructor(
    private api: ShowyePageApiService,
    private coder: ShowyePageUtilService,
    private auth: ShowyePageAuthService,
    modalSrv: NzModalService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private formService: NbFormService,
    public msg: NzMessageService,
    public notify: NzNotificationService,
    private visNetworkService: VisNetworkService,
  ) {
    modalSrv.closeAll();
  }

  ngOnDestroy(): void {
    if (this.route$) {
      this.route$.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (this.processName) {
      this.title = this.processName + '流程图';
    }
    this.id = this.processId;
    if (this.id) {
      this.reloadEdges();
    }
  }

  /**
   * 流程基本信息
   */
  public route$: any = null;
  users: any = [];
  userMap: any = {};
  id: any;
  groupMap: any = {};
  groups: any = [];
  process: any = {};
  forms: any = [];
  formMap: any = {};
  fields: any = [];
  infoForm: FormGroup;
  showInfoModal = false;
  processFormCols: any = [];

  metaId: any;
  formWithInstances: any = [];
  formInstanceMap: any = {};
  instanceFormMap: any = {};
  instanceMap: any = {};

  metaFormInstances: any = [];

  downloadUrl = this.api.getDownloadUrl();
  uploadUrl = this.api.getUploadUrl();
  token: any = this.auth.token;

  getTemplateName(): string {
    return this.process.printTemplateUrl ? this.process.printTemplateUrl.name : '';
  }

  /**
   * 下面处理 节点、操作
   */
  selectedType: any;
  selected: any;
  nodeTypes = [
    { id: 1, name: '流程开始' },
    { id: 2, name: '正常结束' },
    { id: 3, name: '异常终止' },
    { id: 4, name: '已撤销' },
    // { id: 8, name: '判断网关' },
    // { id: 9, name: '自动任务' },
    { id: 10, name: '按职级审批' },
    { id: 11, name: '按职能审批' },
  ];
  nodeForm: FormGroup;
  nodeOptions: any[] = [];
  nodes: any[] = [];
  nodeMap: any = {};
  showNodeModal = false;
  preloaders: any = [];

  reloadNodes(): void {
    this.api.listNodesByProcess(this.id).subscribe((e: any) => {
      const nodesInfo: any[] = [];
      this.nodeIdsByEdges.forEach((x: any) => {
        e.data.forEach((y: any) => {
          if (x.id === y.id) {
            nodesInfo.push(y);
          }
        });
      });
      this.nodes = nodesInfo;
      this.nodeOptions = nodesInfo.map((x: any) => ({ value: x.id, label: x.name }));
      this.reloadGraph();
    });
  }

  /**
   * 节点权限
   */
  nodeRoleToIdx = 0;
  nodeRoleFromIdx = 0;
  nodeLevelForm: FormGroup;
  roles: any = [];
  roleMap: any = {};
  levels: any = [];
  levelMap: any = {};
  levelFromEnum: any = {
    1: '直接上级',
    2: '上两级',
    3: '上三级',
    4: '上四级',
    5: '上五级',
  };
  levelToEnum: any = {
    1: '连续一级',
    2: '连续二级',
    3: '连续三级',
    4: '连续四级',
    5: '连续五级',
  };
  nrm: any = {};
  selectedRoles: any = [];
  showNodeRoleModal = false;

  /**
   * 下面处理操作
   */
  edgeConditionColumns: any = [];
  edgeTypes = [
    { value: 'primary', label: '通过' },
    { value: 'danger', label: '驳回' },
    // { id: 'default', name: '其他' },
    { value: 'dashed', label: '其他' },
  ];
  edgeForm: FormGroup;
  edges: any[] = [];
  edgeMap: any = {};
  showEdgeModal = false;

  nodeIdsByEdges: any[] = [];

  reloadEdges(): void {
    this.api.listEdgesByProcess(this.id).subscribe((e: any) => {
      this.edges = e.data;
      this.nodeIdsByEdges = [];
      e.data.forEach((x: any) => {
        this.nodeIdsByEdges.push({ id: x.fromId });
        this.nodeIdsByEdges.push({ id: x.toId });
      });
      this.nodeIdsByEdges = this.goRepeatData(this.nodeIdsByEdges);
      this.reloadNodes();
    });
  }

  // 数组去从
  goRepeatData(data: any): any[] {
    const newArr: any = [];
    const obj: any = {};
    for (let i = 0; i < data.length; i++) {
      if (!obj[data[i].id]) {
        newArr.push(data[i]);
        obj[data[i].id] = true;
      }
    }
    return newArr;
  }

  getEdgeName(id: any): any {
    return this.edgeMap[id]?.name;
  }

  /**
   * 图形绘制
   */
  public visNetwork = 'networkId1';
  public visNetworkData: any;
  public visNetworkOptions: any = {};
  public visNetworkNodes: any;
  public visNetworkEdges: any;
  public visNetworkOperators: any;
  public visNetworkInited: any;

  public networkInitialized(): void {
    setTimeout(() => {
      this.visNetworkInited = true;
    }, 200);
  }

  extractEdgeId(eid: string): any {
    return parseInt(eid.substr(eid.indexOf('-') + 1), 10);
  }

  getEdgeDefinitionByType(type: string): any {
    switch (type) {
      case 'danger': {
        return { arrows: { to: { enabled: true } }, color: '#FF4D4F', font: { color: '#FF4D4F' } };
      }
      case 'dashed': {
        return { arrows: { to: { enabled: true } }, dashes: true, color: '#666', font: { color: '#666' } };
      }
      case 'primary': {
        return { arrows: { to: { enabled: true } }, color: '#2B7CE9', font: { color: '#2B7CE9' } };
      }
      case 'approve': {
        return { arrows: { to: { enabled: true } }, color: '#198941', font: { color: '#198941' } };
      }
      default: {
        return { arrows: { to: { enabled: true } }, color: '#666', font: { color: '#666' } };
      }
    }
  }

  reloadGraph(): void {
    this.nodeMap = this.nodes ? this.nodes.reduce((acc: any, cur: any) => ({ ...acc, [cur.id]: cur }), {}) : {};
    this.visNetworkNodes = this.nodes
      ? this.nodes.map((x: any) => {
          switch (x.type) {
            case 1:
              return { id: x.id, label: x.name, shape: 'circle', color: '#666', font: { color: '#FEF6F6' } };
            case 2:
              return { id: x.id, label: x.name, shape: 'circle', color: '#198941', font: { color: '#FEF6F6' } };
            case 3:
              return { id: x.id, label: x.name, shape: 'circle', color: '#FF4D4F', font: { color: '#FEF6F6' } };
            case 4:
              return { id: x.id, label: x.name, shape: 'circle', color: '#999', font: { color: '#FEF6F6' } };
            case 8:
              return { id: x.id, label: x.name, shape: 'diamond' };
            case 9:
              return { id: x.id, label: x.name, shape: 'ellipse' };
            case 10:
            case 11:
              return { id: x.id, label: x.name, shape: 'box' };
            default:
              return { id: x.id, label: x.name, shape: 'text' };
          }
        })
      : [];
    this.visNetworkOperators = this.edges
      ? this.edges
          .filter((x: any) => x.conditions && x.conditions.length > 0)
          .map((x: any) => {
            switch (x.type) {
              case 'danger': {
                return {
                  id: `e-${x.id}`,
                  shape: 'dot',
                  size: 5,
                  color: { background: '#fff', border: '#FF4D4F' },
                  font: { color: '#FF4D4F' },
                };
              }
              case 'dashed': {
                return {
                  id: `e-${x.id}`,
                  shape: 'dot',
                  size: 5,
                  dashes: true,
                  color: { background: '#fff', border: '#666' },
                  font: { color: '#666' },
                };
              }
              case 'primary': {
                return {
                  id: `e-${x.id}`,
                  shape: 'dot',
                  size: 5,
                  color: { background: '#fff', border: '#2B7CE9' },
                  font: { color: '#2B7CE9' },
                };
              }
              case 'approve': {
                return {
                  id: `e-${x.id}`,
                  shape: 'dot',
                  size: 5,
                  color: { background: '#fff', border: '#198941' },
                  font: { color: '#198941' },
                };
              }
              default: {
                return {
                  id: `e-${x.id}`,
                  shape: 'dot',
                  size: 5,
                  color: { background: '#fff', border: '#666' },
                  font: { color: '#666' },
                };
              }
            }
          })
      : [];
    this.edgeMap = this.edges ? this.edges.reduce((acc: any, cur: any) => ({ ...acc, [cur.id]: cur }), {}) : {};
    const egs: any = [];
    if (this.edges && this.edges.length > 0) {
      this.edges.forEach((x: any) => {
        // 按照 condition 来设置
        if (x.conditions && x.conditions.length > 0) {
          egs.push({
            ...this.getEdgeDefinitionByType(x.type),
            id: `e-${x.id}`,
            label: x.name,
            from: x.fromId,
            to: `e-${x.id}`,
          });
          egs.push({
            ...this.getEdgeDefinitionByType(x.type),
            id: `e-${x.id}-0`,
            label: '其它',
            from: `e-${x.id}`,
            to: x.toId,
          });
          x.conditions.forEach((c: any, idx: any) => {
            egs.push({
              ...this.getEdgeDefinitionByType(x.type),
              id: `e-${x.id}-${idx + 1}`,
              label: c.name,
              from: `e-${x.id}`,
              to: c.nodeId,
            });
          });
        } else {
          egs.push({
            ...this.getEdgeDefinitionByType(x.type),
            id: `e-${x.id}`,
            label: x.name,
            from: x.fromId,
            to: x.toId,
          });
        }
      });
    }
    this.visNetworkEdges = egs;

    this.visNetworkData = {
      nodes: [...this.visNetworkNodes, ...this.visNetworkOperators],
      edges: this.visNetworkEdges,
    };
    if (this.visNetworkInited) {
      this.visNetworkService.setData(this.visNetwork, this.visNetworkData);
    }
  }

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }
}
